import { defineStore } from 'pinia'
import axios from 'axios'

const API_URL = process.env.VUE_APP_API
const PUBLIC_VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY
const APP_URL = process.env.VUE_APP_URL_GLOBAL

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        permissions: null,
        blockedByUser: null
    }),
    actions: {
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) {
                return parts.pop().split(';').shift()
            }
        },
        async setNotifications() {
            if (!('Notification' in window)) {
                console.log(
                    'This browser does not support desktop notifications'
                )
                this.permissions = false
                return
            }

            try {
                const permission = await Notification.requestPermission()
                if (permission === 'denied') {
                    this.permissions = false
                } else if (permission === 'granted') {
                    this.permissions = true
                    this.checkSubscription()
                }
            } catch (error) {
                console.error('Permission request failed:', error)
            }
        },
        checkNotificationPermission() {
            console.log('Checking notification permission...')
            // console.log(Notification.permission)

            if (!('Notification' in window)) {
                console.log(
                    'This browser does not support desktop notifications'
                )
                this.permissions = false
                return
            }

            if (Notification.permission === 'denied') {
                this.permissions = false
            } else if (Notification.permission === 'granted') {
                this.permissions = true
                this.checkSubscription()
            } else {
                this.setNotifications()
            }
        },
        async subscribeUser(subscription) {
            // Bearer token included ✅
            const userID = window.localStorage.getItem('userId')
            const authToken = this.getCookie('authToken')

            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            }

            // console.log(subscription)
            try {
                const response = await axios.post(
                    `${API_URL}api/push/subscribe`,
                    {
                        userId: userID,
                        subscription: subscription
                    },
                    config
                )
                // console.log(response)
            } catch (error) {
                console.error('Error subscribing user:', error)
            }
        },
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
            const base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/')
            const rawData = window.atob(base64)
            const buffer = new Uint8Array(rawData.length)

            for (let i = 0; i < rawData.length; ++i) {
                buffer[i] = rawData.charCodeAt(i)
            }

            return buffer
        },
        /**
         *
         * Firebase Cloud Messaging
         * Request permission for notifications and get the token
         * $return {<string>} granted, denied, default
         */
        requestPermission() {
            console.log('request permission')

            if (!('Notification' in window)) {
                this.permissions =
                    'This browser does not support desktop notifications'
                return
            }

            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.')
                    this.permissions = 'granted'
                    // const options = {
                    //     body: 'Dzięki za zezwolenie na powiadomienia! w taki sposób będziemy mogli informować Cię o nowych wiadomościach i aktywnościach na Twoim koncie.',
                    //     icon: 'img/icons/notification-icon.png'
                    // }

                    // new Notification('Witaj w biurze randkowym!', options)
                } else if (permission === 'denied') {
                    console.error('Notification permission denied.')
                    this.permissions = 'denied'
                } else {
                    console.log('Notification permission default.')
                    this.permissions = 'default'
                }
            })
        }
    }
})
