<template>
    <HeaderComponent />
    <section class="contact-page">
        <div class="contact-page__header-container">
            <h1>Kontakt</h1>
        </div>
        <div class="contact-page__heart-container">
            <img
                alt="heart image"
                src="@/assets/images/contact-page-heart.svg"
            />
        </div>
        <div class="contact-page__email-container">
            <a href="mailto:kontakt@biurorandkowe.pl">
                kontakt@biurorandkowe.pl
            </a>
        </div>
        <div class="contact-page__divider"></div>
        <div class="contact-page__address-container">
            <span>B87 Sp. z o.o.</span>
            <span>ul. Baśniowa 25</span>
            <span>62-800 Kalisz</span>
            <span>NIP: 6182199989</span>
        </div>
    </section>
    <Footer />
</template>

<script>
    import HeaderComponent from '@/components/landing-03/HeaderComponent.vue'
    import Footer from '@/components/landing-03/footer.vue'
    export default {
        components: {
            HeaderComponent,
            Footer
        }
    }
</script>

<style lang="scss">
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/global.scss';

    .contact-page {
        display: flex;
        flex-direction: column;
        padding: 100px 16px;
        margin-top: 50px;
        background-color: #F4F2EE;
        @include breakpoint-min("xs-tablet") {
            margin-top: 120px;
            padding: 122px;
        }
        &__header-container {
            text-align: center;
            margin-bottom: 98px;
            @include breakpoint-min("xs-tablet") {
                margin-bottom: 90px;
        }
            h1 {
                font-family: Promenade;
                font-size: clamp(64px, 6vw, 90px);
                font-weight: 400;
                margin: 0;
            }
        }
        &__heart-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 22px;
        }
        &__email-container {
            text-align: center;
            a {
                font-size: 16px;
                font-weight: 700;
                cursor: pointer;
                text-decoration: none;
                color: black;
            }
        }
        &__divider {
            height: 142px;
            background-color: #707070;
            width: 1px;
            margin: 30px auto;
        }
        &__address-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
</style>
