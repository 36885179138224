import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import axios from 'axios'

const VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY
const API_URL = process.env.VUE_APP_API

const firebaseConfig = {
    apiKey: 'AIzaSyDLEHSKZC5nQBXdgbWtEbKEVLbfVFzcLm4',
    authDomain: 'biuro-randkowe.firebaseapp.com',
    projectId: 'biuro-randkowe',
    storageBucket: 'biuro-randkowe.appspot.com',
    messagingSenderId: '970879373858',
    appId: '1:970879373858:web:9d8a0d209acf19c387f07b'
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

const getCookie = name => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}

//zapisywanie tokena do bazy
const saveTokenToBackend = async token => {
    try {
        console.log('Sending token to backend:', token)

        const authToken = getCookie('authToken')
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }
        const userId = localStorage.getItem('userId')

        const response = await axios.post(
            `${API_URL}api/push/register-fcm-token`,
            { fcmToken: token, userId: userId },
            config
        )

        console.log('Response from backend:', response.data)
    } catch (e) {
        console.error('Error sending token to backend:', e)
    }
}

// pobierz token i wyslij do backu
const getFcmToken = async () => {
    try {
        const token = await getToken(messaging, { vapidKey: VAPID_KEY })
        console.log('FCM Token:', token)
        // Wysyłanie tokenu do backendu, aby go zapisać
        await saveTokenToBackend(token)
    } catch (error) {
        console.error('Error retrieving FCM token', error)
    }
}

// // Request permission for notifications and get the token
// export const requestNotificationPermission = async () => {
//     try {
//         // Ask the user for permission using the browser's Notification API
//         const permission = await Notification.requestPermission()

//         if (permission === 'granted') {
//             console.log('Notification permission granted.')

//             // Get the FCM token
//             const token = await getToken(messaging, {
//                 vapidKey: VAPID_KEY
//             })
//             console.log('FCM Token:', token)
//             return token
//         } else {
//             console.error('Notification permission denied.')
//         }
//     } catch (error) {
//         console.error('Error getting permission or token:', error)
//     }
// }

// Listen for messages when the app is in the foreground
onMessage(messaging, payload => {
    console.log('Message received in the foreground:', payload)
    // Display notification
    // const notificationTitle = payload.notification.title
    // const notificationOptions = {
    //     body: payload.notification.body,
    //     icon: payload.notification.icon
    // }
    // new Notification(notificationTitle, notificationOptions)
})

// Export messaging for use in other parts of the app
export { messaging, getFcmToken }
