<template>
    <Header />
    <div class="payments">
        <div class="payments__wrapper">
            <div class="payments__left">
                <!-- <router-link to="#" class="payments__left-back">
                    <svg width="15" height="19"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" fill="white"/>
                    </svg>
                </router-link> -->

                <h2>Dane do płatności</h2>
                <div class="payments__left-form">
                    <div class="payments__left-form-top">
                        <label for="name">
                            <p>Imię:</p>
                            <input id="name" type="text" v-model="name" />
                        </label>
                        <label for="surname">
                            <p>Nazwisko:</p>
                            <input id="surname" type="text" v-model="surname" />
                        </label>
                    </div>
                    <div
                        class="payments__left-form-bottom payments__left-form-bottom--remove-border"
                    >
                        <label for="phone">
                            <p>Telefon:</p>
                            <input
                                v-if="phone"
                                id="phone"
                                type="phone"
                                v-model="phone"
                                maxlength="9"
                            />
                        </label>
                        <label for="email">
                            <p>Email:</p>
                            <input
                                v-if="email"
                                disabled
                                id="email"
                                type="text"
                                v-model="email"
                            />
                        </label>
                    </div>
                    <div
                        class="payments__left-form-bottom payments__left-form-bottom--remove-border"
                    >
                        <label for="city">
                            <p>Miasto:</p>
                            <input
                                id="city"
                                type="city"
                                v-model="city"
                                maxlength="50"
                            />
                        </label>
                        <label for="postcode">
                            <p>Kod pocztowy:</p>
                            <input
                                id="postcode"
                                type="text"
                                v-model="postcode"
                                maxlength="6"
                            />
                        </label>
                    </div>
                    <div class="payments__full-width">
                        <label for="street">
                            <p>Ulica i numer:</p>
                            <input
                                id="street"
                                type="text"
                                v-model="street"
                                maxlength="50"
                            />
                        </label>
                    </div>
                </div>

                <div
                    class="payments__right-checkbox payments__right-checkbox--margin"
                >
                    <label for="invoice">
                        <input
                            type="checkbox"
                            name=""
                            id="invoice"
                            v-model="invoice"
                        />
                        <p>Chcę fakturę na firmę.</p>
                    </label>
                    <div
                        class="payments__left-form payments__right-checkbox--margin"
                        v-if="invoice"
                    >
                        <div class="payments__left-form-top">
                            <label for="nip">
                                <p>NIP:</p>
                                <input
                                    id="nip"
                                    type="text"
                                    v-model="nip"
                                    style="width: 100%"
                                    maxlength="10"
                                />
                            </label>
                            <label for="cityinvoice">
                                <p>Miasto:</p>
                                <input
                                    id="cityinvoice"
                                    type="text"
                                    style="width: 100%"
                                    v-model="cityInvoice"
                                    maxlength="50"
                                />
                            </label>
                        </div>
                        <div
                            class="payments__left-form-bottom payments__left-form-bottom--remove-border"
                        >
                            <label for="streetInvoice">
                                <p>Ulica:</p>
                                <input
                                    id="streetInvoice"
                                    type="text"
                                    style="width: 100%"
                                    v-model="streetInvoice"
                                    maxlength="50"
                                />
                            </label>
                            <label for="postcodeinvoice">
                                <p>Kod pocztowy:</p>
                                <input
                                    id="postcodeinvoice"
                                    type="text"
                                    style="width: 100%"
                                    v-model="postcodeInvoice"
                                    maxlength="6"
                                />
                            </label>
                        </div>
                        <div class="payments__full-width">
                            <label for="company">
                                <p>Nazwa firmy:</p>
                                <input
                                    id="company"
                                    type="text"
                                    v-model="company"
                                    maxlength="50"
                                    style="width: 100%"
                                />
                            </label>
                        </div>
                    </div>
                </div>

                <p class="error">{{ errors.nip }}</p>
                <p class="error">{{ errors.phone }}</p>
                <p class="error">{{ errors.postcode }}</p>
                <p class="error">{{ errors.postcodeInvoice }}</p>
                <p class="error">{{ errors.street }}</p>
                <p class="error">{{ errors.streetInvoice }}</p>
            </div>
            <div class="payments__right">
                <div class="payments__right-box">
                    <h2>Podsumowanie koszyka</h2>
                    <div class="payments__right-order">
                        <div class="payments__right-order-left">
                            <h3>Produkt:</h3>
                            <p>PAKIET PLUS</p>
                            <em>(6 miesięcy)</em>
                            <p class="summary">PODSUMOWANIE</p>
                        </div>
                        <div class="payments__right-order-right">
                            <h3>Koszt:</h3>
                            <p>594 zł (z vat)</p>
                            <p class="summary">594 zł (z vat)</p>
                        </div>
                    </div>
                    <div class="payments__right-payment">
                        <img
                            src="../assets/images/Przelewy24.png"
                            alt="Przelewy24"
                        />
                    </div>
                    <p class="payments__right-text">
                        Twoje dane osobowe będą użyte do przetworzenia
                        zamówienia, ułatwienia korzysatnia ze strony
                        internetowej oraz innych celów Opisanych w naszej
                        polityce prywatność.
                    </p>
                    <div class="payments__right-checkbox">
                        <label for="terms">
                            <input
                                type="checkbox"
                                name=""
                                id="terms"
                                v-model="terms"
                            />
                            <p>
                                Akceptuję
                                <router-link
                                    target="/blank"
                                    to="/regulamin_platformy.pdf"
                                >
                                    Regulamin
                                </router-link>
                                i
                                <router-link
                                    target="/blank"
                                    to="/polityka_prywatnosci.pdf"
                                >
                                    Politykę prywatności
                                </router-link>
                                .
                            </p>
                        </label>
                        <div>
                            <p class="payments__acceptance">
                                W przypadku nie wyrażenia poniższej zgody, Twój
                                formularz może zostać zweryfikowany w terminie
                                dłuższym niż 14dni
                            </p>
                            <p
                                class="payments__acceptance payments__acceptance--gray"
                            >
                                Czy zgadzasz się na natychmiastowe rozpoczęcie
                                świadczenia usługi? Jeśli tak, informujemy, że
                                po spełnieniu świadczenia utracisz prawo
                                odstąpienia od umowy.
                            </p>
                            <label for="terms2" class="payments__right-terms2">
                                <input
                                    type="radio"
                                    value="term2"
                                    id="terms2"
                                    v-model="terms2"
                                />
                                <p>
                                    Zgadzam się i przyjmuję powyższą informację
                                    do wiadomości.
                                </p>
                            </label>
                        </div>
                        <div>
                            <label for="terms3" class="payments__right-terms2">
                                <input
                                    type="radio"
                                    value="term3"
                                    id="terms3"
                                    v-model="terms2"
                                />
                                <p>Nie zgadzam się.</p>
                            </label>
                        </div>
                        <label for="info" class="payments__right-terms2">
                            <input
                                type="checkbox"
                                name=""
                                id="info"
                                v-model="info"
                            />
                            <p>
                                Zgadzam się na wysyłanie przez B87 spółkę z
                                ograniczoną odpowiedzialnością z siedzibą w
                                Kaliszu informacji handlowych na podany przeze
                                mnie adres email.
                            </p>
                        </label>
                    </div>
                    <button
                        :class="{
                            'payments__right-box-btn': true,
                            'disabled-btn': !(terms && terms2)
                        }"
                        :disabled="!(terms && terms2)"
                        @click="submitPayment"
                    >
                        Zamawiam i płacę
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '@/components/landing-03/HeaderComponent.vue'
    import axios from 'axios'
    export default {
        data() {
            return {
                user: '',
                questionnaire: '',
                name: '',
                surname: '',
                phone: '',
                email: '',
                city: '',
                cityInvoice: '',
                terms: '',
                terms2: '',
                terms3: '',
                postcode: '',
                postcodeInvoice: '',
                street: '',
                streetInvoice: '',
                company: '',
                invoice: false,
                info: '',
                nip: '',
                errors: {
                    nip: '',
                    postcode: '',
                    phone: '',
                    postcodeInvoice: '',
                    street: '',
                    streetInvoice: ''
                }
            }
        },
        created() {
            this.getUser()
        },
        components: {
            Header
        },
        watch: {
            nip(newVal) {
                if (this.invoice) this.validateNip()
            },
            postcode(newVal) {
                this.validatePostcode()
            },
            postcodeInvoice(newVal) {
                this.validatePostcodeInvoice()
            },
            phone(newVal) {
                this.validatePhone()
            },
            street(newVal) {
                this.validateStreet()
            },
            streetInvoice(newVal) {
                this.validateStreetInvoice()
            }
        },
        methods: {
            validateNip() {
                // Przykład walidacji NIP: dokładna walidacja może wymagać bardziej złożonego podejścia
                const nipRegEx = /^[0-9]{10}$/
                this.errors.nip = nipRegEx.test(this.nip)
                    ? ''
                    : 'NIP powinien składać się z 10 cyfr.'
            },

            validatePostcodeInvoice() {
                // Przykładowa walidacja kodu pocztowego: format "xx-xxx"
                const postcodeRegEx = /^\d{2}-\d{3}$/
                this.errors.postcodeInvoice = postcodeRegEx.test(
                    this.postcodeInvoice
                )
                    ? ''
                    : 'Format kodu pocztowego to xx-xxx.'
            },

            validatePostcode() {
                // Przykładowa walidacja kodu pocztowego: format "xx-xxx"
                const postcodeRegEx = /^\d{2}-\d{3}$/
                this.errors.postcode = postcodeRegEx.test(this.postcode)
                    ? ''
                    : 'Format kodu pocztowego to xx-xxx.'
            },

            validatePhone() {
                // Przykładowa walidacja telefonu: format "123123123"
                const phoneRegEx = /^\d{9}$/
                this.errors.phone = phoneRegEx.test(this.phone)
                    ? ''
                    : 'Numer telefonu powinien składać się z 9 cyfr.'
            },

            validateStreet() {
                // Przykładowa walidacja telefonu: format "123123123"
                this.errors.street =
                    this.street.length > 5
                        ? ''
                        : 'Ulica powinna mieć minimum 5 znaków.'
            },

            validateStreetInvoice() {
                // Przykładowa walidacja telefonu: format "123123123"
                this.errors.streetInvoice =
                    this.streetInvoice.length > 5
                        ? ''
                        : 'Ulica powinna mieć minimum 5 znaków.'
            },

            paymentValidation() {
                // Wyzeruj komunikaty o błędach
                this.errors.nip = ''
                this.errors.postcode = ''
                this.errors.phone = ''
                this.errors.street = ''
                this.errors.streetInvoice = ''

                // Wykonaj walidacje
                if (this.invoice) this.validateNip()
                if (this.invoice) this.validatePostcodeInvoice()
                if (this.invoice) this.validateStreetInvoice()
                this.validatePostcode()
                this.validatePhone()
                this.validateStreet()

                // Sprawdź, czy są jakieś błędy
                return Object.keys(this.errors).every(
                    key => this.errors[key] === ''
                )
            },
            async submitPayment() {
                if (!this.paymentValidation()) {
                    return
                }

                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                try {
                    const response = await axios.post(
                        `${this.url}api/payment/plus`,
                        {
                            amount: 100,
                            description: 'basic',
                            name: this.name,
                            surname: this.surname,
                            email: this.email,
                            postcode: this.postcode,
                            street: this.street,
                            city: this.city,
                            nip: this.nip,
                            invoice: this.invoice,
                            postcodeInvoice: this.postcodeInvoice,
                            cityInvoice: this.cityInvoice,
                            streetInvoice: this.streetInvoice,
                            company: this.company
                        },
                        config
                    )

                    const paymentUrl = `${this.przelewyURL}/trnRequest/${response.data.url}`
                    // console.log(paymentUrl);

                    // Przekierowanie użytkownika
                    window.location.href = paymentUrl
                } catch (error) {
                    console.error(error)
                }
            },
            getUser() {
                // Bearer token included ✅
                const userId = localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
                axios
                    .get(
                        `${this.url}api/questionnaire/singleuser/${userId}`,
                        config
                    )
                    .then(resp => {
                        this.user = resp.data.user
                        this.email = this.user.email
                        this.questionnaire = resp.data.questionnaire

                        this.phone = this.questionnaire.phone
                        this.city = this.questionnaire.city
                        this.name = this.questionnaire.name
                        this.surname = this.questionnaire.surname

                        // if(this.user.paymentBasic == true){
                        //     this.$router.push('/panel/notification');
                        // }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/global.scss';

    .payments {
        background-color: #f4f2ee;
        padding-bottom: 100px;
        min-height: 100vh;
        padding-top: 160px;
        &__right-terms2 {
            margin-top: 20px;
        }
        &__acceptance {
            margin-top: 20px;
            color: $pink;
            font-weight: 700;
            &--gray {
                color: #000;
            }
        }
        &__full-width {
            &--invoice {
                margin-top: 30px;
            }
            label {
                width: 100%;
                padding: 27px 25px;
                display: flex;
                align-items: center;
                column-gap: 22px;
                border: 1px solid $black;
                cursor: text;
                p {
                    width: fit-content;
                    text-wrap: nowrap;
                    font-size: 1.6rem;
                    line-height: 3rem;
                    font-weight: 700;
                    font-family: 'Open Sans', sans-serif;
                }
                input {
                    width: 100%;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    font-size: 1.6rem;
                    line-height: 3rem;
                    font-weight: 400;
                    font-family: 'Open Sans', sans-serif;
                }
            }
        }
        &__wrapper {
            max-width: 1568px;
            padding: 0 30px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            column-gap: 164px;
            @include breakpoint-max('m-large') {
                column-gap: 60px;
            }
            @include breakpoint-max('large') {
                flex-direction: column;
            }
        }
        &__left {
            flex: 75;
            padding-top: 64px;
            h2 {
                font-size: 4.5rem;
                line-height: 5rem;
                color: $black;
                font-family: 'Promenade', serif;
                font-weight: 400;
                margin: 0;
                margin-bottom: 23px;
            }
            @include breakpoint-max('mobile') {
                width: 100%;
            }
        }
        &__left-back {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-color: $pink;
            display: grid;
            place-items: center;
            margin-bottom: 26px;
        }
        &__left-form {
            @include breakpoint-max('large') {
                max-width: 754px !important;
            }
            @include breakpoint-max('xs-tablet') {
                max-width: 377px !important;
            }
        }
        &__left-form-top,
        &__left-form-bottom {
            display: flex;
            @include breakpoint-max('xs-tablet') {
                flex-wrap: wrap;
            }
            &--remove-border {
                label {
                    &:nth-child(1) {
                        border-bottom: none;
                    }
                    &:nth-child(2) {
                        border-bottom: none;
                    }
                }
            }
            label {
                width: 377px;
                padding: 27px 25px;
                display: flex;
                align-items: center;
                column-gap: 22px;
                border: 1px solid $black;
                cursor: text;
                &:nth-child(1) {
                    border-right: none;
                    @include breakpoint-max('xs-tablet') {
                        border-right: 1px solid #000;
                    }
                }
                p {
                    width: fit-content;
                    text-wrap: nowrap;
                    font-size: 1.6rem;
                    line-height: 3rem;
                    font-weight: 700;
                    font-family: 'Open Sans', sans-serif;
                }
                input {
                    width: 100%;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    font-size: 1.6rem;
                    line-height: 3rem;
                    font-weight: 400;
                    font-family: 'Open Sans', sans-serif;
                }
            }
        }
        &__left-form-top {
            label {
                &:nth-child(1) {
                    border-bottom: none;
                }
                &:nth-child(2) {
                    border-bottom: none;
                }
            }
        }
        &__right {
            flex: 65;
            padding-top: 64px;
            h2 {
                font-size: 4.5rem;
                line-height: 5rem;
                color: $black;
                font-family: 'Promenade', serif;
                font-weight: 400;
                margin: 0;
                margin-bottom: 23px;
            }
        }
        &__right-box {
            max-width: 488px;
        }
        &__right-order {
            margin-bottom: 42px;
            border: 1px solid $black;
            padding: 33px 68px 46px 35px;
            display: flex;
            justify-content: space-between;
        }
        &__right-order-left {
            h3 {
                margin-bottom: 11px;
                font-size: 2.5rem;
                line-height: 2.2rem;
                font-family: 'Promenade', serif;
                font-weight: 400;
            }
            p {
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
            }
            em {
                display: inline-block;
                margin-bottom: 45px;
                font-style: normal;
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
            }
        }
        &__right-order-right {
            h3 {
                margin-bottom: 11px;
                font-size: 2.5rem;
                line-height: 2.2rem;
                font-family: 'Promenade', serif;
                font-weight: 400;
            }
            p {
                text-align: right;
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
            }
            .summary {
                margin-top: 67px;
            }
        }
        &__right-payment {
            margin-bottom: 25px;
            width: 100%;
            max-width: 209px;
            display: grid;
            place-items: center;
            padding: 13px 54px;
            border: 1px solid $black;
            img {
                max-width: 100px;
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        &__right-text {
            margin-bottom: 45px;
            font-size: 1.6rem;
            line-height: 2.2rem;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
        }
        &__right-checkbox {
            margin-bottom: 52px;
            &--margin {
                margin-top: 60px;
            }
            label {
                display: flex;
                column-gap: 10px;
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                input {
                    min-width: 25px;
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                }
                a {
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    font-weight: 400;
                    font-family: 'Open Sans', sans-serif;
                    text-decoration: underline;
                    color: $black;
                }
            }
        }
        &__right-box-btn {
            background-color: $pink;
            padding: 15px 25px;
            appearance: none;
            color: #000;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
        }
        .disabled-btn {
            cursor: not-allowed !important;
        }

        //
        input[type='radio'] {
            overflow: hidden;
            -webkit-appearance: none;
            appearance: none;
            background-color: $grayRGBA;
            margin: 0;
            margin-right: 15px;
            position: relative;

            width: 25px;
            height: 25px;
            border: none;
            border-radius: 12px;
            outline: none;
        }

        input[type='radio']::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.99978 1.31405C12.4378 -3.24795 23.5338 4.73505 7.99978 15.0001C-7.53422 4.73605 3.56178 -3.24795 7.99978 1.31405Z' fill='%23FF0000'/%3E%3C/svg%3E");
            transform: scale(0);
            transform-origin: bottom;
            transition: 0.3s;
            background-repeat: no-repeat;
            background-position: center;
        }

        input[type='radio']:checked::before {
            transform: scale(1);
        }

        input[type='radio']:focus {
            outline: none;
        }

        input[type='radio']:disabled {
            cursor: not-allowed;
        }
        //
    }
</style>
