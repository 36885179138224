<template>
    <div>
        <HeaderComponent/>
        <section class="faqs main-landing">
            <div class="scroll-div" id="faqs"></div>
            <div class="container-landing">
                <h2 class="faqs__title">FAQ</h2>
                <div class="faqs__columns">
                    <div class="faqs__columns-left">
                        <h3 class="faqs__columns-left-title">BIURO I REJESTRACJA</h3>
                        <ul class="faqs__columns-left-list">
                            <SingleFaq
                                v-for="(question, index) in firstColumnQuestions"
                                :key="index"
                                :question="question"
                                :inIndex="index"
                                :showMore="showMore"
                            />
                        </ul>
                        <h3 class="faqs__columns-right-title faqs__under-col">POMOC TECHNICZNA</h3>
                        <ul class="faqs__columns-right-list">
                            <SingleFaq
                                v-for="(question, index) in thirdColumnQuestions"
                                :key="'second_' + index"
                                :question="question"
                                :inIndex="index"
                                :showMore="showMore"
                            />
                        </ul>
                    </div>
                    <div class="faqs__columns-right">
                        <h3 class="faqs__columns-right-title">USŁUGI I PAKIETY</h3>
                        <ul class="faqs__columns-right-list">
                            <SingleFaq
                                v-for="(question, index) in secondColumnQuestions"
                                :key="'second_' + index"
                                :question="question"
                                :inIndex="index"
                                :showMore="showMore"
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>
<script>
// import SingleFaq from './atoms/SingleFaq.vue';
import SingleFaq from '../components/landing-03/atoms/SingleFaq.vue';
import HeaderComponent from "@/components/landing-03/HeaderComponent.vue";
import Footer from "@/components/landing-03/footer.vue";

    export default {
        data() {
            return {
                firstColumnQuestions: [
                    {title: 'W jakich miastach będzie działało Biuro Randkowe?', text: 'Biuro Randkowe  będzie się sukcesywnie otwierać na największe polskie miasta, takie jak Warszawa, Poznań, Wrocław, Kraków, Łódź, Rzeszów, Gdańsk/Gdynia/Sopot, Katowice, Szczecin, Bydgoszcz, Lublin. O kolejności, w której będziemy rozpoczynać działalność, zadecyduje liczba osób zapisanych w danych miastach oraz dostępność naszych konsultantów. Chcemy zapewnić naszym klientom najwyższą jakość usług, dlatego planowanie otwarcia nowych lokalizacji jest ściśle związane z liczbą zgłoszeń w poszczególnych miastach. Dzięki temu możemy zagwarantować, że w każdym z tych miejsc nasi klienci otrzymają profesjonalną obsługę i indywidualne podejście. Zachęcamy do zapisywania się na listę oczekujących, aby przyspieszyć otwarcie Biura Randkowego w Twoim mieście.'},
                    {title: 'Jak mogę się zarejestrować w Biurze Randkowym?', text: 'Aby zarejestrować się w  Biurze Randkowym, wystarczy wypełnić formularz rejestracyjny dostępny na naszej stronie internetowej. Podczas rejestracji podajesz swoje dane kontaktowe, dodajesz zdjęcie oraz wybierasz miasta, w których jesteś gotowy/a umówić się na randkę. Po zakończeniu rejestracji zostaniesz dodany/a do listy oczekujących na kwestionariusz. W momencie, gdy kwestionariusz będzie gotowy do wypełnienia, poinformujemy Cię o tym mailowo i/lub SMS-em.'},
                    {title: 'Czy rejestracja w Biurze jest płatna?', text: 'Nie, rejestracja i zapis na listę oczekujących są całkowicie bezpłatne. Wszyscy zainteresowani mogą bez żadnych opłat zarejestrować się w naszym systemie i dołączyć do listy oczekujących na kwestionariusz lub rozpoczęcie działalności w ich mieście. Opłaty wnosi się dopiero po otrzymaniu i wypełnieniu kwestionariusza, który jest kolejnym krokiem w procesie rejestracji do Biura Randkowego. Wypełnienie kwestionariusza jest niezbędne, abyśmy mogli lepiej poznać preferencje i oczekiwania naszych klientów oraz dopasować odpowiednich partnerów.'},
                    {title: 'Jak mogę śledzić status swojego zgłoszenia? ', text: 'Status swojego zgłoszenia możesz śledzić, logując się na swoje konto na naszej stronie internetowej. Po zalogowaniu się, w panelu użytkownika znajdziesz aktualne informacje dotyczące statusu Twojego zgłoszenia oraz ewentualnych dalszych kroków w procesie rejestracji i dopasowywania partnerów. Regularne sprawdzanie swojego statusu pozwoli Ci być na bieżąco z postępami w procesie.'},
                ],
                secondColumnQuestions: [
                    {title: 'Jakie usługi oferuje Biuro Randkowe?', text: 'Biuro Randkowe ma na celu pomoc w znalezieniu odpowiedniego partnera lub partnerki. Nasze usługi obejmują przede wszystkim weryfikację kwestionariusza oraz dopasowywanie par na podstawie preferencji i oczekiwań użytkowników. Po wypełnieniu i zweryfikowaniu kwestionariusza, przeszukujemy bazę klientów, aby znaleźć najlepsze dopasowanie. Dbamy o to, aby proces dopasowywania był dokładny i oparty na indywidualnych potrzebach naszych użytkowników.'},
                    {title: 'Jakie są dostępne pakiety i co obejmują?', text: 'W Biurze Randkowym dostępne są różne pakiety usług, które odpowiadają na różne potrzeby naszych klientów. Szczegółowe informacje na temat dostępnych pakietów oraz ich zawartości można znaleźć w zakładce CENNIK na naszej stronie internetowej. Każdy pakiet oferuje różne poziomy dostępu do naszej bazy użytkowników, a także dodatkowe korzyści, takie jak priorytetowe przetwarzanie zgłoszeń, możliwość zawieszenia profilu czy dedykowane wsparcie konsultantów. Pakiety są płatne jednorazowo po wypełnieniu kwestionariusza.'},
                    {title: 'Jak mogę opłacić pakiet?', text: 'Opłacenie pakietu jest możliwe po otrzymaniu i wypełnieniu kwestionariusza. Nasza strona automatycznie przekieruje Cię do systemu płatności, gdzie będziesz mógł/a dokonać płatności. Oferujemy różne metody płatności, aby zapewnić Ci jak największą wygodę. Prosimy jednak pamiętać, że nie oferujemy płatności ratalnych ani odroczonych – pełna kwota za pakiet musi zostać uiszczona jednorazowo.'},
                    {title: 'Co daje mi wyższy pakiet?', text: 'Wyższe pakiety oferują kilka dodatkowych korzyści w porównaniu do pakietu podstawowego (Basic). Przede wszystkim pozwalają na szukanie dopasowań spośród większej bazy użytkowników. Oznacza to, że mamy możliwość przedstawienia większej liczby potencjalnych partnerów, co znacząco zwiększa szanse na znalezienie dopasowania. W przypadku Pakietu Basic, profil jest prezentowany innym użytkownikom korzystającym z wyższych pakietów, którzy mają pierwszeństwo akceptacji. Często wiąże się to z dłuższym czasem oczekiwania na dopasowanie, gdyż to inni użytkownicy decydują w pierwszej kolejności o tym, czy chcą nawiązać kontakt. Za każdym razem, gdy Twój profil jest prezentowany innej osobie otrzymujesz powiadomienie w panelu. Szczegóły na temat każdego z pakietów, w tym informacje o dodatkowych usługach i korzyściach, można znaleźć w zakładkach CENNIK i PROCES na naszej stronie internetowej.'},
                    {title: 'Jaki jest czas oczekiwania na otrzymanie kwestionariusza?', text: 'Czas oczekiwania na otrzymanie kwestionariusza może się różnić w zależności od kilku czynników. Ze względu na duże zainteresowanie naszymi usługami, nie jesteśmy w stanie podać dokładnego czasu oczekiwania. Kwestionariusze wysyłamy na bieżąco do osób zapisanych na listach oczekujących w miastach, w których aktualnie działa Biuro. Możesz sprawdzić, czy działamy w interesującej Cię lokalizacji sprawdzając na MAPIE. Czas oczekiwania zależy od liczby osób zapisanych na liście w danym mieście oraz możliwości naszych konsultantów. Każde zgłoszenie jest rozpatrywane w kolejności, w jakiej zostało złożone. Zapewniamy, że o nikim nie zapominamy i każda osoba zapisująca się na listę oczekujących jest ujęta w naszym systemie. Jeśli w panelu klienta widzisz wiadomość powitalną, oznacza to, że wszystko jest w porządku i Twoje zgłoszenie zostało prawidłowo zarejestrowane. Prosimy o cierpliwość i zapewniamy, że dokładamy wszelkich starań, aby jak najszybciej wysłać kwestionariusze do wszystkich oczekujących. Dziękujemy za zrozumienie i informujemy, że poinformujemy Cię o możliwości wypełnienia kwestionariusza mailem i/lub smsem.'},
                    {title: 'Jak dowiem się, że mogę wypełnić kwestionariusz?', text: 'O możliwości wypełnienia kwestionariusza poinformujemy Cię mailowo i/lub SMS-em. Możesz również samodzielnie sprawdzić status swojego zgłoszenia, logując się na swoje konto. Prosimy o cierpliwość i zapewniamy, że  dokładamy wszelkich starań, aby proces ten przebiegał jak najszybciej i sprawnie.'},
                    {title: 'Czy moje zgłoszenie nie zginęło?', text: 'Zapewniamy, że o nikim nie zapominamy i każda osoba zapisująca się na listę oczekujących jest ujęta w naszym systemie. Możesz również samodzielnie sprawdzić status swojego zgłoszenia, logując się na swoje konto. Jeśli widzisz wiadomość powitalną w zakładce Powiadomienia, oznacza to, że wszystko jest w porządku i Twoje zgłoszenie zostało prawidłowo zarejestrowane.'},
                    {title: 'Czy mogę usunąć swoje konto z listy oczekujących?', text: 'Tak, możesz usunąć swoje konto z listy oczekujących. Możesz to zrobić samodzielnie, logując się do swojego panelu użytkownika. Po zalogowaniu się, przejdź do zakładki Mój profil, gdzie znajdziesz opcję usunięcia konta. Wybierz tę opcję i postępuj zgodnie z instrukcjami na ekranie, aby zakończyć proces usuwania. Jeśli napotkasz jakiekolwiek trudności lub będziesz potrzebować dodatkowej pomocy, nasz zespół obsługi klienta jest dostępny, aby Ci pomóc. Dziękujemy za chęć skorzystania z naszych usług i jeśli kiedyś uznasz, że masz ochotę pójść na randkę, będzie nam przyjemnie znowu Cię powitać.'},
                    {title: 'Czy po przesłaniu kwestionariusza mogę go edytować?', text: 'Nie, po przesłaniu kwestionariusza do naszych konsultantów nie ma możliwości jego edycji. Dlatego bardzo ważne jest, aby wypełnić kwestionariusz w skupieniu i dokładnie przemyśleć swoje odpowiedzi. Rekomendujemy, aby wypełniać kwestionariusz w spokojnym miejscu, kiedy masz wolny czas na przemyślenia. Zwracaj szczególną uwagę na to, co wpisujesz, upewnij się, że Twoje odpowiedzi są precyzyjne i przemyślane, oraz unikaj literówek, które mogą wpłynąć na jakość przesłanych informacji. Pamiętaj także, że niektóre odpowiedzi z kwestionariusza będą widoczne na Twoim profilu. Podczas wypełniania kwestionariusz będziesz widzieć komunikaty informujące, które odpowiedzi będą publicznie dostępne, co pomoże Ci w ich odpowiednim sformułowaniu.'},
                    {title: 'Jak długo od otrzymania dostępu do kwestionariusza jest on aktywny?', text: 'Nie ma dokładnego limitu czasowego, w którym musisz wypełnić kwestionariusz. Im szybciej wypełnisz kwestionariusz, tym szybciej będziemy mogli rozpocząć proces poszukiwania odpowiednich dopasowań dla Ciebie. Mimo to, nie warto się spieszyć i wypełniać go bez namysłu. Zachęcamy do poświęcenia odpowiedniej ilości czasu na przemyślenie swoich odpowiedzi, aby były one jak najbardziej dokładne i odzwierciedlały Twoje oczekiwania oraz preferencje. Pamiętaj, że starannie wypełniony kwestionariusz zwiększa szanse na powodzenie całego procesu. Dziękujemy za Twoje zaangażowanie i współpracę!'},
                    {title: 'Czy moje odpowiedzi zostaną zapisane, jeśli przerwę wypełnianie kwestionariusza?', text: 'Tak, Twoje odpowiedzi zostaną zapisane, jeśli przerwiesz wypełnianie kwestionariusza. Możesz wrócić do kwestionariusza w innym czasie i uzupełnić dalsze odpowiedzi. Będzie on aktywny w Twoim panelu. Na tym etapie możesz jeszcze edytować swoje odpowiedzi oraz swobodnie przechodzić pomiędzy pytaniami. Dzięki temu masz możliwość dokładnego przemyślenia i dopracowania swoich odpowiedzi przed ich ostatecznym przesłaniem do naszych konsultantów.'},
                    {title: 'Kto widzi moje odpowiedzi?', text: 'Twoje odpowiedzi w kwestionariuszu są widoczne dla konsultanta, który zajmuje się szukaniem dla Ciebie dopasowania. Konsultant analizuje Twoje odpowiedzi, aby stworzyć Twój Profil Singla i na tej podstawie dobiera potencjalnych partnerów, którzy najlepiej odpowiadają Twoim preferencjom i oczekiwaniom. Niektóre z Twoich odpowiedzi będą widoczne także dla osób, które zostaną dopasowane do Ciebie. Podczas wypełniania kwestionariusza będziemy informować Cię, które informacje będą widoczne dla innych użytkowników, abyś miał/a pełną świadomość, co będzie udostępniane w Twojej wizytówce. Informacja „To będzie widoczne w profilu” znajduje się w górze ekranu podczas wypełniania kwestionariusza. Informacja ta jest podana dla każdego pytania. '},
                    {title: 'Jaki jest czas oczekiwania na dopasowanie?', text: 'Czas trwania procesu znalezienia odpowiedniego dla Ciebie dopasowania jest uzależniony od liczby osób w bazie klientów Biura Randkowego, które w dużej części spełniają określone przez Ciebie preferencje. Kluczowe znaczenie mają tutaj wypełniony kwestionariusz, udzielone odpowiedzi na pytania oraz określone oczekiwania i wymagania wobec przyszłego partnera/partnerki. Z tego powodu, nie jesteśmy w stanie podać dokładnego czasu, w jakim otrzymasz dopasowanie. Na tym etapie prosimy uzbroić się w cierpliwość, zdarza się, że pierwsze dopasowanie jest po kilku tygodniach. Wielkość naszej bazy nie ma tutaj znaczenia, ponieważ każdy użytkownik ze swoimi unikalnymi preferencjami stanowi odrębną kombinację cech i upodobań. Proces dopasowania opiera się przede wszystkim na kryteriach nienegocjowalnych, które przyjmujemy za wyznaczniki w naszej autorskiej metodzie doboru. Jest to czasochłonne, ale gwarantuje wyższą jakość i większe prawdopodobieństwo sukcesu w znalezieniu odpowiedniego partnera. Należy pamiętać, że nasz proces wymaga czasu i dokładności. Weryfikacja kwestionariuszy i wybór odpowiednich partnerów to zadanie, które wykonujemy z najwyższą starannością, poświęcając czas na dokładne zapoznanie się z informacjami dostarczonymi przez klientów, co umożliwia zaproponowanie najlepszego możliwego dopasowania. Zapewniamy, że każdy krok w procesie dopasowania jest realizowany z myślą o zadowoleniu naszych klientów i sukcesie w znalezieniu odpowiedniego partnera.'},
                    {title: 'Jak często pojawiają się dopasowania?', text: 'Biuro Randkowe różni się od standardowych aplikacji randkowych tym, że stawiamy na jakość, a nie ilość dopasowań. W odróżnieniu od aplikacji, gdzie można rozmawiać z wieloma osobami jednocześnie, skupiamy się na budowaniu jednej relacji na raz. Oznacza to, że nie można prowadzić rozmów z kilkoma osobami jednocześnie, co minimalizuje ryzyko rozproszenia uwagi i zapewnia skoncentrowanie się na budowaniu jednej, solidnej relacji. Jeśli dwie osoby są ze sobą sparowane, nie będą one widoczne jako propozycja dopasowania dla innych użytkowników, mimo pokrywających się preferencji. Naszym celem jest, aby każda relacja miała szansę się rozwijać bez zakłóceń. Możliwość zaproponowania nowego dopasowania pojawia się dopiero w momencie, gdy jedna ze stron zdecyduje się zrezygnować z poprzedniego. Nasi użytkownicy często spełniają kryteria wielu osób jednocześnie, jednak są im przedstawiani w kolejności, aby dać każdej relacji realną szansę na powodzenie. Dlatego czasem na odpowiednie dopasowanie trzeba poczekać.Chcielibyśmy również zaznaczyć, że mimo tego, iż liczba dopasowań może wydawać się niewielka np. raz na kilka tygodni, to każda propozycja jest starannie przemyślana i dobrana z największą uwagą. Wierzymy, że wartość tkwi w jakości relacji, a nie w liczbie potencjalnych partnerów.'},
                    {title: 'Jak wygląda proces dopasowywania partnerów?', text: 'Proces dopasowywania partnerów w Biurze Randkowym jest starannie zaplanowany i realizowany przez doświadczone osoby, które analizują każdy wpływający do Biura Randkowego kwestionariusz. Po otrzymaniu i weryfikacji kwestionariusza, Twoje odpowiedzi są analizowane przez konsultanta, aby stworzyć Twój Profil Singla. Na tej podstawie dobieramy potencjalnych partnerów, którzy najlepiej odpowiadają Twoim preferencjom i oczekiwaniom. W pierwszej kolejności bierzemy pod uwagę przede wszystkim nienegocjowalne kwestie, które stanowią fundament udanych relacji, takie jak chęć posiadania dzieci czy wyznawana wiara. Dzięki temu dwie osoby, które zostają sobie przedstawione, mogą mieć pewność, że pod względem najważniejszych kwestii są kompatybilne. W dalszej kolejności analizowana jest preferencja wiekowa, pod względem wyglądu i zainteresowań, jednak te obszary sa traktowane już jako wskazówka do lepszego dopasowania, a nie warunek konieczny. Proces ten jest dokładnie przemyślany i uwzględnia najistotniejsze aspekty życia, które wpływają na długoterminową harmonię i zadowolenie w związku. Staramy się, aby każde dopasowanie miało jak największy potencjał na sukces, co wymaga czasu i dogłębnej analizy.'},
                    {title: 'Czy mogę rozmawiać z kilkoma osobami naraz?', text: 'Nie, ideą Biura Randkowego jest możliwość rozmowy z jedną dopasowaną osobą w tym samym czasie. Uważamy, że takie podejście pozwala na bardziej skoncentrowane i skuteczne budowanie relacji. Jeśli jednak w rozmowie okaże się, że dopasowana osoba nie spełnia Twoich oczekiwań, możesz zrezygnować z tego dopasowania i czekać na kolejne propozycje.'},
                    {title: 'Ile czasu na zaakceptowanie lub odrzucenie dopasowania ma drugi użytkownik?', text: 'Kiedy użytkownik otrzymuje propozycję dopasowania, zostaje o tym poinformowany drogą mailową i powiadomieniem w panelu. Jeśli w przeciągu tygodnia nie zareaguje, wysyłamy maila przypominającego. Jeśli brak reakcji drugiej osoby stanie się zbyt męczący lub niekomfortowy, to po Twojej stronie leżeć będzie podjęcie decyzji o rezygnacji z tego konkretnego dopasowania, ponieważ nie wprowadzamy odgórnie żadnych ograniczeń czasowych dla użytkowników. W takiej sytuacji to Tobie pozostawiamy decyzję, jak długo jesteś gotowy/a czekać na odpowiedź drugiej strony. Jeśli zdecydujesz się na rezygnację, zrozumiemy Twoją decyzję i przystąpimy do poszukiwań nowego dopasowania.'},
                    {title: 'Co się dzieje kiedy dopasowana osoba mi się nie podoba?', text: 'Jeśli dopasowana osoba Ci się nie spodoba, masz możliwość rezygnacji z tego dopasowania. Dopasowany do Ciebie użytkownik zostanie poinformowany o Twojej decyzji za pomocą powiadomienia w panelu. Po skorzystaniu z tej funkcji rozmowa z tą osobą nie będzie już możliwa, a proces poszukiwania kolejnego dopasowania rozpocznie się na nowo. Chcemy podkreślić, że postrzeganie drugiej osoby jako atrakcyjnej jest kwestią niezwykle indywidualną. Każdy z nas ma swoje unikalne preferencje i to, co dla jednej osoby jest atrakcyjne, dla innej może być mniej istotne. Dokładamy wszelkich starań, aby dopasować osoby do siebie na podstawie wielu kryteriów, jednak nie jesteśmy w stanie patrzeć na człowieka oczami użytkowników. Dlatego tak ważne jest, aby dać sobie i innym szansę na poznanie się bliżej, co może prowadzić do odkrycia cech, które początkowo mogły nie być dostrzegalne. Konsultant ma także więcej informacji o tej osobie, warto więc zaufać jego doświadczeniu.'},
                    {title: 'Jakie są opcje komunikacji z potencjalnymi partnerami przed spotkaniem?', text: 'Oferujemy prostą aplikację dedykowaną naszym klientom, która służy do rozpoczęcia rozmowy z potencjalnymi partnerami. Ta aplikacja nie wymaga udostępniania żadnych danych osobowych, co zapewnia bezpieczeństwo i prywatność naszym użytkownikom. Rozmowa i korzystanie z naszego komunikatora są możliwe, kiedy obie osoby zaakceptują dopasowanie, co gwarantuje, że każda strona jest zainteresowana nawiązaniem kontaktu. Aplikacja pozwala na swobodne prowadzenie rozmów, dzięki czemu możesz lepiej poznać potencjalnego partnera przed spotkaniem. Wielu naszych klientów, po wstępnym kontakcie za pośrednictwem aplikacji, decyduje się na przeniesienie komunikacji na inne, popularne komunikatory, jeśli czują się komfortowo i chcą kontynuować rozmowę w innym środowisku. Naszym celem jest zapewnienie wygodnych, prostych i bezpiecznych narzędzi do komunikacji, które ułatwią Ci poznanie nowej osoby i przygotowanie się do rozmowy telefonicznej lub spotkania.'},
                    {title: 'Czy mogę otrzymać raport z moich dotychczasowych dopasowań i spotkań?', text: 'Cała historia Twoich dopasowań jest dostępna na Twoim profilu. Z każdą aktualizacją otrzymujesz powiadomienie, które zostaje zapisane w Twoim panelu, dzięki czemu masz pełny wgląd w dotychczasowe działania.'},
                    {title: 'Czy istnieje limit dopasowań?', text: 'Biuro Randkowe oferuje nielimitowaną liczbę dopasowań. Dzięki temu masz możliwość kontynuowania poszukiwań odpowiedniego partnera przez cały czas obowiązywania pakietu, z którego korzystasz.'},
                    {title: 'Czy może się zdarzyć, że podczas świadczenia usługi nie otrzymam dopasowania?', text: 'Jest to możliwe, jednak są to wyjątkowe przypadki. Należy wziąć pod uwagę, że czasami preferencje użytkowników są bardzo specyficzne, a w rzadkich przypadkach bardzo wygórowane. Przyznajemy, że może to utrudniać znalezienie odpowiedniego partnera. W innych przypadkach mogą istnieć ograniczenia w bazie danych, które sprawiają, że znalezienie dopasowania odpowiadającego większości oczekiwań danej osoby będzie niemożliwe. Naszym celem jest jak najlepiej wspierać naszych klientów w znalezieniu odpowiedniego partnera, dlatego zachęcamy do otwartości i elastyczności w swoich oczekiwaniach, co może zwiększyć szanse na sukces.'},
                    {title: 'Czy dopasowanie będzie spełniało wszystkie moje wymagania?', text: 'Naszym priorytetem jest, aby dopasowanie w pierwszej kolejności spełniało Twoje najważniejsze, nienegocjowalne wartości. Staramy się jak najlepiej uwzględniać wszystkie Twoje preferencje, jednak nie możemy zagwarantować, że każde dopasowanie będzie idealne pod każdym względem. Dążymy do tego, aby dopasowanie w jak największym stopniu przypominało  osobę, którą opisujesz w kwestionariuszu. Chcemy podkreślić, że postrzeganie drugiej osoby jako atrakcyjnej jest kwestią niezwykle indywidualną. Każdy z nas ma swoje unikalne preferencje i to, co dla jednej osoby jest atrakcyjne, dla innej może być mniej istotne. Nasi konsultanci dokładają wszelkich starań, aby dopasować osoby do siebie na podstawie wielu kryteriów, jednak nie są w stanie patrzeć na człowieka oczami użytkowników. Dlatego tak ważne jest, aby dać sobie i innym szansę na poznanie się bliżej, co może prowadzić do odkrycia cech, które początkowo mogły nie być dostrzegalne.'},
                    {title: 'Czy mogę porozmawiać z moim konsultantem?', text: 'Konsultanci pozostają anonimowi, a ich dane nie są udostępniane ze względu na komfort i prywatność ich pracy. Usługa nie obejmuje bezpośrednich rozmów z konsultantami. Taki system został wprowadzony, aby zapewnić najwyższy poziom obiektywności i profesjonalizmu. Konsultanci mają dostęp do Twojego kwestionariusza, z wyłączeniem danych, które umożliwiałyby im Twoją identyfikację i tym samym zaburzyć obiektywizm. Celem jest zapewnienie, że każdy profil jest analizowany w sposób rzetelny i bezstronny, co zwiększa szanse na skuteczne dopasowanie. Dzięki temu procesowi możemy zagwarantować, że każdy przypadek traktowany jest z pełnym profesjonalizmem, koncentrując się na znalezieniu jak najlepszego dopasowania, zgodnie z Twoimi najważniejszymi wymaganiami i preferencjami. '},
                    {title: 'Czy mogę poznać powód, dla którego ktoś nie był mną zainteresowany?', text: 'Zrozumienie, dlaczego ktoś nie był zainteresowany, może być kuszące, jednak często nie jesteśmy gotowi na otrzymywanie takich informacji. Tego typu oceny mogą negatywnie wpłynąć na nasze samopoczucie, mimo że są subiektywne i nie zawsze odzwierciedlają naszą prawdziwą wartość. Z tego powodu nasze Biuro Randkowe nie zbiera automatycznie powodów odmowy zainteresowania. Chcemy, aby każdy użytkownik czuł się pewnie i komfortowo, koncentrując się na pozytywnych aspektach procesu randkowania i nawiązywania nowych znajomości. Ważne jest, aby pamiętać, że brak zainteresowania ze strony jednej osoby nie oznacza, że nie znajdziesz odpowiedniego partnera, który doceni Twoje unikalne cechy i wartości.'},
                    {title: 'Jakie warunki trzeba spełnić, żeby zostać klientem Biura Randkowego?', text: 'Aby zostać klientem/tką naszego biura, należy spełnić kilka warunków. Przede wszystkim, musisz być osobą pełnoletnią, czyli mieć ukończone 18 lat. Ważne jest również, abyś był osobą wolną, co oznacza, że nie jesteś w związku małżeńskim ani innym stałym związku. Gotowość do spotkań na terenie Polski jest także kluczowa, ponieważ Biuro Randkowe działa w konkretnych miastach w Polsce. Zainteresowanie randkowaniem i chęć poznawania nowych osób to kolejne ważne kryterium. Nasza usługa skierowana jest do tych osób, które są otwarte na nowe znajomości i gotowe na randki. Oprócz tego, musisz być gotów opłacić nasze usługi zgodnie z wybranym pakietem, co pozwala nam na efektywną pracę nad znalezieniem odpowiedniego dopasowania dla Ciebie. Na koniec, konieczne jest wypełnienie naszego kwestionariusza. To narzędzie pozwala nam lepiej zrozumieć Twoje oczekiwania, preferencje i wartości, co z kolei pomaga nam w dokładnym dopasowaniu odpowiednich kandydatów. Spełnienie tych warunków umożliwia nam skuteczną pracę nad znalezieniem dla Ciebie idealnego partnera, zgodnie z Twoimi indywidualnymi wymaganiami i oczekiwaniami.'},
                    {title: 'Czy Biuro Randkowe oferuje usługi dla osób z niepełnosprawnościami?', text: 'Tak, nasze Biuro Randkowe oferuje usługi również dla osób z niepełnosprawnościami. Jesteśmy świadomi, że każdy klient ma unikalne potrzeby i wymagania, dlatego dokładamy wszelkich starań, aby nasze usługi były dostępne i przyjazne dla wszystkich. Podczas wypełniania kwestionariusza, prosimy o uwzględnienie istotnych informacji dotyczących niepełnosprawności w opisie, aby osoba, która zostanie do Ciebie dopasowania, nie czuła się niepoinformowana.'},
                    {title: 'Czy Biuro Randkowe oferuje usługi dla osób LGBTQ+?', text: 'Tak, nasze Biuro Randkowe oferuje usługi również dla osób LGBTQ+. Jesteśmy otwarci na wszystkie orientacje seksualne i staramy się zapewnić wsparcie oraz dopasowania dla każdej osoby, niezależnie od jej tożsamości. Jednakże, ze względu na mniejszą liczebność społeczności LGBTQ+ w naszej bazie użytkowników, liczba potencjalnych dopasowań może być znacząco mniejsza w porównaniu do heteroseksualnych użytkowników. Mimo to, dokładamy wszelkich starań, aby znaleźć odpowiednie dopasowania i wspierać naszych klientów w ich poszukiwaniach.'},
                    {title: 'Czy Biuro Randkowe działa za granicą?', text: 'Nie, Biuro Randkowe nie działa za granicą. Nasze usługi są dostępne wyłącznie na terenie Polski, gdzie koncentrujemy się na zapewnieniu wysokiej jakości dopasowań i wsparcia dla naszych klientów. Osoby mieszkające poza granicami Polski mogą zapisywać się do Biura, pod warunkiem że są gotowe do spotkań w Polsce. Podczas procesu rejestracji można wybrać miasta, w których preferuje się organizację spotkań.'},
                    {title: 'Czy mogę zarekomendować Biuro Randkowe znajomym i otrzymać za to korzyści?', text: 'W tym momencie nie mamy programu rekomendacji. Będzie nam bardzo miło, że pomożesz w budowaniu społeczności Biura Randkowego, bo dzięki temu pomożesz znajomym i innym singlom w Polsce znaleźć najlepszą dla siebie osobę.'},
                    {title: 'Czy mogę ustawić preferencje dotyczące języka, którym posługuje się potencjalny partner?', text: 'Tak, możesz zawrzeć w kwestionariuszu informację, że interesuje Cię także relacja z osobą niepolskojęzyczną. Nie wszystkie osoby jednak informują w jakim języku mówią, dlatego jeśli Ty nie mówisz po polsku może to mieć wpływ na liczbę prawidłowych dopasowań.'},
                ],
                thirdColumnQuestions: [
                    {title: 'Co zrobić, jeśli napotykam problemy z płatnością? ', text: 'Jeśli napotykasz problemy z płatnością, upewnij się, że wszystkie pola są poprawnie uzupełnione (dane kontaktowe, adres) oraz że zostały zaakceptowane zgody i regulamin. Pakiet jest możliwy do opłacenia dopiero po wypełnieniu kwestionariusza. Jeśli problem nadal występuje, skontaktuj się z naszym zespołem obsługi klienta pod adresem kontakt@biurorandkowe.pl.'},
                    {title: 'Jak mogę odzyskać hasło do mojego konta?', text: 'Jeśli zapomniałeś/aś hasło do swojego konta, możesz je łatwo odzyskać, korzystając z funkcji "Resetuj hasło" dostępnej na stronie logowania. Wprowadź swój adres e-mail, a na Twoją skrzynkę mailową zostanie wysłana wiadomość z instrukcjami, jak zresetować hasło. W przypadku problemów z odzyskaniem hasła, skontaktuj się z naszym zespołem obsługi klienta pod adresem kontakt@biurorandkowe.pl.'},
                    {title: 'Co zrobić, jeśli nie mogę się zalogować na swoje konto?', text: 'Jeśli masz problemy z zalogowaniem się na swoje konto, upewnij się, że wprowadzasz poprawne dane logowania. Sprawdź, czy używasz właściwego adresu e-mail i hasła. Jeśli nadal nie możesz się zalogować, spróbuj zresetować hasło, korzystając z funkcji "Resetuj hasło". Jeśli problem nadal występuje, skontaktuj się z naszym zespołem obsługi klienta, aby uzyskać pomoc.'},
                    {title: 'Jak mogę zmienić dane kontaktowe w swoim profilu?', text: 'Jeśli chcesz zmienić swoje dane kontaktowe, takie jak adres e-mail, numer telefonu czy miejsce zamieszkania, skontaktuj się z naszym zespołem obsługi klienta pod adresem kontakt@biurorandkowe.pl. Zmiany te nie mogą być dokonane samodzielnie w panelu użytkownika. Nasz zespół pomoże Ci w aktualizacji danych i upewni się, że Twój profil zawiera aktualne informacje.'},
                    {title: 'Czy mogę zawiesić swój profil? ', text: 'Funkcja zawieszenia profilu jest dostępna jedynie dla osób korzystających z Pakietu Premium. Jeśli jesteś użytkownikiem Pakietu Premium i chcesz zawiesić swój profil, skontaktuj się z naszym zespołem obsługi klienta pod adresem kontakt@biurorandkowe.pl. Nasi konsultanci pomogą Ci w zawieszeniu profilu na określony czas, zgodnie z Twoimi potrzebami. Jeśli korzystasz z Pakietu Basic lub Plus możesz zwrócić się z prośbą o tymczasowe wyłączenie konta, jednak aktywność pakietu nie zostanie przedłużona i wygaśnie w terminie, który podany jest w zakładce Twój Pakiet. Na okres wyłączenia konta nie będziemy szukać dla Ciebie nowych dopasowań ani proponować Twojego profilu innym użytkownikom. Kiedy zechcesz wrócić do bazy Biura uprzejmie prosimy o przesłanie informacji na adres kontakt@biurorandkowe.pl.'},
                    {title: 'Jak mogę zrezygnować z usług Biura Randkowego?', text: 'Jeśli chcesz zrezygnować z usług Biura Randkowego, możesz to zrobić, usuwając swoje konto w panelu użytkownika. Po zalogowaniu się, przejdź do zakładki "Mój profil" i wybierz opcję usunięcia konta. Postępuj zgodnie z instrukcjami na ekranie, aby zakończyć proces usuwania. W przypadku jakichkolwiek pytań lub problemów, skontaktuj się z naszym zespołem obsługi klienta, który udzieli Ci niezbędnej pomocy. Usunięcie konta przed końcem okresu, za które zostało opłacone nie powoduje zwrotu części należności za usługę.'},
                    {title: 'Jak mogę skontaktować się z obsługą klienta?', text: 'Jeśli masz jakiekolwiek pytania lub potrzebujesz pomocy, skontaktuj się z naszym zespołem obsługi klienta pod adresem kontakt@biurorandkowe.pl. Nasz zespół jest dostępny, aby odpowiedzieć na Twoje pytania i udzielić niezbędnej pomocy.'},
                    {title: 'Jak mogę włączyć powiadomienia?', text: 'Twoje powiadomienia będą działać po zainstalowaniu aplikacji. W tym celu przejdź <a target="_blank" href="https://support.google.com/chrome/answer/9658361?hl=pl&co=GENIE.Platform%3DiOS&oco=1">tutaj</a>.'},
                    {title: 'Dlaczego moje powiadomienia nie działają?', text: 'Twoje powiadomienia będą działać po zainstalowaniu aplikacji. Jeśli dalej się nie pokazują przejdź <a target="_blank" href="https://support.apple.com/pl-pl/108781">tutaj</a> dla iPhone lub <a target="_blank" href="https://support.google.com/android/answer/9079661?hl=pl#zippy=">tutaj</a> dla Android.'},
                ],
            }
        },
        components: {
            SingleFaq,
            HeaderComponent,
            Footer
        },
    }
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";


    .faqs {
        padding-top: 120px;
        background-color: $bege3;
        padding-bottom: 84px;
        &__title {
            font-family: 'Oswald', sans-serif;
            font-size: 30rem;
            line-height: 45.5rem;
            color: $begeLight3;
            text-transform: uppercase;
            position: relative;
            margin-bottom: 53px;
            &::after {
                content: '';
                position: absolute;
                bottom: 25px;
                left: 0px;
                width: 100%;
                height: 1px;
                background-color: $black;
                @include breakpoint-max("mobile") {
                    content: none;
                }
            }
            @include breakpoint-max("xs-tablet") {
                margin-bottom: 15px;
            }
            @include breakpoint-max("mobile") {
                font-size: 48px;
                line-height: 50px;
                padding: 60px 0px;
                margin-bottom: 0;
            }
        }
        &__columns {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 93px;
            @include breakpoint-max('s-tablet') {
                column-gap: 30px;
            }
            @include breakpoint-max("mobile") {
                flex-direction: column;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 30px;
            }
        }
        &__columns-left,
        &__columns-right {
            flex: 1;
        }
        &__columns-left-title,
        &__columns-right-title {
            font-family: 'Helvetica', sans-serif;
            font-size: 3.2rem;
            line-height: 3.9rem;
            color: $black;
            margin-bottom: 62px;
            @include breakpoint-max("xs-tablet") {
                margin-bottom: 30px;
            }
            @include breakpoint-max("mobile") {
                // display: none;
            }
        }
        &__columns-right-title {
            opacity: 1;
        }
        &__columns-left-list,
        &__columns-right-list {
            list-style: none;
            li {
                border-top: 1px solid $black;
                padding: 20px 0 26px;
            }
        }
        &__more {
            padding-top: 9px;
            a {
                text-decoration: none;
                display: block;
                width: fit-content;
            }
            p {
                padding: 11px 31px;
                border: 1px solid $black;
                font-family: 'Open Sans', sans-serif;
                font-size: 2.4rem;
                line-height: 3rem;
                font-weight: 700;
                color: $black;
                text-transform: uppercase;
                width: fit-content;
                cursor: pointer;

            }
        }
        &__columns-content-text {
            p {
                font-size: 14px;
                line-height: 20px;

            }
        }
        &__under-col {
            margin-top: 40px;
            @include breakpoint-max("mobile") {
                margin-top: 30px;
            }
        }
    }

</style>
