
<template>
    <section class="image-and-text">
        <div class="scroll-div" id="o-biurze"></div>
        <div class="image-and-text__wrapper">
            <div class="image-and-text__left">
                <div class="image-and-text__lottie-box">
                    <img src="@/assets/images/hearts-bcg.jpg" alt="" class="image-and-text__lottie-bcg">
                    <Vue3Lottie class="image-and-text__lottie" :animationData="Hearts" :height="364" :width="546" />
                </div>
            </div>
            <div class="image-and-text__right">
                <svg class="image-and-text__title-desktop" xmlns="http://www.w3.org/2000/svg" width="206" height="921" viewBox="0 0 206 921" fill="none">
                    <path d="M205.751 860.693C205.751 875.677 203.116 887.533 197.847 896.261C192.578 904.988 185.003 911.245 175.123 915.033C165.243 918.82 153.469 920.714 139.802 920.714H65.455C51.623 920.714 39.8493 918.82 30.134 915.033C20.4187 911.245 13.0087 904.988 7.904 896.261C2.63467 887.533 0 875.677 0 860.693C0 845.543 2.63467 833.605 7.904 824.878C13.0087 815.986 20.4187 809.646 30.134 805.859C39.8493 802.071 51.623 800.178 65.455 800.178H139.802C153.469 800.178 165.243 802.071 175.123 805.859C185.003 809.646 192.578 815.986 197.847 824.878C203.116 833.605 205.751 845.543 205.751 860.693ZM174.382 860.693C174.382 856.082 173.229 852.706 170.924 850.566C168.619 848.425 165.655 847.025 162.032 846.367C158.409 845.708 154.704 845.379 150.917 845.379H54.587C50.635 845.379 46.93 845.708 43.472 846.367C39.8493 847.025 36.8853 848.425 34.58 850.566C32.2747 852.706 31.122 856.082 31.122 860.693C31.122 864.974 32.2747 868.185 34.58 870.326C36.8853 872.466 39.8493 873.866 43.472 874.525C46.93 875.183 50.635 875.513 54.587 875.513H150.917C154.704 875.513 158.409 875.266 162.032 874.772C165.655 874.113 168.619 872.796 170.924 870.82C173.229 868.679 174.382 865.303 174.382 860.693Z" fill="#F4F2EE"/>
                    <path d="M202.787 710.073H2.71699V657.956C2.71699 649.393 3.37566 641.325 4.693 633.75C6.01033 626.011 8.56267 619.177 12.35 613.249C15.9727 607.321 21.242 602.71 28.158 599.417C34.9093 595.959 43.8837 594.23 55.081 594.23C63.1497 594.23 69.9833 595.465 75.582 597.935C81.016 600.24 85.3797 603.616 88.673 608.062C91.9663 612.343 94.1893 617.53 95.342 623.623C96.33 615.884 98.8823 609.379 102.999 604.11C106.951 598.841 112.303 594.889 119.054 592.254C125.805 589.455 133.956 588.055 143.507 588.055C153.881 588.055 162.855 589.455 170.43 592.254C177.84 594.889 183.933 598.758 188.708 603.863C193.483 608.968 197.024 615.307 199.329 622.882C201.634 630.292 202.787 638.855 202.787 648.57V710.073ZM171.912 665.86V656.474C171.912 646.759 169.442 640.09 164.502 636.467C159.562 632.68 152.317 630.786 142.766 630.786C135.191 630.786 129.181 631.609 124.735 633.256C120.289 634.903 117.078 637.62 115.102 641.407C112.961 645.194 111.891 650.299 111.891 656.721V665.86H171.912ZM82.745 665.86V657.215C82.745 650.464 81.757 645.359 79.781 641.901C77.6403 638.443 74.594 636.138 70.642 634.985C66.69 633.668 61.9147 633.009 56.316 633.009C51.0467 633.009 46.683 633.997 43.225 635.973C39.6023 637.784 36.8853 640.584 35.074 644.371C33.2627 647.994 32.357 652.604 32.357 658.203V665.86H82.745Z" fill="#F4F2EE"/>
                    <path d="M202.787 564.617H2.71699V520.404H202.787V564.617Z" fill="#F4F2EE"/>
                    <path d="M205.751 432.773C205.751 448.087 203.281 460.108 198.341 468.835C193.236 477.398 185.909 483.408 176.358 486.866C166.643 490.324 154.787 492.053 140.79 492.053H2.71699V448.334H147.459C151.576 448.334 155.692 448.005 159.809 447.346C163.926 446.687 167.301 445.288 169.936 443.147C172.571 440.842 173.888 437.384 173.888 432.773C173.888 427.998 172.571 424.54 169.936 422.399C167.301 420.258 163.926 418.941 159.809 418.447C155.692 417.788 151.576 417.459 147.459 417.459H2.71699V373.493H140.79C154.787 373.493 166.643 375.304 176.358 378.927C185.909 382.385 193.236 388.395 198.341 396.958C203.281 405.521 205.751 417.459 205.751 432.773Z" fill="#F4F2EE"/>
                    <path d="M202.787 345.362H2.71699V290.281C2.71699 276.778 4.199 265.087 7.163 255.207C10.127 245.327 15.4787 237.67 23.218 232.236C30.7927 226.637 41.5783 223.838 55.575 223.838C63.8083 223.838 71.136 224.497 77.558 225.814C83.98 227.131 89.5787 229.601 94.354 233.224C98.9647 236.682 102.834 241.704 105.963 248.291L202.787 220.874V266.569L113.126 288.552V301.149H202.787V345.362ZM87.685 301.149V288.799C87.685 282.542 86.6147 277.684 84.474 274.226C82.1687 270.768 78.9577 268.38 74.841 267.063C70.5597 265.581 65.455 264.84 59.527 264.84C50.9643 264.84 44.2953 266.404 39.52 269.533C34.7447 272.662 32.357 278.425 32.357 286.823V301.149H87.685Z" fill="#F4F2EE"/>
                    <path d="M202.787 204.915H176.111L32.604 156.009V202.692H2.71699V112.537H27.664L172.9 162.184V112.043H202.787V204.915Z" fill="#F4F2EE"/>
                    <path d="M202.787 90.402H2.71699V0.494001H32.851V46.189H82.498V11.362H113.126V46.189H172.9V0H202.787V90.402Z" fill="#F4F2EE"/>
                </svg>
                <div class="image-and-text__content">
                    <h2 class="image-and-text__title">
                        O BIURZE
                    </h2>
                    <p class="image-and-text__text">
                        <strong>BIURO RANDKOWE TO MIEJSCE DLA WSPÓŁCZESNYCH SINGLI</strong>

                    </p>
                    


                    <p class="image-and-text__text">
                        Dla wszystkich, którzy chcieliby poznawać nowe osoby i stworzyć udany związek. Dla osób zmęczonych aplikacjami randkowymi i złotymi radami, takimi jak „Wyjdź do ludzi” lub „Obniż wymagania”. One nie rozwiązują problemu, który ma swoje źródło najczęściej w obecnym stylu życia.
                    </p>
                    <p class="image-and-text__text">
                        Jest mnóstwo mężczyzn i kobiet, którzy mają wiele do zaoferowania, ale zwyczajnie… nie wiedzą o swoim istnieniu. Zmieniamy to i poznajemy ze sobą osoby, które wiele łączy i warto, żeby się spotkały. Od nich będzie zależało, co wydarzy się dalej.
                    </p>
                    <p class="image-and-text__text">
                        Naszym celem jest to, żeby każdy był tu na chwilę, ale stworzył relację na lata.
                    </p>
                    <SimpleButton
                        text="Zapisz się"
                        route="/register"
                        class="image-and-text__button"
                    />
                </div>
            </div>
        </div>
    </section>
</template>
  
  <script>
    import { Vue3Lottie } from 'vue3-lottie';
    import Hearts from '@/assets/lottie/hearts.json';
    import SimpleButton from '@/components/landing-03/atoms/SimpleButton.vue';


    export default {
    data() {
        return {
            Hearts
        };
    },
    props: {
        
    },
    components: {
        Vue3Lottie,
        SimpleButton
    },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/global.scss";


    .image-and-text {
        border-bottom: 1px solid $black;

        &__wrapper {
            display: flex;
            height: 100vh;
            min-height: 600px;

            @include breakpoint-max('tablet') {
                flex-direction: column-reverse;
                height: initial;

            }
        }
        &__left {
            flex: 1;
            // max-width: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            // margin-right: 20px;
            column-gap: 20px;
            @include breakpoint-max('tablet') {
                text-align: center;
                align-items: center;
                padding: 30px 0;
                max-width: 100%;
                flex: 1;

            }

        }
        &__content {
            margin-left: 34px;
            max-width: 510px;
            margin-right: 30px;
            padding: 50px;
            
            @include breakpoint-max('xl-tablet') {
                padding: 50px 0;
                margin: 0 auto;
            }
            @include breakpoint-max('mobile') {
                margin: 0 30px;
            }
            // @include breakpoint-max('m-large') {
            //     margin-left: 90px;
            // }
        }

        &__text {
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin-bottom: 30px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            strong {
                font-size: 16px;
            }
            @include breakpoint-max('mobile') {
                font-size: 16px;

            }
        }

        &__right {
            flex: 1;
            // max-width: 50%;
            border-left: 1px solid $black;
            display: flex;
            align-items: center;
            position: relative;
            background-color: $bege3;

            @include breakpoint-max('tablet') {
                border: none;
                border-top: 1px solid $black;
                max-width: 100%;
                flex: 1;

            }
        }
        &__img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            @include breakpoint-max('tablet') {
                object-position: center 20%;
            }
        }
        &__title-desktop {
            height: 100%;
            @include breakpoint-max('xl-tablet') {
                display: none;
            }
        }
        &__title {
            font-size: 80px;
            line-height: 85px;
            font-family: 'Oswald', sans-serif;
            font-weight: 800;
            margin-bottom: 28px;
            color: $begeLight3;
            display: none;
            @include breakpoint-max('xl-tablet') {
                font-size: 48px;
                line-height: 45px;
                display: inline-block;
            }
        }
        &__subtitle {
            font-size: 6.4rem;
            line-height: 7rem;
            font-family: 'adobe-caslon-pro', serif;
            font-weight: 800;
            margin-bottom: 8%;
            @include breakpoint-max('tablet') {
                font-size: 28px;
                line-height: 35px;
            }
        }
        &__author {
            text-transform: uppercase;
            font-size: 2.4rem;
            line-height: 2.8rem;
            @include breakpoint-max('tablet') {
                font-size: 16px;
                line-height: 20px;

            }
            strong {
                font-weight: 800;
            }
        }
        &__lottie-box {
            position: relative;
            margin-left: 143px;
            margin-right: 160px;
            @include breakpoint-max('m-large') {
                margin-left: 30px;
                margin-right: 30px;

            }
        }
        &__lottie-bcg {
            width: 100%;
            height: 100%;
        }
        &__lottie {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            padding: 40px;
        }
        &__button {
            margin-top: 50px;
            @include breakpoint-max('mobile') {
                margin-top: 0;
                
            }
        }
    }

</style>
  