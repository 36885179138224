<template>
    <section class="map-section">
        <div class="scroll-div" id="map-section"></div>
        <div class="map-section__header-container">
            <h1>Gdzie już działamy?</h1>
        </div>
        <div class="map-section__map-container">
            <img
                src="@/assets/images/Mapka-biuro-randkowe-krzywe.svg"
                alt="map image"
                class="map-section-map"
            />
        </div>
        <div class="map-section__heart">
            <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.18 0.470001C15.07 0.470001 17.9 1.31999 20.31 2.89999C21.41 3.59999 22.5 4.31999 23.56 5.07999C24.93 6.19999 26.9 6.19999 28.27 5.07999C29.34 4.30999 30.45 3.56 31.58 2.88C35.1 0.480005 39.55 -0.159998 43.6 1.19C49.57 3.15 52.83 9.57001 50.87 15.54C50.48 16.74 49.9 17.84 49.15 18.84C46.15 22.59 42.89 26.13 39.4 29.41C36.54 32.27 33.62 35.09 30.72 37.93C28.15 40.33 24.22 40.48 21.47 38.29C15.24 32.95 9.42999 27.14 4.06999 20.92C2.32999 18.99 1.11998 16.65 0.559981 14.11C-0.560019 7.82999 3.60999 1.84 9.87999 0.720001C9.94999 0.710001 10.03 0.699993 10.1 0.679993C10.8 0.579993 11.49 0.51999 12.19 0.48999H12.17H12.18V0.470001Z" fill="#D13724"/>
            </svg>
            <h2>Tutaj jesteśmy!</h2>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {}
        },
        props: {},
        computed: {},
        components: {}
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/global.scss';

    .map-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 100px 16px;
        background-color: #e1dbd5;
        @include breakpoint-max('xs-tablet') {
            padding: 32px 16px;
        }
        &__header-container {
            display: flex;
            align-items: center;
            gap: 6px;
            text-align: center;
            h1 {
                font-weight: 700;
                margin: 0;
                font-family: Oswald;
                font-size: clamp(56px, 6vw, 120px);
            }
        }
        &__map-container {
            display: flex;
            max-width: 1000px;
            width: 100%;
            img {
                width: 100%;
                height: auto;
                display: flex;
            }
        }
        &__heart{
            display: flex;
            align-items: center;
            font-family: Oswald;
            svg{
                margin-right: 8px;
            }
        }
    }
</style>
