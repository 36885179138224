<!-- TODO: nieprawidłowe dane -->
<template>
    <div class="admin-basic-accepted admin-basic-accepted">
        <div class="admin-users-view__wrapper">
            <div class="admin-basic-accepted__left">
                <div class="admin-basic-accepted__left-top">
                    <div class="admin-basic-accepted__left-top-l">
                        <router-link
                            class="admin-basic-accepted__left-prev"
                            v-if="linkPrev"
                            :to="linkPrev"
                        >
                            Powrót
                        </router-link>
                        <router-link
                            class="admin-basic-accepted__left-prev"
                            v-else
                            to="/admin/users/Warszawa/1"
                        >
                            Powrót
                        </router-link>
                    </div>
                    <div class="admin-basic-accepted__right-user-heading">
                        <h2>Dopasowania:</h2>
                        <div
                            class="admin-basic-accepted__right-user-heading-boxes"
                        >
                            <router-link
                                :to="'/admin/basic/verify/' + user?._id"
                            >
                                <figure>
                                    <img
                                        src="../assets/images/ludzik1.png"
                                        alt="Photo"
                                    />
                                </figure>
                                <p>Do weryfikacji</p>
                            </router-link>
                            <router-link
                                :to="'/admin/basic/accepted/' + user?._id"
                            >
                                <figure>
                                    <div
                                        class="admin-basic-accepted__right-user-heading-boxes-second"
                                    ></div>
                                </figure>
                                <p>Zaakceptowane</p>
                            </router-link>
                            <router-link
                                :to="'/admin/basic/cancelled/' + user?._id"
                            >
                                <figure>
                                    <img
                                        src="../assets/images/ludzik1.png"
                                        alt="Photo"
                                    />
                                </figure>
                                <p>Rezygnacja</p>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="admin-to-verify__loader-wrapper" v-if="loading">
                    <Loader />
                </div>
                <div v-if="!loading" class="admin-basic-accepted__wrapper">
                    <div class="admin-basic-accepted__right-user">
                        <p v-if="!acceptedUsers">Brak danych...</p>
                        <div
                            v-if="acceptedUsers"
                            class="admin-basic-accepted__right-user-matches"
                        >
                            <div
                                v-for="item in acceptedUsers"
                                class="admin-basic-accepted__right-user-matches-item"
                            >
                                <SingleMatchView
                                    :userId="item._id"
                                    :toBackId="$route.params.id"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import SingleMatchView from '@/components/atoms/SingleMatchView.vue'
    import PersonalBasicsInfoCleaner from '@/components/atoms/PersonalBasicsInfoCleaner.vue'
    import Loader from '@/components/atoms/Loader.vue'
    export default {
        name: 'single user',
        data() {
            return {
                linkPrev: '',
                user: [],
                acceptedUsers: [],
                loading: true
            }
        },
        components: {
            SingleMatchView,
            PersonalBasicsInfoCleaner,
            Loader
        },
        mounted() {
            this.linkPrev = localStorage.getItem('adminPlusPrev')
            this.getUser(this.$route.params.id)
        },

        methods: {
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getUser(id) {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
                axios
                    .get(`${this.url}api/verify/${id}`, config)
                    .then(resp => {
                        this.user = resp.data.user
                        this.loading = false
                    })
                    .then(() => {
                        this.getAcceptedListedUsers()
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },

            getAcceptedListedUsers() {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
                axios
                    .get(
                        `${this.url}api/users/acceptedList/${this.$route.params.id}`,
                        config
                    )
                    .then(response => {
                        this.acceptedUsers = response?.data?.acceptedList || []
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.loading = false
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .seventh {
        .admin-basic-accepted__user-parts-second-text {
            padding-top: 21px;
            margin-top: 3px;
        }
        .admin-basic-accepted__user-parts-line {
            margin-bottom: 0;
        }
        .admin-basic-accepted__user-parts-second-item-line {
            display: block !important;
        }
    }
    .send-questionnaire-button {
        padding-bottom: 184px;
    }
    .send-questionnaire {
        display: block;
        outline: none;
        border: 1px solid $black;
        margin: 0 auto;
        width: 200px;
        text-align: center;
        padding: 15px 0;
        background-color: #e1dbd5;
        font-size: 1.6rem;
        line-height: 3rem;
        color: $black;
        font-weight: bold;
        cursor: pointer;
    }
    .admin-basic-accepted {
        &__wrapper {
            display: flex;
            flex-direction: column;
            column-gap: 20px;
            @include breakpoint-max('m-large') {
                flex-direction: column;
                row-gap: 50px;
            }
        }
        &__message {
            list-style-type: none;
            background-color: $gray;
            padding: 10px;
            border-radius: 8px;
        }
        &__overlay {
            width: 100%;
            height: 100vh;
            background-color: #111111;
            opacity: 70%;
            position: fixed;
            top: 0;
            left: 0;
        }
        &__popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 500px;
            width: 100%;
            padding: 20px;
            background-color: $gray;
            border-radius: 15px;
            &-wrapper {
                display: flex;
                flex-direction: column;
                textarea {
                    resize: none;
                    padding: 20px;
                    border: 1px solid darken($gray, 10%);
                    border-radius: 10px;
                    font-size: 1.6rem;
                }
                .admin-basic-accepted__btn {
                    margin-top: 8px;
                }
            }
        }
        &__check {
            border: 3px dashed #f2545b;
            border-radius: 15px;
            margin: 20px 0;
            margin-top: 0;
            padding: 20px;
            h3 {
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 10px;
                font-size: 1.4rem;
                line-height: 1.6rem;
                color: rgba(17, 17, 17, 0.5);
                font-weight: 600;
                padding: 15px 20px 0px 0;
                border-radius: 10px;
            }
        }
        &__verification {
            color: #f2545b;
            background: lighten(#f2545b, 10%);
            padding: 15px 20px !important;
            margin-top: 10px;
        }

        &__columns {
            display: flex;
            margin-left: 90px;
        }
        &__photos {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
        &__left,
        &__right {
            flex: 1;
            figure {
                width: fit-content;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    border-radius: 10px;
                }
            }
            h3 {
                font-size: 1.8rem;
                line-height: 2.5rem;
                color: $black1;
                font-weight: 700;
            }
        }
        &__left {
            @include breakpoint-max('xs-tablet') {
                padding: 0 30px;
            }
        }
        &__left-top {
            display: flex;
            margin-bottom: 40px;
            a {
                flex: 1;
            }
            @include breakpoint-max('s-tablet') {
                flex-direction: column;
                row-gap: 20px;
            }
        }
        &__left-top-l {
            flex: 1;
        }
        &__left-prev {
            display: block;
            width: fit-content;
            padding: 17px 25px;
            padding-top: 20px;
            background-color: $pink;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            font-size: 1.6rem;
            line-height: 3rem;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
        }
        &__gallery-display figure,
        &__gallery-thumbnails figure {
            margin: 10px;
            cursor: pointer;
        }
        &__gallery-thumbnails img {
            height: auto;
        }
        &__user {
            margin-bottom: 50px;
            background-color: $gray;
            border-radius: 15px;
            flex: 1;
        }
        &__right-user {
            flex: 1;
            @include breakpoint-max('v-large') {
                flex: 0.52;
            }
        }
        &__user-top,
        &__users-top {
            display: flex;
            margin-bottom: 35px;
        }
        &__user-top-person {
            margin-top: 30px;
        }
        .no-image {
            margin-top: 30px;
        }
        &__user-top-person-name {
            padding: 8px 36px 8px 59px;
            background-color: $pink;
            color: $white;
            font-size: 3rem;
            line-height: 4.5rem;
            font-weight: 700;
            text-transform: capitalize;
            font-family: 'Open Sans', sans-serif;
            box-shadow: 0px 3px 6px #00000029;
            margin-bottom: 41px;
            @include breakpoint-max('xs-tablet') {
                padding: 6px 20px;
            }
            @include breakpoint-max('mobile') {
                padding: 4px 10px;
                font-size: 2rem;
                margin-bottom: 10px;
            }
        }
        &__user-top-person-data {
            padding-left: 51px;
            div {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 26px;
                    @include breakpoint-max('mobile') {
                        margin-right: 15px;
                        max-width: 15px;
                    }
                }
                p {
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-family: 'Open Sans', sans-serif;
                }
                @include breakpoint-max('mobile') {
                    margin-bottom: 0px;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 20px;
            }
            @include breakpoint-max('mobile') {
                padding-left: 10px;
            }
        }
        &__user-top-person-data-height {
            p {
                text-transform: lowercase !important;
            }
        }
        &__user-parts-first {
            max-width: 1200px;
            margin-bottom: 44px;
            p {
                font-size: 1.6rem;
                line-height: 2rem;
                color: $black;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                margin-bottom: 17px;
                span {
                    font-weight: 400;
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }
        &__user-parts-first-social {
            display: block;
            margin-bottom: 7px;
            color: $black;
            text-decoration: none;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.6rem;
            line-height: 3rem;
            font-weight: 400;
            transition: 0.3s;
            width: fit-content;
            &:hover {
                color: $pink;
            }
        }
        &__user-parts-second {
            max-width: 1200px;
            margin-bottom: 43px;
        }
        &__user-parts-first-title,
        &__user-parts-second-title {
            font-size: 3.5rem;
            line-height: 5rem;
            color: $black;
            padding-bottom: 17px;
            width: 100%;
            max-width: 506px;
            font-family: 'Promenade';
            font-weight: 400;
        }
        &__user-parts-line {
            margin-bottom: 17px;
            height: 3px;
            background-color: $black;
            width: 100%;
            @include breakpoint-max('xl-tablet') {
                width: 65%;
            }
        }
        &__user-parts {
            width: 100%;
            max-width: 505px;
            padding-right: 30px;
            @include breakpoint-max('xs-tablet') {
                padding-right: 0px;
            }
        }
        &__user-parts-second-title {
            margin-bottom: 3px;
        }
        &__user-parts-second-item {
            max-width: 1200px;
            width: 100%;
            &:last-child {
                .admin-basic-accepted__user-parts-second-item-line {
                    display: none;
                }
            }
        }
        &__user-parts-second-item-box {
            padding-top: 3px;
            display: flex;
            align-items: flex-start;
            column-gap: 41px;
            @include breakpoint-max('m-large') {
                column-gap: 15px;
            }
            @include breakpoint-max('xl-tablet') {
                flex-direction: column;
            }
        }
        &__user-parts-second-item-box-left {
            flex: 68;
            display: flex;
            column-gap: 41px;
            @include breakpoint-max('m-large') {
                column-gap: 15px;
            }
            @include breakpoint-max('xl-tablet') {
                width: 100%;
            }
        }
        &__user-parts-second-item-line {
            margin-bottom: 17px;
            width: 100%;
            height: 1px;
            background-color: $black;
            @include breakpoint-max('xl-tablet') {
                margin-top: 20px;
                width: 65%;
            }
        }
        &__user-parts-second-text {
            flex: 50;
            padding-bottom: 21px;
            margin-bottom: 3px;
            h3 {
                margin-bottom: 3px;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                color: $black;
            }
            p {
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                color: $black;
            }
            em {
                font-style: normal;
                color: $pink;
                font-weight: 700;
            }
            @include breakpoint-max('large') {
                flex: 35;
            }
            @include breakpoint-max('xl-tablet') {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
        &__user-parts-second-fix {
            flex: 13;
            display: flex;
            justify-content: center;
            column-gap: 22px;
            label {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                align-items: center;
            }
            span {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $black;
                font-weight: 400;
            }
            // Input type radio
            .content {
                position: relative;
                display: grid;
                place-items: center;
                width: 27px;
                height: 27px;
            }
            .checkBox {
                display: block;
                cursor: pointer;
                width: 27px;
                height: 27px;
                border: 4px solid transparent;
                border-radius: 50%;
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 0px 1.5px $black;
            }

            .checkBox div {
                width: 60px;
                height: 60px;
                background-color: $pink;
                top: -52px;
                left: -52px;
                position: absolute;
                transform: rotateZ(45deg);
                z-index: 100;
            }
            .correct {
                .checkBox div {
                    background-color: $black;
                }
            }

            .checkBox input[type='radio']:checked + div {
                left: -10px;
                top: -10px;
            }

            .checkBox input[type='radio'] {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                visibility: hidden;
            }

            .transition {
                transition: 300ms ease;
            }
            //
            @include breakpoint-max('m-large') {
                flex: 15;
            }
            @include breakpoint-max('mobile') {
                column-gap: 10px;
            }
        }
        &__user-parts-second-fix-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 17px;
        }
        .to-fix {
            min-width: 42px;
        }
        &__user-parts-second-input {
            flex: 47;
            div {
                display: flex;
                flex-direction: column;
                row-gap: 17px;
                @include breakpoint-max('xl-tablet') {
                    margin-top: 10px;
                }
            }
            span {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $black;
                font-weight: 400;
            }
            input {
                border: 1px solid #2a2a29;
                background: transparent;
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                color: $black;
                outline: none;
                padding: 4px 13px;
            }
            @include breakpoint-max('m-large') {
                flex: 35;
            }
            @include breakpoint-max('xl-tablet') {
                width: 100%;
            }
        }
        &__users {
            margin-bottom: 50px;
            background-color: $gray;
            padding: 31px 35px;
            border-radius: 15px;
            max-width: 650px;
        }
        &__user-info,
        &__users-info {
            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $black1;
                font-weight: 600;
                margin-bottom: 10px;
            }
            span {
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: rgba(17, 17, 17, 0.5);
                font-weight: 600;
                text-align: right;
                margin-left: 10px;
            }
        }
        &__user-mail,
        &__user-phone {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            p {
                margin-bottom: 0;
                margin-left: 13px;
            }
        }
        &__btn {
            padding: 14px 21px;
            cursor: pointer;
            border-radius: 10px;
            color: $white;
            background-color: #0fb70d;
            &--margin {
                margin-left: 8px;
            }
            &--red {
                background-color: #f2545b;
            }
        }

        .admin-users-view {
            &__header {
                max-width: 1597px;
                height: 104px;
                background-color: $white;
                padding-left: 65px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &__header-logo {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 14px;
                }
                p {
                    font-family: 'NunitoExtraBold';
                    font-weight: 800;
                    font-size: 2rem;
                    line-height: 3.7rem;
                    color: $black;
                }
            }
            &__header-notification {
                cursor: pointer;
                width: 53px;
                height: 53px;
                background: $gray;
                border-radius: 15px;
                display: grid;
                place-items: center;
                position: relative;
                transition: 0.3s;
                &:hover {
                    svg {
                        animation: myAnim 2s ease 0s 1 normal forwards;
                    }
                }
            }
            &__header-notification-circle {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 12px;
                height: 12px;
                background-color: #f2545b;
                border-radius: 50%;
            }
            &__wrapper {
                display: flex;
                padding-bottom: 86px;
                padding-right: 30px;
            }
            &__sidebar {
                max-width: 303px;
                width: 100%;
                height: calc(100vh - 104px);
                background: $gray;
                border-radius: 0 15px 15px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding-top: 54px;
                padding-bottom: 150px;
            }
            &__sidebar-pages {
                position: relative;
                display: flex;
                flex-direction: column;
                list-style: none;
                li {
                    margin-bottom: 13px;
                    a {
                        text-decoration: none;
                        font-family: 'NunitoMedium';
                        font-weight: 500;
                        font-size: 1.8rem;
                        line-height: 2.5rem;
                        color: $black1;
                        height: 38px;
                        display: flex;
                        align-items: center;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    svg {
                        margin-right: 13px;
                    }
                }
            }
            &__sidebar-nav {
                position: absolute;
                left: -19px;
                top: 0px;
                width: 4px;
                height: 38px;
                background-color: #f2545b;
                box-shadow: -3px 3px 6px rgba(242, 84, 91, 0.35);
                border-radius: 500px;
                transition: 0.3s;
            }
            &__sidebar-logout {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &__sidebar-line {
                margin-bottom: 34px !important;
                max-width: 239px;
                margin: 0 auto;
                width: 100%;
                height: 1px;
                background-color: $bege;
            }
            &__sidebar-logout-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;
                p {
                    font-family: 'NunitoMedium';
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2.5rem;
                    color: $black1;
                }
                &:hover {
                    .arrow {
                        left: 12px;
                    }
                }
            }
            &__sidebar-logout-svg {
                position: relative;
                width: 17px;
                height: 19px;
                margin-right: 15px;
                .left {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
                .arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 6px;
                    transition: 0.3s;
                }
            }
            &__content {
                width: 100%;
                height: calc(100vh - 104px);
                // background-image: url(http://localhost:8080/img/light-bcg-big.93d5f768.png);
                input {
                    padding-left: 93px;
                    width: 270px;
                    padding: 15px;
                }
            }
            &__content-users {
                padding-top: 49px;
                padding-left: 93px;
                padding-top: 13px;
                max-width: 1294px;
                width: 100%;
            }
            &__content-input {
                padding-left: 93px;
                input {
                    background: $gray;
                    border-radius: 10px;
                    outline: none;
                    border: none;
                    font-family: 'NunitoBold';
                    font-weight: 700;
                    font-size: 1.6rem;
                    color: #111111;
                }
            }
            &__content-input-wrapper {
                position: relative;
                width: fit-content;
                svg {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 24px;
                }
            }

            @keyframes myAnim {
                0%,
                100% {
                    transform: translateX(0%);
                    transform-origin: 50% 50%;
                }

                15% {
                    transform: translateX(-5px) rotate(12deg);
                }

                30% {
                    transform: translateX(5px) rotate(-12deg);
                }

                45% {
                    transform: translateX(0px) rotate(3.6deg);
                }

                60% {
                    transform: translateX(0px) rotate(-2.4deg);
                }

                75% {
                    transform: translateX(0px) rotate(1.2deg);
                }
            }
        }
        &__right-user {
        }
        &__right-user-heading {
            flex: 1;
            display: flex;
            align-items: center;
            column-gap: 63px;
            h2 {
                font-size: 3.5rem;
                line-height: 5rem;
                color: $black;
                font-weight: 400;
                font-family: 'Promenade';
            }
            @include breakpoint-max('mobile') {
                flex-direction: column;
                row-gap: 20px;
                align-items: flex-start;
            }
        }
        &__right-user-heading-boxes {
            display: flex;
            column-gap: 13px;
            a {
                text-decoration: none;
                figure {
                    display: block;
                    margin-bottom: 5px;
                    img {
                        display: block;
                        width: 59px;
                        height: 59px;
                        object-fit: cover;
                    }
                }
                p {
                    font-size: 0.8rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                    width: 100%;
                    text-align: center;
                    font-family: 'Open Sans', sans-serif;
                }
            }
        }
        &__right-user-heading-boxes-second {
            width: 59px;
            height: 59px;
            border-radius: 10px;
            background-color: $pink;
        }
    }
    .gallery {
        &__thumbnails {
            figure {
                display: block;
                img {
                    cursor: pointer;
                    display: block;
                    height: auto;
                    width: 280px;
                    height: 280px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    object-position: center;
                    border-radius: 0 !important;
                    border: 1px solid $black;
                    @include breakpoint-max('xs-tablet') {
                        width: 240px;
                        height: 240px;
                    }
                    @include breakpoint-max('mobile') {
                        width: 160px;
                        height: 160px;
                    }
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                }
            }
        }
    }
    .lightbox {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            max-width: 80vw; /* 80% szerokości widoku */
            max-height: 80vh; /* 80% wysokości widoku */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            max-width: 95vw;
            max-height: 95vh;
            object-fit: contain !important;
        }
    }
    .lightbox__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .lightbox__nav {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
    .lightbox__nav--left {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }
    .lightbox__nav--right {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }
    .lightbox__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
</style>
