<template>
    <section class="faq">
        <div class="scroll-div" id="faq"></div>
        <div class="container-landing">
            <h2 class="faq__title">FAQ</h2>
            <div class="faq__columns">
                <div class="faq__columns-left">
                    <h3 class="faq__columns-left-title">BIURO I REJESTRACJA</h3>
                    <ul class="faq__columns-left-list">
                        <SingleFaq
                            v-for="(question, index) in firstColumnQuestions"
                            :key="index"
                            :question="question"
                            :inIndex="index"
                            :showMore="showMore"
                        />
                    </ul>
                </div>
                <div class="faq__columns-right">
                    <h3 class="faq__columns-right-title">FORMULARZ</h3>
                    <ul class="faq__columns-right-list">
                        <SingleFaq
                            v-for="(question, index) in secondColumnQuestions"
                            :key="'second_' + index"
                            :question="question"
                            :inIndex="index"
                            :showMore="showMore"
                        />
                    </ul>
                </div>
            </div>
            <div class="faq__more">
                <router-link @click="opened = false" to="/pytania"><p>więcej</p></router-link>
            </div>
        </div>
    </section>
</template>
<script>
import SingleFaq from './atoms/SingleFaq.vue';
import SimpleButton from '@/components/landing-03/atoms/SimpleButton.vue';

    export default {
        data() {
            return {
                firstColumnQuestions: [
                    {title: 'W jakich miastach będzie działało Biuro Randkowe?', text: 'Biuro Randkowe  będzie się sukcesywnie otwierać na największe polskie miasta, takie jak Warszawa, Poznań, Wrocław, Kraków, Łódź, Rzeszów, Gdańsk/Gdynia/Sopot, Katowice, Szczecin, Bydgoszcz, Lublin. O kolejności, w której będziemy rozpoczynać działalność, zadecyduje liczba osób zapisanych w danych miastach oraz dostępność naszych konsultantów. Chcemy zapewnić naszym klientom najwyższą jakość usług, dlatego planowanie otwarcia nowych lokalizacji jest ściśle związane z liczbą zgłoszeń w poszczególnych miastach. Dzięki temu możemy zagwarantować, że w każdym z tych miejsc nasi klienci otrzymają profesjonalną obsługę i indywidualne podejście. Zachęcamy do zapisywania się na listę oczekujących, aby przyspieszyć otwarcie Biura Randkowego w Twoim mieście.'},
                    {title: 'Jak mogę się zarejestrować w Biurze Randkowym?', text: 'Aby zarejestrować się w  Biurze Randkowym, wystarczy wypełnić formularz rejestracyjny dostępny na naszej stronie internetowej. Podczas rejestracji podajesz swoje dane kontaktowe, dodajesz zdjęcie oraz wybierasz miasta, w których jesteś gotowy/a umówić się na randkę. Po zakończeniu rejestracji zostaniesz dodany/a do listy oczekujących na kwestionariusz. W momencie, gdy kwestionariusz będzie gotowy do wypełnienia, poinformujemy Cię o tym mailowo i/lub SMS-em.'},
                ],
                secondColumnQuestions: [
                    {title: 'Czy rejestracja w biurze jest płatna?', text: 'Nie, rejestracja i zapis na listę oczekujących są całkowicie bezpłatne. Wszyscy zainteresowani mogą bez żadnych opłat zarejestrować się w naszym systemie i dołączyć do listy oczekujących na kwestionariusz lub rozpoczęcie działalności w ich mieście. Opłaty wnosi się dopiero po otrzymaniu i wypełnieniu kwestionariusza, który jest kolejnym krokiem w procesie rejestracji do Biura Randkowego. Wypełnienie kwestionariusza jest niezbędne, abyśmy mogli lepiej poznać preferencje i oczekiwania naszych klientów oraz dopasować odpowiednich partnerów.'},
                    {title: 'Jak mogę śledzić status swojego zgłoszenia? ', text: 'Status swojego zgłoszenia możesz śledzić, logując się na swoje konto na naszej stronie internetowej. Po zalogowaniu się, w panelu użytkownika znajdziesz aktualne informacje dotyczące statusu Twojego zgłoszenia oraz ewentualnych dalszych kroków w procesie rejestracji i dopasowywania partnerów. Regularne sprawdzanie swojego statusu pozwoli Ci być na bieżąco z postępami w procesie.'},
                ]
            }
        },
        components: {
            SingleFaq,
            SimpleButton
        },
    }
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";


    .faq {
        background-color: $bege3;
        padding-bottom: 84px;
        border-bottom: 1px solid $black;
        &__title {
            font-family: 'Oswald', sans-serif;
            font-size: 30rem;
            line-height: 45.5rem;
            color: $begeLight3;
            text-transform: uppercase;
            position: relative;
            margin-bottom: 53px;
            &::after {
                content: '';
                position: absolute;
                bottom: 25px;
                left: 0px;
                width: 100%;
                height: 1px;
                background-color: $black;
                @include breakpoint-max("mobile") {
                    content: none;
                }
            }
            @include breakpoint-max("xs-tablet") {
                margin-bottom: 15px;
            }
            @include breakpoint-max("mobile") {
                font-size: 48px;
                line-height: 50px;
                padding: 60px 0px;
                margin-bottom: 0;
            }
        }
        &__columns {
            display: flex;
            column-gap: 93px;
            @include breakpoint-max('s-tablet') {
                column-gap: 30px;
            }
            @include breakpoint-max("mobile") {
                flex-direction: column;
            }
        }
        &__columns-left,
        &__columns-right {
            flex: 1;
        }
        &__columns-left-title,
        &__columns-right-title {
            font-family: 'Helvetica', sans-serif;
            font-size: 3.2rem;
            line-height: 3.9rem;
            color: $black;
            margin-bottom: 62px;
            @include breakpoint-max("xs-tablet") {
                margin-bottom: 30px;
            }
            @include breakpoint-max("mobile") {
                display: none;
            }
        }
        &__columns-right-title {
            opacity: 0;
        }
        &__columns-left-list,
        &__columns-right-list {
            list-style: none;
            li {
                border-top: 1px solid $black;
                padding: 20px 0 26px;
            }
        }
        &__more {
            padding-top: 9px;
            a {
                text-decoration: none;
                display: block;
                width: fit-content;
            }
            p {
                padding: 11px 31px;
                border: 1px solid $black;
                font-family: 'Open Sans', sans-serif;
                font-size: 2.4rem;
                line-height: 3rem;
                font-weight: 700;
                color: $black;
                text-transform: uppercase;
                width: fit-content;
                cursor: pointer;

            }
        }
        &__columns-content-text {
            p {
                font-size: 14px;
                line-height: 20px;

            }
        }
    }

</style>
