<template>
    <div class="single">
        <div class="single__overlay" v-if="popup"></div>
        <div class="admin-users-view__wrapper">
            <div class="single__left">
                <router-link
                    class="single__left-prev"
                    v-if="linkPrev"
                    :to="linkPrev"
                >
                    Powrót
                </router-link>
                <router-link
                    class="single__left-prev"
                    v-else
                    to="/admin/users/Warszawa/1"
                >
                    Powrót
                </router-link>

                <div v-if="!loading" class="single__user">
                    <PersonalBasicsInfo
                        v-if="photosUpdated"
                        :city="questionnaire.city"
                        :images="userPhotos"
                        :name="questionnaire.name"
                        :age="questionnaire.age"
                        :sex="questionnaire.sex"
                        :growth="questionnaire.growth"
                        :children="questionnaire.childrenHas"
                    />
                    <div
                        v-if="
                            questionnaireDeclined != 'nothing' &&
                            questionnaireDeclined?.questionnaire?.done == false
                        "
                        class="single__waiting_on_user"
                    >
                        <p>Oczekujemy na poprawę kwestionariusza.</p>
                    </div>
                    <div class="single__user-parts">
                        <div class="single__user-parts-second">
                            <h2 class="single__user-parts-second-title">
                                Zdjęcie i opis
                            </h2>
                            <div class="single__user-parts-line"></div>
                            <div class="single__user-parts-second">
                                <div
                                    class="single__user-parts-second-item"
                                    v-for="(el, index) in partEight"
                                >
                                    <div
                                        class="single__user-parts-second-item-box"
                                    >
                                        <div
                                            class="single__user-parts-second-item-box-left"
                                        >
                                            <div
                                                class="single__user-parts-second-text"
                                            >
                                                <h3>{{ el.text }}</h3>
                                                <div v-if="el.value">
                                                    <div
                                                        v-if="
                                                            typeof el.value ==
                                                            'string'
                                                        "
                                                    >
                                                        <p
                                                            v-if="
                                                                el.value ==
                                                                'false'
                                                            "
                                                        >
                                                            <span>Nie</span>
                                                        </p>
                                                        <p v-else>
                                                            <span>
                                                                {{ el.value }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el.value ==
                                                            'number'
                                                        "
                                                    >
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el
                                                                .value[0] ==
                                                            'object'
                                                        "
                                                    >
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item[0] }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div v-else>
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="single__user-parts-second-fix"
                                            >
                                                <div
                                                    class="single__user-parts-second-fix-box correct"
                                                >
                                                    <span>Dobrze</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partEight' +
                                                                    index
                                                                "
                                                                value="0"
                                                                checked
                                                                v-model="
                                                                    partEightChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="single__user-parts-second-fix-box to-fix"
                                                >
                                                    <span>Do poprawy</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partEight' +
                                                                    index
                                                                "
                                                                value="1"
                                                                v-model="
                                                                    partEightChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="single__user-parts-second-input"
                                        >
                                            <div
                                                v-if="
                                                    partEightChecked[index] == 1
                                                "
                                            >
                                                <span>Uzasadnienie:</span>
                                                <input
                                                    type="text"
                                                    v-model="
                                                        partEightFixText[index]
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="single__user-parts-second-item-line"
                                    ></div>
                                </div>
                            </div>
                        </div>

                        <QuestionnaireFirstPart
                            :partOne="partOne"
                            :partOneSocials="partOneSocials"
                        />
                        <!-- #region PartTwo -->
                        <div class="single__user-parts-second">
                            <h2 class="single__user-parts-second-title">
                                Część II
                            </h2>
                            <div class="single__user-parts-line"></div>
                            <div class="single__user-parts-second">
                                <div
                                    class="single__user-parts-second-item"
                                    v-for="(el, index) in partTwo"
                                >
                                    <div
                                        class="single__user-parts-second-item-box"
                                    >
                                        <div
                                            class="single__user-parts-second-item-box-left"
                                        >
                                            <div
                                                class="single__user-parts-second-text"
                                            >
                                                <h3>{{ el.text }}</h3>
                                                <div v-if="el.value">
                                                    <div
                                                        v-if="
                                                            typeof el.value ==
                                                            'string'
                                                        "
                                                    >
                                                        <p
                                                            v-if="
                                                                el.value ==
                                                                'false'
                                                            "
                                                        >
                                                            <span>Nie</span>
                                                        </p>
                                                        <p v-else>
                                                            <span>
                                                                {{ el.value }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el.value ==
                                                            'number'
                                                        "
                                                    >
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el
                                                                .value[0] ==
                                                            'object'
                                                        "
                                                    >
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item[0] }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div v-else>
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="single__user-parts-second-fix"
                                            >
                                                <div
                                                    class="single__user-parts-second-fix-box correct"
                                                >
                                                    <span>Dobrze</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partTwo' +
                                                                    index
                                                                "
                                                                value="0"
                                                                checked
                                                                v-model="
                                                                    partTwoChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="single__user-parts-second-fix-box to-fix"
                                                >
                                                    <span>Do poprawy</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partTwo' +
                                                                    index
                                                                "
                                                                value="1"
                                                                v-model="
                                                                    partTwoChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="single__user-parts-second-input"
                                        >
                                            <div
                                                v-if="
                                                    partTwoChecked[index] == 1
                                                "
                                            >
                                                <span>Uzasadnienie:</span>
                                                <input
                                                    type="text"
                                                    v-model="
                                                        partTwoFixText[index]
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="single__user-parts-second-item-line"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <!-- #endregion -->
                        <!-- #region PartThree -->
                        <div class="single__user-parts-second">
                            <h2 class="single__user-parts-second-title">
                                Część III
                            </h2>
                            <div class="single__user-parts-line"></div>
                            <div class="single__user-parts-second">
                                <div
                                    class="single__user-parts-second-item"
                                    v-for="(el, index) in partThree"
                                >
                                    <div
                                        class="single__user-parts-second-item-box"
                                    >
                                        <div
                                            class="single__user-parts-second-item-box-left"
                                        >
                                            <div
                                                class="single__user-parts-second-text"
                                            >
                                                <h3>{{ el.text }}</h3>
                                                <div v-if="el.value">
                                                    <div
                                                        v-if="
                                                            el.text.includes(
                                                                'Jaka różnica'
                                                            )
                                                        "
                                                    >
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                Druga osoba
                                                                mogłaby być
                                                                młodsza
                                                                maksymalnie:
                                                                <em>
                                                                    {{ item }}
                                                                </em>
                                                            </span>
                                                        </p>
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                Druga osoba
                                                                mogłaby być
                                                                starsza
                                                                maksymalnie:
                                                                <em>
                                                                    {{ item }}
                                                                </em>
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            el.text.includes(
                                                                'Ile masz lat?'
                                                            )
                                                        "
                                                    >
                                                        <p>{{ el.value }}</p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el.value ==
                                                            'number'
                                                        "
                                                    >
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            el.value[0] &&
                                                            typeof el
                                                                .value[0] ==
                                                                'object'
                                                        "
                                                    >
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span
                                                                v-if="
                                                                    item[0] ==
                                                                    'false'
                                                                "
                                                            >
                                                                Nie
                                                            </span>
                                                            <span v-else>
                                                                {{ item[0] }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el.value ==
                                                            'string'
                                                        "
                                                    >
                                                        <p
                                                            v-if="
                                                                el.value ==
                                                                'false'
                                                            "
                                                        >
                                                            <span>Nie</span>
                                                        </p>
                                                        <p v-else>
                                                            <span>
                                                                {{ el.value }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div v-else>
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="single__user-parts-second-fix"
                                            >
                                                <div
                                                    class="single__user-parts-second-fix-box correct"
                                                >
                                                    <span>Dobrze</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partThree' +
                                                                    index
                                                                "
                                                                value="0"
                                                                checked
                                                                v-model="
                                                                    partThreeChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="single__user-parts-second-fix-box to-fix"
                                                >
                                                    <span>Do poprawy</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partThree' +
                                                                    index
                                                                "
                                                                value="1"
                                                                v-model="
                                                                    partThreeChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="single__user-parts-second-input"
                                        >
                                            <div
                                                v-if="
                                                    partThreeChecked[index] == 1
                                                "
                                            >
                                                <span>Uzasadnienie:</span>
                                                <input
                                                    type="text"
                                                    v-model="
                                                        partThreeFixText[index]
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="single__user-parts-second-item-line"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <!-- #endregion -->
                        <!-- #region PartFourth -->
                        <div class="single__user-parts-second">
                            <h2 class="single__user-parts-second-title">
                                Część IV
                            </h2>
                            <div class="single__user-parts-line"></div>
                            <div class="single__user-parts-second">
                                <div
                                    class="single__user-parts-second-item"
                                    v-for="(el, index) in partFourth"
                                >
                                    <div
                                        class="single__user-parts-second-item-box"
                                    >
                                        <div
                                            class="single__user-parts-second-item-box-left"
                                        >
                                            <div
                                                class="single__user-parts-second-text"
                                            >
                                                <h3>{{ el.text }}</h3>
                                                <div v-if="el.value">
                                                    <div
                                                        v-if="
                                                            el.text.includes(
                                                                'Jaka różnica'
                                                            )
                                                        "
                                                    >
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                Druga osoba
                                                                mogłaby być
                                                                młodsza
                                                                maksymalnie:
                                                                <em>
                                                                    {{ item }}
                                                                </em>
                                                            </span>
                                                        </p>
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                Druga osoba
                                                                mogłaby być
                                                                starsza
                                                                maksymalnie:
                                                                <em>
                                                                    {{ item }}
                                                                </em>
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            el.text.includes(
                                                                'Jestem osobą'
                                                            )
                                                        "
                                                    >
                                                        <p>
                                                            <span>
                                                                {{
                                                                    el.value[0]
                                                                }}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span>
                                                                {{
                                                                    el.value[1]
                                                                }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            el.text.includes(
                                                                'Zależy mi, żeby potencjalny partner/partnerka'
                                                            )
                                                        "
                                                    >
                                                        <p>
                                                            <span>
                                                                {{
                                                                    el.value[0]
                                                                }}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span>
                                                                {{
                                                                    el.value[1]
                                                                }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el.value ==
                                                            'number'
                                                        "
                                                    >
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el
                                                                .value[0] ==
                                                            'object'
                                                        "
                                                    >
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item[0] }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el.value ==
                                                            'string'
                                                        "
                                                    >
                                                        <p>
                                                            <span>
                                                                {{ el.value }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div v-else>
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="single__user-parts-second-fix"
                                            >
                                                <div
                                                    class="single__user-parts-second-fix-box correct"
                                                >
                                                    <span>Dobrze</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partFour' +
                                                                    index
                                                                "
                                                                value="0"
                                                                checked
                                                                v-model="
                                                                    partFourthChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="single__user-parts-second-fix-box to-fix"
                                                >
                                                    <span>Do poprawy</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partFour' +
                                                                    index
                                                                "
                                                                value="1"
                                                                v-model="
                                                                    partFourthChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="single__user-parts-second-input"
                                        >
                                            <div
                                                v-if="
                                                    partFourthChecked[index] ==
                                                    1
                                                "
                                            >
                                                <span>Uzasadnienie:</span>
                                                <input
                                                    type="text"
                                                    v-model="
                                                        partFourthFixText[index]
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="single__user-parts-second-item-line"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <!-- #endregion -->
                        <!-- #region PartFifth -->
                        <div class="single__user-parts-second">
                            <h2 class="single__user-parts-second-title">
                                Część V
                            </h2>
                            <div class="single__user-parts-line"></div>
                            <div class="single__user-parts-second">
                                <div
                                    class="single__user-parts-second-item"
                                    v-for="(el, index) in partFifth"
                                >
                                    <div
                                        class="single__user-parts-second-item-box"
                                    >
                                        <div
                                            class="single__user-parts-second-item-box-left"
                                        >
                                            <div
                                                class="single__user-parts-second-text"
                                            >
                                                <h3>{{ el.text }}</h3>
                                                <div v-if="el.value">
                                                    <div
                                                        v-if="
                                                            el.text.includes(
                                                                'Wzrost:'
                                                            )
                                                        "
                                                    >
                                                        <p>
                                                            <span>
                                                                {{ el.value }} m
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el.value ==
                                                            'number'
                                                        "
                                                    >
                                                        <p
                                                            v-for="(
                                                                item, indexx
                                                            ) in el.answers"
                                                        >
                                                            <span
                                                                v-if="
                                                                    indexx ==
                                                                    el.value
                                                                "
                                                            >
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el
                                                                .value[0] ==
                                                            'object'
                                                        "
                                                    >
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item[0] }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            typeof el.value ==
                                                            'string'
                                                        "
                                                    >
                                                        <p>
                                                            <span>
                                                                {{ el.value }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div v-else>
                                                        <p
                                                            v-for="item in el.value"
                                                        >
                                                            <span>
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="single__user-parts-second-fix"
                                            >
                                                <div
                                                    class="single__user-parts-second-fix-box correct"
                                                >
                                                    <span>Dobrze</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partFive' +
                                                                    index
                                                                "
                                                                value="0"
                                                                checked
                                                                v-model="
                                                                    partFifthChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="single__user-parts-second-fix-box to-fix"
                                                >
                                                    <span>Do poprawy</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partFive' +
                                                                    index
                                                                "
                                                                value="1"
                                                                v-model="
                                                                    partFifthChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="single__user-parts-second-input"
                                        >
                                            <div
                                                v-if="
                                                    partFifthChecked[index] == 1
                                                "
                                            >
                                                <span>Uzasadnienie:</span>
                                                <input
                                                    type="text"
                                                    v-model="
                                                        partFifthFixText[index]
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="single__user-parts-second-item-line"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <!-- #endregion -->
                        <!-- #region PartSixth -->
                        <div class="single__user-parts-second">
                            <h2 class="single__user-parts-second-title">
                                Część VI
                            </h2>
                            <div class="single__user-parts-line"></div>
                            <div class="single__user-parts-second">
                                <div
                                    class="single__user-parts-second-item"
                                    v-for="(el, index) in partSixth"
                                >
                                    <div
                                        class="single__user-parts-second-item-box"
                                    >
                                        <div
                                            class="single__user-parts-second-item-box-left"
                                        >
                                            <div
                                                class="single__user-parts-second-text"
                                            >
                                                <h3>{{ el.text }}</h3>
                                                <div v-if="el.value">
                                                    <div>
                                                        <p
                                                            v-for="item in el.value"
                                                            class="sixth-list"
                                                        >
                                                            <span>
                                                                {{ item }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="single__user-parts-second-fix"
                                            >
                                                <div
                                                    class="single__user-parts-second-fix-box correct"
                                                >
                                                    <span>Dobrze</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partSix' +
                                                                    index
                                                                "
                                                                value="0"
                                                                checked
                                                                v-model="
                                                                    partSixthChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="single__user-parts-second-fix-box to-fix"
                                                >
                                                    <span>Do poprawy</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partSix' +
                                                                    index
                                                                "
                                                                value="1"
                                                                v-model="
                                                                    partSixthChecked[
                                                                        index
                                                                    ]
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="single__user-parts-second-input"
                                        >
                                            <div
                                                v-if="
                                                    partSixthChecked[index] == 1
                                                "
                                            >
                                                <span>Uzasadnienie:</span>
                                                <input
                                                    type="text"
                                                    v-model="
                                                        partSixthFixText[index]
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="single__user-parts-second-item-line"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <!-- #endregion -->
                        <!-- #region PartSeventh -->

                        <div class="single__user-parts-second seventh">
                            <h2 class="single__user-parts-second-title">
                                Część VII
                            </h2>
                            <div class="single__user-parts-line"></div>
                            <div class="single__user-parts-second">
                                <div
                                    class="single__user-parts-second-item"
                                    v-for="(el, index) in partSeventh"
                                >
                                    <div
                                        class="single__user-parts-second-item-box"
                                    >
                                        <div
                                            class="single__user-parts-second-item-box-left"
                                        >
                                            <div
                                                class="single__user-parts-second-text seventh-list"
                                            >
                                                <div>
                                                    <p>
                                                        <strong>
                                                            {{ el?.text }}
                                                        </strong>
                                                        <span>
                                                            {{
                                                                el?.value[index]
                                                            }}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="single__user-parts-second-fix"
                                            >
                                                <div
                                                    class="single__user-parts-second-fix-box correct"
                                                >
                                                    <span>Dobrze</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partSeven' +
                                                                    index
                                                                "
                                                                value="0"
                                                                checked
                                                                v-model="
                                                                    partSeventhChecked
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="single__user-parts-second-fix-box to-fix"
                                                >
                                                    <span>Do poprawy</span>
                                                    <div class="content">
                                                        <label class="checkBox">
                                                            <input
                                                                type="radio"
                                                                :name="
                                                                    'partSeven' +
                                                                    index
                                                                "
                                                                value="1"
                                                                v-model="
                                                                    partSeventhChecked
                                                                "
                                                            />
                                                            <div
                                                                class="transition"
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="single__user-parts-second-input"
                                        >
                                            <div v-if="partSeventhChecked == 1">
                                                <span>Uzasadnienie:</span>
                                                <textarea
                                                    v-model="partSeventhFixText"
                                                ></textarea>
                                                <!-- <input type="text" > -->
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="single__user-parts-second-item-line"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <!-- #endregion -->
                    </div>
                </div>
                <div v-else>
                    <Loader />
                </div>
            </div>
            <!-- <div class="single__right"> -->
            <!-- #region main -->
            <!-- <div
                            class="single__users"
                            v-for="(item, index) in matches"
                            :key="index"
                        >
                            <p>{{ user.date }}</p>
                            <p>{{ user.email }}</p>
                            <div class="single__users-top">
                                <figure>
                                    <img :src="item.photo1">
                                </figure>
                                <h3>{{ item.name }}</h3>
                            </div>
                            <div class="single__users-info">
                                <p>Wiek: <span>{{ item.age }}</span></p>
                                <p>Płeć: <span>{{ item.sex }}</span></p>
                                <p>Wzrost: <span>{{ item.growth }}</span></p>
                                <p>Miasto: <span>{{ item.city }}</span></p>
                                <p>Stan cywilny: <span>{{ item.materialStatus }}</span></p>

                                <p>Wiara: <span>{{ item.religion }}</span></p>
                                <p>Czy praktykuje: <span>{{ item.religionMatter }}</span></p>
                                <p>Czy chce dzieci: <span>{{ item.childrenWanted }}</span></p>
                                <p>Poglądy polityczne: <span>{{ item.politicalViews }}</span></p>
                                <p>Możliwość przeprowadzki: <span>{{ item.movePossibility }}</span></p>
                                <p>Czy bierze pod uwagę związek z osobą po rozwodzie: <span>{{ item.divorcedMatter }}</span></p>



                                <div class="single__user-phone">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Call">
                                    <path id="Vector" d="M2.9399 1.71604C3.01856 1.63737 3.0579 1.59804 3.09255 1.5662C3.94461 0.783272 5.25429 0.783272 6.10634 1.5662C6.14099 1.59804 6.18033 1.63737 6.25899 1.71604L7.88279 3.33984C8.77594 4.23298 9.03137 5.58178 8.52666 6.73965C8.02195 7.89752 8.27739 9.24632 9.17053 10.1395L11.8395 12.8085C12.7327 13.7016 14.0815 13.957 15.2393 13.4523C16.3972 12.9476 17.746 13.2031 18.6392 14.0962L20.263 15.72C20.3416 15.7987 20.381 15.838 20.4128 15.8727C21.1957 16.7247 21.1957 18.0344 20.4128 18.8864C20.381 18.9211 20.3416 18.9604 20.2629 19.0391L19.2759 20.0261C18.4722 20.8299 17.3135 21.1673 16.2039 20.9207C8.64317 19.2405 2.73845 13.3358 1.0583 5.77514C0.811712 4.66551 1.14913 3.50681 1.95289 2.70305L2.9399 1.71604Z" stroke="#B49660" stroke-width="1.5"/>
                                    </g>
                                    </svg>
                                    <p>{{ item.phone }}</p>
                                    <p>{{ user.date }}</p>
                                </div>
                                <div class="single__user-mail">
                                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Message 36">
                                    <path id="Vector" d="M19.6253 4.42918C20.0425 5.36275 20.2192 6.63533 20.5231 8.82309C20.9895 12.181 21.2227 13.86 20.7099 15.164C20.2598 16.3088 19.4287 17.2633 18.3567 17.8667C17.1356 18.5539 15.4406 18.5539 12.0504 18.5539H10.3137C6.92351 18.5539 5.22842 18.5539 4.00736 17.8667C2.93539 17.2633 2.10427 16.3088 1.65414 15.164C1.1414 13.86 1.37459 12.181 1.84097 8.82309C2.12547 6.77464 2.29855 5.52853 2.6619 4.61179M19.6253 4.42918C19.5438 4.24688 19.4532 4.07751 19.3507 3.91677C18.7976 3.04977 18.0062 2.36069 17.0713 1.93213C16.0105 1.4458 14.6904 1.4458 12.0504 1.4458H10.3137C7.67364 1.4458 6.35361 1.4458 5.29274 1.93213C4.35789 2.36069 3.56649 3.04977 3.0134 3.91677C2.87743 4.12992 2.76239 4.35826 2.6619 4.61179M19.6253 4.42918L19.5306 4.52385L18.6131 5.4414C15.0489 9.00559 13.2668 10.7877 11.0523 10.7877C8.83779 10.7877 7.05569 9.00559 3.4915 5.4414L2.6619 4.61179" stroke="#B49660" stroke-width="1.5"/>
                                    </g>
                                    </svg>
                                    <p>{{ item }}</p>
                                </div>
                                <p>Imię: <span>{{ item.name }}</span></p>
                                <p>Nazwisko: <span>{{ item.surname }}</span></p>
                                <p>Wiek: <span>{{ item.age }}</span></p>
                                <p>Płeć: <span>{{ item.sex === 0 ? 'Kobieta' : (item.sex === 0 ? 'Kobieta' : 'Osoba niebinarna') }}</span></p>
                                <p>Poszukiwana płeć: <span>{{ item.genderWanted === 0 ? 'Mężczyzna' : (item.sex === 0 ? 'Kobieta' : 'Osoba niebinarna') }}</span></p>
                                <p>Wzrost: <span>{{ item.growth }}</span></p>
                                <p>Miasto: <span>{{ item.city === 0 ? 'Poznań' : 'Warszawa' }}</span></p>
                                <p>Stan cywilny: <span>{{ item.materialStatus === 0 ? 'panna/kawaler' : (item.materialStatus === 1 ? 'po rozwodzie' : 'wdowiec/wdowa') }}</span></p>

                                <p>Wiara: <span>{{ item.religion === 0 ? 'Wierzącą' : 'Niewierzącą' }}</span></p>
                                <p>Czy praktykuje: <span>{{ item.religionPractice === 0 ? 'Praktykującą' : 'Niepraktykującą' }}</span></p>
                                <p>Czy ma dzieci: <span>{{ item.childrenHas === 0 ? 'Tak' : 'Nie' }}</span></p>
                                <p>Czy chce dzieci: <span>{{ item.childrenWanted === 0 ? 'Chce' : 'Nie chce' }}</span></p>
                                <p>Poglądy polityczne: <span>{{ politicalViewsFormattedMatch(item) }}</span></p>
                                <p>Możliwość przeprowadzki: <span>{{ item.movePossibility === 0 ? 'Tak' : (item.movePossibility === 1 ? 'Nie' : 'Rozważam taką możliwość')  }}</span></p>
                                <p>Czy bierze pod uwagę związek z osobą po rozwodzie: <span>{{ item.divorcedMatter === 0 ? 'Tak' : 'Absolutnie nie' }}</span></p>
                                <p>
                                    Flaga:
                                    <span :style="{ color: flagMap[flagTrimmed].color }">
                                        {{ flagMap[flagTrimmed].text }}
                                    </span>
                                </p>


                            <button class="single__btn single__btn--connected" :class="getClass(item.userId)" @click="addTemporaryMatch(item.userId)">Połącz</button>
                        </div> -->
            <!-- #endregion -->
            <!-- </div> -->
        </div>
        <div
            v-if="
                questionnaireDeclined != 'nothing' &&
                questionnaireDeclined?.questionnaire?.done == false &&
                !loading
            "
            class="single__waiting_on_user bottom-text"
        >
            <p>Oczekujemy na poprawę kwestionariusza.</p>
        </div>

        <div
            class="send-questionnaire-button"
            v-if="
                questionnaireDeclined != 'nothing' &&
                questionnaireDeclined?.questionnaire?.done != false
            "
        >
            <div v-if="sending">
                <Loader />
            </div>
            <button
                v-if="!loading"
                :disabled="sending"
                class="send-questionnaire"
                @click="sendQuestionnaire"
            >
                {{ sending ? 'WYSYŁANIE...' : 'WYŚLIJ' }}
            </button>
            <div class="send-questionnaire__response">{{ response }}</div>
        </div>
    </div>
</template>

<script>
    import PersonalBasicsInfo from '../components/atoms/PersonalBasicsInfo.vue'
    import QuestionnaireFirstPart from '../components/atoms/QuestionnaireFirstPart.vue'
    import Loader from '@/components/atoms/Loader.vue'
    import { useWindowScroll } from '@vueuse/core'
    import axios from 'axios'

    import { loadRouteLocation } from 'vue-router'

    export default {
        name: 'single user',
        data() {
            return {
                linkPrev: '',
                user: [],
                matches: [],
                questionnaire: [],
                temporarymatch: null,
                match: null,
                flagTrimmed: 0,
                verifications: [],
                popup: false,
                //
                partOne: [],
                partOneSocials: [],
                partTwo: [],
                partTwoChecked: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                partTwoFixText: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                partThree: [],
                partThreeChecked: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                partThreeFixText: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                partFourth: [],
                partFourthChecked: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                partFourthFixText: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                partFifth: [],
                partFifthChecked: [null, null, null, null, null],
                partFifthFixText: [null, null, null, null, null],
                partSixth: [],
                partSixthChecked: [null, null],
                partSixthFixText: [null, null],
                partSeventh: [],
                partSeventhChecked: [null],
                partSeventhFixText: [null],
                partEight: [],
                partEightChecked: [null, null],
                partEightFixText: [null, null],
                questionnaireDeclined: null,
                photosUpdated: false,
                //
                flagMap: {
                    0: { color: 'red', text: 'Czerwona' },
                    1: { color: 'yellow', text: 'Żółta' },
                    2: { color: 'blue', text: 'Niebieska' },
                    3: { color: 'green', text: 'Zielona' },
                    default: { color: 'red', text: 'Czerwona' }
                },
                data: {
                    image: require('@/assets/images/hero-girl.png')
                },
                currentPhotoIndex: 0,
                lightboxVisible: false,
                userPhotos: [],
                sending: false,
                response: null,
                loading: true
            }
        },
        components: {
            PersonalBasicsInfo,
            QuestionnaireFirstPart,
            Loader
        },
        mounted() {
            document.addEventListener('keydown', this.handleKeydown)
            document.addEventListener('keydown', this.handleKeydownExit)
            this.linkPrev = localStorage.getItem('adminUrlPrev')
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeydown)
            document.removeEventListener('keydown', this.handleKeydownExit)
        },
        methods: {
            checkAllValuesAreNullExceptUserId(data) {
                for (let key in data) {
                    if (key === 'userId') continue

                    if (data[key] !== null) return false
                }

                return true
            },
            sendQuestionnaire() {
                const keys = [
                    'openToNewPeople',
                    'openToNewThings',
                    'lotOfImpressions',
                    'characterTraits',
                    'feelingsTalking',
                    'freeTime',
                    'petsHas',
                    'petsAttitude',
                    'hobby',
                    'sex',
                    'wantedGender',
                    'age',
                    'ageDifference',
                    'ageAdditionalComment',
                    'job',
                    'demandingJob',
                    'jobPros',
                    'jobImportance',
                    'income',
                    'incomeImportance',
                    'education',
                    'educationImportance',
                    'educationAdditionalComment',
                    'materialStatus',
                    'weddingWanted',
                    'religion',
                    'religionPractice',
                    'religionMatter',
                    'religionPracticeMatter',
                    'divorcedMatter',
                    'childrenHas',
                    'childrenPartnerHas',
                    'childrenWanted',
                    'politicalViews',
                    'movePossibility',
                    'growth',
                    'growthMatter',
                    'growthComment',
                    'appearanceNoAcceptation',
                    'specialAppearance',
                    'additionalInfo',
                    'additionalPartnerInfo',
                    'completeSentences',
                    'photos',
                    'profileDescription',
                    'premiumNeeded'
                ]

                let dataToSend = {
                    userId: this.$route.params.id
                }

                this.response = ''

                dataToSend['openToNewPeople'] = this.partTwoChecked[0]
                    ? this.partTwoFixText[0]
                    : null
                dataToSend['openToNewThings'] = this.partTwoChecked[1]
                    ? this.partTwoFixText[1]
                    : null
                dataToSend['lotOfImpressions'] = this.partTwoChecked[2]
                    ? this.partTwoFixText[2]
                    : null
                dataToSend['characterTraits'] = this.partTwoChecked?.[3]
                    ? this.partTwoFixText?.[3]
                    : null
                dataToSend['feelingsTalking'] = this.partTwoChecked[4]
                    ? this.partTwoFixText[4]
                    : null
                dataToSend['freeTime'] = this.partTwoChecked[5]
                    ? this.partTwoFixText[5]
                    : null
                dataToSend['petsHas'] = this.partTwoChecked[6]
                    ? this.partTwoFixText[6]
                    : null
                dataToSend['petsAttitude'] = this.partTwoChecked[7]
                    ? this.partTwoFixText[7]
                    : null
                dataToSend['hobby'] = this.partTwoChecked[8]
                    ? this.partTwoFixText[8]
                    : null
                //
                dataToSend['age'] = this.partThreeChecked[0]
                    ? this.partThreeFixText[0]
                    : null
                dataToSend['ageDifference'] = this.partThreeChecked[1]
                    ? this.partThreeFixText[1]
                    : null
                dataToSend['ageAdditionalComment'] = this.partThreeChecked[2]
                    ? this.partThreeFixText[2]
                    : null
                dataToSend['job'] = this.partThreeChecked?.[3]
                    ? this.partThreeFixText?.[3]
                    : null
                dataToSend['demandingJob'] = this.partThreeChecked[4]
                    ? this.partThreeFixText[4]
                    : null
                dataToSend['jobPros'] = this.partThreeChecked[5]
                    ? this.partThreeFixText[5]
                    : null
                dataToSend['jobImportance'] = this.partThreeChecked[6]
                    ? this.partThreeFixText[6]
                    : null
                dataToSend['income'] = this.partThreeChecked[7]
                    ? this.partThreeFixText[7]
                    : null
                dataToSend['incomeImportance'] = this.partThreeChecked[8]
                    ? this.partThreeFixText[8]
                    : null
                dataToSend['education'] = this.partThreeChecked[9]
                    ? this.partThreeFixText[9]
                    : null
                dataToSend['educationImportance'] = this.partThreeChecked[10]
                    ? this.partThreeFixText[10]
                    : null
                dataToSend['educationAdditionalComment'] = this
                    .partThreeChecked[11]
                    ? this.partThreeFixText[11]
                    : null
                //
                dataToSend['materialStatus'] = this.partFourthChecked[0]
                    ? this.partFourthFixText[0]
                    : null
                dataToSend['weddingWanted'] = this.partFourthChecked[1]
                    ? this.partFourthFixText[1]
                    : null
                dataToSend['religion'] = this.partFourthChecked[2]
                    ? this.partFourthFixText[2]
                    : null
                dataToSend['religionPractice'] = this.partFourthChecked?.[3]
                    ? this.partFourthFixText?.[3]
                    : null
                dataToSend['religionMatter'] = this.partFourthChecked[4]
                    ? this.partFourthFixText[4]
                    : null
                dataToSend['religionPracticeMatter'] = this.partFourthChecked[5]
                    ? this.partFourthFixText[5]
                    : null
                dataToSend['divorcedMatter'] = this.partFourthChecked[6]
                    ? this.partFourthFixText[6]
                    : null
                dataToSend['childrenHas'] = this.partFourthChecked[7]
                    ? this.partFourthFixText[7]
                    : null
                dataToSend['childrenWanted'] = this.partFourthChecked[8]
                    ? this.partFourthFixText[8]
                    : null
                dataToSend['politicalViews'] = this.partFourthChecked[9]
                    ? this.partFourthFixText[9]
                    : null
                dataToSend['movePossibility'] = this.partFourthChecked[10]
                    ? this.partFourthFixText[10]
                    : null
                //
                dataToSend['growth'] = this.partFifthChecked[0]
                    ? this.partFifthFixText[0]
                    : null
                dataToSend['growthMatter'] = this.partFifthChecked[1]
                    ? this.partFifthFixText[1]
                    : null
                dataToSend['growthComment'] = this.partFifthChecked[2]
                    ? this.partFifthFixText[2]
                    : null
                dataToSend['appearanceNoAcceptation'] = this
                    .partFifthChecked?.[3]
                    ? this.partFifthFixText?.[3]
                    : null
                dataToSend['specialAppearance'] = this.partFifthChecked[4]
                    ? this.partFifthFixText[4]
                    : null
                //
                dataToSend['additionalInfo'] = this.partSixthChecked[0]
                    ? this.partSixthFixText[0]
                    : null
                dataToSend['additionalPartnerInfo'] = this.partSixthChecked[1]
                    ? this.partSixthFixText[1]
                    : null
                //
                dataToSend['completeSentences'] =
                    this.partSeventhChecked === '1'
                        ? this.partSeventhFixText
                        : null

                // console.log(this.partEightChecked)
                // console.log(this.partEightFixText)

                dataToSend['profileDescription'] = this.partEightChecked[0]
                    ? this.partEightFixText[0]
                    : null
                dataToSend['images'] = this.partEightChecked[1]
                    ? this.partEightFixText[1]
                    : null

                // dataToSend['sex'] = this.answers[12][0];
                // dataToSend['wantedGender'] = this.answers[13][0];
                // dataToSend['images'] = this.answers[46];
                // dataToSend['premiumNeeded'] = this.answers[47];

                this.checkAllValuesAreNullExceptUserId(dataToSend)
                this.sending = true

                // WERYFIKACJA
                if (
                    this.checkAllValuesAreNullExceptUserId(dataToSend) == true
                ) {
                    const authToken = this.getCookie('authToken')
                    const config = {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }

                    axios
                        .post(
                            `${this.url}api/verify/singleuser/${this.$route.params.id}`,
                            {},
                            config
                        )
                        .then(resp => {
                            this.sending = false
                            this.response = resp?.data?.message || ''
                            window.setTimeout(() => {
                                this.$router.push('/admin/verify/1')
                            }, 3000)
                        })

                        .catch(err => {
                            console.log(err)
                            this.sending = false
                            this.response = err?.message || ''
                        })
                } else {
                    const authToken = this.getCookie('authToken')
                    const config = {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }

                    setTimeout(() => {
                        // Bearer token included ✅
                        const authToken = this.getCookie('authToken')
                        const config = {
                            headers: {
                                Authorization: `Bearer ${authToken}`
                            }
                        }

                        //ODRZUCENIE, do poprawki
                        const cleanedData = JSON.parse(
                            JSON.stringify(dataToSend)
                        )
                        axios
                            .post(
                                `${this.url}api/questionnaire-declined/declined/${this.$route.params.id}`,
                                cleanedData,
                                config
                            )
                            .then(resp => {
                                this.sending = false
                                this.response = resp?.data?.message || ''
                            })
                            .catch(err => {
                                this.sending = false
                                this.response = err?.message || ''
                                console.log(err)
                            })
                    }, 2000)
                }

                // setTimeout(() => {

                //     const cleanedData = JSON.parse(JSON.stringify(dataToSend));
                //     axios
                //     .post(`${this.url}api/questionnaire-declined/`, cleanedData)
                //     .then((resp) => {

                //         console.log('Zapisano');

                //     })
                //     .catch((err) => {
                //         console.log(err);
                //     });
                // }, 2000)

                // console.log('Checked');
                // this.partTwoChecked.forEach((el, index) => {
                //     console.log(el + ' Index: ' + index + ', text: ' + this.partTwoFixText[index] + ' name:' + this.partTwo[index].name)
                // })
                // const authToken = this.getCookie('authToken');

                // const config = {
                //     headers: {
                //         'Authorization': `Bearer ${authToken}`
                //     }
                // };

                // axios
                //     .post(`${this.url}api/verify/singleuser/${this.$route.params.id}`, config)
                //     .then(() => {
                //         this.$router.push('/admin/basic/1');
                //     })
                //     .catch((err) => {
                //         console.log(err);
                //     });
            },
            setGrowth(el) {
                let metric
                if (el) {
                    el.length >= 3 && !el.includes('.')
                        ? (metric = 'cm')
                        : (metric = 'm')
                    return `${el} ${metric}`
                }
            },
            setSex(el) {
                switch (el) {
                    case '0':
                        return 'Kobieta'
                    case '1':
                        return 'Mężczyzna'
                    case '2':
                        return 'Niebinarna'
                    default:
                }
            },
            setAge(el) {
                return new Date().getFullYear() - new Date(el).getFullYear()
            },
            checkVerifications() {
                if (this.verifications && this.verifications.length > 0) {
                    const lastVerification =
                        this.verifications[this.verifications.length - 1]
                    return (
                        lastVerification && lastVerification.resolved === true
                    )
                }
                return true
            },
            addVerify() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .post(`${this.url}api/verify/`, {
                        userid: this.$route.params.id
                    }, config)
                    .then(() => {
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            decline() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .post(`${this.url}api/verify/decline`, {
                        userid: this.$route.params.id,
                        reason: this.reason
                    }, config)
                    .then(() => {
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            formatDateWithAge(isoDate) {
                try {
                    let birthDate = new Date(isoDate)
                    if (isNaN(birthDate.getTime())) {
                        // Data nie jest prawidłową datą
                        return 'Nieprawidłowa data'
                    }

                    let currentDate = new Date()
                    let currentYear = currentDate.getFullYear()
                    let age = currentYear - birthDate.getFullYear()

                    if (
                        currentDate.getMonth() < birthDate.getMonth() ||
                        (currentDate.getMonth() === birthDate.getMonth() &&
                            currentDate.getDate() < birthDate.getDate())
                    ) {
                        age--
                    }

                    return `${
                        birthDate.toISOString().split('T')[0]
                    } (${age} lat)`
                } catch (e) {
                    // Błąd podczas przetwarzania daty
                    return 'Nieprawidłowa data'
                }
            },
            openLightbox(index) {
                this.currentPhotoIndex = index
                this.lightboxVisible = true
            },
            navigatePhoto(direction) {
                this.currentPhotoIndex += direction
                if (this.currentPhotoIndex < 0) {
                    this.currentPhotoIndex = this.userPhotos.length - 1
                } else if (this.currentPhotoIndex >= this.userPhotos.length) {
                    this.currentPhotoIndex = 0
                }
            },
            trimUserFlag() {
                if (this.user.flag) {
                    this.flagTrimmed = this.user.flag.trim()
                }
            },
            getClass(userId) {
                for (let i = 0; i < this.temporarymatch.length; i++) {
                    if (this.temporarymatch[i].secondUserId === userId) {
                        return 'single__btn--connected'
                    }
                }
                return null
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getUsers() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')

                // Ustawienie nagłówka Authorization
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }

                axios
                    .get(
                        `${this.url}api/questionnaire/singleuseradmin/${this.$route.params.id}`,
                        config
                    )
                    .then(resp => {
                        // console.log(resp)
                        this.user = resp.data.user
                        // this.matches = resp.data.matchedQuestionnaires
                        this.questionnaire = resp.data.questionnaire
                        this.userPhotos = [
                            this.user.photo1,
                            this.user.photo2,
                            this.user.photo3,
                            this.user.photo4
                        ].filter(Boolean)
                        if (this.userPhotos.length > 0) {
                            this.currentPhoto = this.userPhotos[0]
                        }
                        this.photosUpdated = true

                        this.setValuesPartOne(
                            resp.data.questionnaire,
                            resp.data.user
                        )
                        this.setValuesPartTwo(resp.data.questionnaire)
                        this.setValuesPartThree(resp.data.questionnaire)
                        this.setValuesPartFourth(resp.data.questionnaire)
                        this.setValuesPartFifth(resp.data.questionnaire)
                        this.setValuesPartSixth(resp.data.questionnaire)
                        this.setValuesPartSeventh(
                            resp.data?.questionnaire || {}
                        )
                        this.setValuesPartEight(resp.data.questionnaire)

                        window.setTimeout(() => {
                            this.loading = false
                        }, 1000)
                    })
                    .catch(err => {
                        console.log(err)

                        window.setTimeout(() => {
                            this.loading = false
                        }, 1000)
                    })
            },
            setValuesPartOne(questionnaire, user) {
                this.partOne.push({
                    text: 'Imię',
                    value: questionnaire.name,
                    name: 'name'
                })
                this.partOne.push({
                    text: 'Nazwisko',
                    value: questionnaire.surname,
                    name: 'surname'
                })
                this.partOne.push({
                    text: 'E-mail',
                    value: user.email,
                    name: 'email'
                })
                this.partOne.push({
                    text: 'Miasto zamieszkania',
                    value: questionnaire.city,
                    name: 'city'
                })
                this.partOne.push({
                    text: 'Miasto randkowania',
                    value: questionnaire.cityPossible,
                    name: 'cityPossible'
                })

                if (questionnaire.facebook) {
                    this.partOneSocials.push({
                        text: 'Facebook',
                        value: questionnaire.facebook,
                        name: 'facebook'
                    })
                }
                if (questionnaire.instagram) {
                    this.partOneSocials.push({
                        text: 'Instagram',
                        value: questionnaire.instagram,
                        name: 'instagram'
                    })
                }
                if (questionnaire.linkedin) {
                    this.partOneSocials.push({
                        text: 'LinkedIn',
                        value: questionnaire.linkedin,
                        name: 'linkedin'
                    })
                }
                if (questionnaire.website) {
                    this.partOneSocials.push({
                        text: 'Strona www',
                        value: questionnaire.website,
                        name: 'website'
                    })
                }
            },
            setValuesPartTwo(questionnaire) {
                this.partTwo.push({
                    text: 'Nawiązanie nowych znajomości:',
                    value: questionnaire.openToNewPeople,
                    answers: ['Przychodzi mi łatwo', 'Nie przychodzi mi łatwo'],
                    name: 'openToNewPeople'
                })
                this.partTwo.push({
                    text: 'Czy jesteś osobą otwartą?',
                    value: questionnaire.openToNewThings,
                    answers: ['Tak', 'Raczej tak', 'Nie'],
                    name: 'openToNewThings'
                })
                this.partTwo.push({
                    text: 'Czy jesteś osobą, która potrzebuje dużo wrażeń?',
                    value: questionnaire.lotOfImpressions,
                    answers: [
                        'Zdecydowanie tak',
                        'Raczej tak',
                        'Raczej nie',
                        'Zdecydowanie nie'
                    ],
                    name: 'lotOfImpressions'
                })
                this.partTwo.push({
                    text: 'Jestem osobą:',
                    value: questionnaire.characterTraits,
                    name: 'characterTraits'
                })
                this.partTwo.push({
                    text: 'Mówienie o emocjach i uczuciach:',
                    value: questionnaire.feelingsTalking,
                    answers: [
                        'Jest dla mnie wyzwaniem',
                        'Przychodzi mi dość trudno',
                        'Przychodzi mi dość łatwo',
                        'Nie jest dla mnie wyzwaniem'
                    ],
                    name: 'feelingsTalking'
                })
                this.partTwo.push({
                    text: 'Jak lubisz spędzać wolny czas?',
                    value: questionnaire.freeTime,
                    answers: [
                        'Zdecydowanie aktywnie',
                        'Cenię balans, łączę aktywność i odpoczynek',
                        'Relaksując się, mało aktywnie'
                    ],
                    name: 'freeTime'
                })
                this.partTwo.push({
                    text: 'Czy masz zwierzę lub zwierzęta domowe?',
                    value: questionnaire.petsHas,
                    answers: [
                        'Tak',
                        'Nie, ale chcę mieć',
                        'Nie i nie chcę mieć'
                    ],
                    name: 'petsHas'
                })
                this.partTwo.push({
                    text: 'Jaki jest Twój stosunek do zwierząt?',
                    value: questionnaire.petsAttitude,
                    answers: [
                        'Lubię zwierzaki',
                        'Nie przepadam',
                        'Zdecydowanie nie przepadam'
                    ],
                    name: 'petsAttitude'
                })
                this.partTwo.push({
                    text: 'Czy jest coś, co szczególnie Cię pasjonuje?',
                    value: questionnaire.hobby,
                    name: 'hobby'
                })
            },
            setValuesPartThree(questionnaire) {
                this.partThree.push({
                    text: 'Ile masz lat?',
                    value: (
                        new Date().getFullYear() -
                        new Date(questionnaire.age).getFullYear() -
                        1
                    ).toString()
                })
                this.partThree.push({
                    text: 'Jaka różnica wieku w relacji jest dla Ciebie akceptowalna?',
                    value: questionnaire.ageDifference,
                    answers: [
                        'do 5 lat',
                        'do 10 lat',
                        'do 15 lat',
                        'powyżej 15 lat'
                    ]
                })
                this.partThree.push({
                    text: 'Dodatkowa uwaga dotycząca wieku:',
                    value: questionnaire.ageAdditionalComment
                })
                this.partThree.push({
                    text: 'Jaki zawód wykonujesz?',
                    value: questionnaire.job
                })
                this.partThree.push({
                    text: 'Czy Twoja praca jest pod jakimś względem bardzo wymagająca?',
                    value: questionnaire.demandingJob,
                    answers: ['Tak', 'Nie']
                })
                this.partThree.push({
                    text: 'Co najbardziej lubisz w swojej pracy?',
                    value: questionnaire.jobPros
                })
                this.partThree.push({
                    text: 'Czy zawód, jaki wykonuje druga osoba jest dla Ciebie specjalnie ważny?',
                    value: questionnaire.jobImportance,
                    answers: ['Tak', 'Nie']
                })
                this.partThree.push({
                    text: 'Twój roczny dochód zawiera się w przedziale:',
                    value: questionnaire.income,
                    answers: [
                        '0-50 tysięcy zł brutto',
                        '50-100.000 zł brutto',
                        '100-200.000 zł brutto',
                        '200-500.000 zł brutto',
                        'powyżej 500.000 zł brutto',
                        'nie chcę udzielać odpowiedzi'
                    ]
                })
                this.partThree.push({
                    text: 'Wysokość zarobków drugiej osoby:',
                    value: questionnaire.incomeImportance,
                    answers: [
                        'Ma dla mnie bardzo duże znaczenie',
                        'Jest dla mnie istotna',
                        'Nie ma większego znaczenia',
                        'Nie ma w ogóle znaczenia'
                    ]
                })
                this.partThree.push({
                    text: 'Jakie jest Twoje wykształcenie:',
                    value: questionnaire.education,
                    answers: [
                        'podstawowe',
                        'gimnazjalne',
                        'zawodowe',
                        'średnie',
                        'licencjat/inżynier',
                        'magister',
                        'wyższy stopień naukowy'
                    ]
                })
                this.partThree.push({
                    text: 'Jak ważne jest dla Ciebie wykształcenie potencjalnego partnera/partnerki?',
                    value: questionnaire.educationImportance,
                    answers: [
                        'Najważniejsze',
                        'Ważne',
                        'Nie ma dla mnie większego znaczenia'
                    ]
                })
                this.partThree.push({
                    text: 'Dodatkowa uwaga dotycząca wykształcenia:',
                    value: questionnaire.educationAdditionalComment
                })
            },
            setValuesPartFourth(questionnaire) {
                // console.log(questionnaire)
                this.partFourth.push({
                    text: 'Twój stan cywilny:',
                    value: questionnaire.materialStatus,
                    answers: ['panna/kawaler', 'po rozwodzie', 'wdowiec/wdowa']
                })
                this.partFourth.push({
                    text: 'Czy zależy Ci na tym, żeby w przyszłości wziąć ślub?',
                    value: questionnaire.weddingWanted,
                    answers: [
                        'zdecydowanie tak',
                        'raczej tak',
                        'raczej nie',
                        'zdecydowanie nie'
                    ]
                })
                this.partFourth.push({
                    text: 'Jestem osobą:',
                    value: [
                        questionnaire.religion,
                        questionnaire.religionPractice
                    ],
                    answers: [
                        ['Wierzącą', 'Niewierzącą'],
                        ['Praktykującą', 'Niepraktykującą']
                    ]
                })
                this.partFourth.push({
                    text: 'Zależy mi, żeby potencjalny partner/partnerka:',
                    value: [
                        questionnaire.religionMatter,
                        questionnaire.religionPracticeMatter
                    ],
                    answers: [
                        ['Była osobą wierzącą', 'Była osobą niewierzącą'],
                        [
                            'Była osobą praktykującą',
                            'Była osobą niepraktykującą'
                        ]
                    ]
                })
                this.partFourth.push({
                    text: 'Czy bierzesz pod uwagę związek z osobą po rozwodzie?',
                    value: questionnaire.divorcedMatter,
                    answers: ['Tak', 'Absolutnie nie']
                })
                this.partFourth.push({
                    text: 'Czy masz dzieci?',
                    value: questionnaire.childrenHas,
                    answers: ['Tak', 'Nie']
                })
                this.partFourth.push({
                    text: 'Czy chcesz dzieci?',
                    value: questionnaire.childrenWanted,
                    answers: ['Chcę', 'Nie chcę']
                })
                this.partFourth.push({
                    text: 'Moje poglądy w kwestiach społecznych i politycznych:',
                    value: questionnaire.politicalViews,
                    answers: [
                        'są bardzo konserwatywne',
                        'są konserwatywne',
                        'są liberalne',
                        'są bardzo liberalne'
                    ]
                })
                this.partFourth.push({
                    text: 'Czy bierzesz pod uwagę możliwość przeprowadzki do innego miasta?',
                    value: questionnaire.movePossibility,
                    answers: ['Tak', 'Nie', 'Rozważam taką możliwość']
                })
            },
            setValuesPartFifth(questionnaire) {
                this.partFifth.push({
                    text: 'Wzrost:',
                    value: questionnaire.growth
                })
                this.partFifth.push({
                    text: 'Jak ważny jest dla Ciebie wzrost osoby, z którą możesz się umówić?',
                    value: questionnaire.growthMatter,
                    answers: [
                        'Musi być niższa',
                        'Musi być wyższa',
                        'Nie ma dla mnie znaczenia'
                    ]
                })
                this.partFifth.push({
                    text: 'Dodatkowa uwaga dotycząca wzrostu:',
                    value: questionnaire.growthComment
                })
                this.partFifth.push({
                    text: 'Czego absolutnie nie jesteś w stanie zaakceptować w wyglądzie drugiej osoby?',
                    value: questionnaire.appearanceNoAcceptation
                })
                this.partFifth.push({
                    text: 'Na co zwracasz szczególną uwagę w wyglądzie drugiej osoby?',
                    value: questionnaire.specialAppearance
                })
            },
            setValuesPartSixth(questionnaire) {
                this.partSixth.push({
                    text: 'Dodatkowe informacje o Tobie, które powinnam znać:',
                    value: questionnaire.additionalInfo
                })
                this.partSixth.push({
                    text: 'Co, poza uwzględnionymi wcześniej kwestiami, ma dla Ciebie szczególne znaczenie przy doborze partnera lub partnerki:',
                    value: questionnaire.additionalPartnerInfo
                })
            },
            setValuesPartSeventh(questionnaire) {
                this.partSeventh.push({
                    text: 'Uwielbiam ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Nie przepadam ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Tracę poczucie czasu, gdy ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Nie żal mi pieniędzy na ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Jestem najbardziej dumna/dumny z ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Nie wyobrażam sobie życia bez ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Najbardziej lubię spędzać wakacje ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Nie jestem w stanie zjeść ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'W moich przyjaciołach najbardziej cenię ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Najlepsza rada usłyszana w życiu to ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Potrawa, którą przyrządzam po mistrzowsku to ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Nie potrafię sobie odmówić ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Cecha, która sprawia, że od razu kogoś lubię to ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'W wolnym czasie ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Nigdy nie żal mi czasu na ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Coś, co szybko wyprowadza mnie z równowagi to ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Najgorszy prezent otrzymany w życiu to ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Lubię zapach ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Marzy mi się ',
                    value: questionnaire?.completeSentences
                })
                this.partSeventh.push({
                    text: 'Podziwiam ludzi za ',
                    value: questionnaire?.completeSentences
                })
            },
            setValuesPartEight(questionnaire) {
                this.partEight.push({
                    text: 'Opis:',
                    value: questionnaire.profileDescription,
                    name: 'profileDescription'
                })
                this.partEight.push({
                    text: 'Zaznacz jeśli zdjęcia są nieprawidłowe',
                    value: 'Zaznacz',
                    name: 'images'
                })
            },
            getUser(id) {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/verify/${id}`, config)
                    .then(resp => {
                        this.verifications = resp.data.verifications
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            setCurrentPhoto(photo) {
                this.currentPhoto = photo
            },
            getQuestionnaireDeclined() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }

                axios
                    .get(
                        `${this.url}api/questionnaire-declined/${this.$route.params.id}`,
                        config
                    )
                    .then(resp => {
                        // console.log('ques', resp)
                        this.questionnaireDeclined = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getMatch() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/match/${this.$route.params.id}`, config)
                    .then(resp => {
                        this.match = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getTemporaryMatch() {
                let id = window.localStorage.getItem('userId')

                const authToken = this.getCookie('authToken')

                // Ustawienie nagłówka Authorization
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }

                axios
                    .get(
                        `${this.url}api/temporarymatch/${this.$route.params.id}`,
                        config
                    )
                    .then(resp => {
                        // this.getUsers(this.$route.params.id);
                        this.temporarymatch = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            addTemporaryMatch(id) {
                const authToken = this.getCookie('authToken')

                // Ustawienie nagłówka Authorization
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
                axios
                    .post(
                        `${this.url}api/temporarymatch`,
                        {
                            userId: this.user._id,
                            secondUserId: id
                        },
                        config
                    )
                    .then(() => {
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
                // console.log(this.user._id, id)
            },

            // matched person format data methods
            politicalViewsFormattedMatch(item) {
                switch (item.politicalViews) {
                    case 0:
                        return 'są bardzo konserwatywne'
                    case 1:
                        return 'są konserwatywne'
                    case 2:
                        return 'są liberalne'
                    case 3:
                        return 'są bardzo liberalne'
                    default:
                        return 'nieznane'
                }
            },
            weddingWantedFormattedMatch(item) {
                switch (item.weddingWanted) {
                    case 0:
                        return 'zdecydowanie tak'
                    case 1:
                        return 'raczej tak'
                    case 2:
                        return 'raczej nie'
                    case 3:
                        return 'zdecydowanie nie'
                    default:
                        return 'nieznana'
                }
            },
            incomeImportanceFormattedMatch(item) {
                switch (item.incomeImportance) {
                    case 0:
                        return 'Ma dla mnie bardzo duże znaczenie'
                    case 1:
                        return 'Jest dla mnie istotna'
                    case 2:
                        return 'Nie ma większego znaczenia'
                    case 3:
                        return 'Nie ma w ogóle znaczenia'
                    default:
                        return 'nieznana'
                }
            },
            incomeFormattedMatch(item) {
                switch (item.income) {
                    case 0:
                        return '0-50 tysięcy zł brutto'
                    case 1:
                        return '50-100.000 zł brutto'
                    case 2:
                        return '100-200.000 zł brutto'
                    case 3:
                        return '200-500.000 zł brutto'
                    case 4:
                        return 'powyżej 500.000 zł brutto'
                    case 5:
                        return 'nie chcę udzielać odpowiedzi'
                    default:
                        return 'nieznana'
                }
            },
            petsAttitudeFormattedMatch(item) {
                switch (item.petsAttitude) {
                    case 0:
                        return 'Lubię zwierzaki'
                    case 1:
                        return 'Nie przepadam'
                    case 2:
                        return 'Zdecydowanie nie przepadam'
                    default:
                        return 'nieznana'
                }
            },
            petsHasFormattedMatch(item) {
                switch (item.petsHas) {
                    case 0:
                        return 'Tak'
                    case 1:
                        return 'Nie, ale chcę mieć'
                    case 2:
                        return 'Nie i nie chcę mieć'
                    default:
                        return 'nieznana'
                }
            },
            freeTimeFormattedMatch(item) {
                switch (item.freeTime) {
                    case 0:
                        return 'Zdecydowanie aktywnie'
                    case 1:
                        return 'Cenię balans, łączę aktywność i odpoczynek'
                    case 2:
                        return 'Relaksując się, mało aktywnie'
                    default:
                        return 'nieznana'
                }
            },
            feelingsTalkingFormattedMatch(item) {
                switch (item.feelingsTalking) {
                    case 0:
                        return 'Jest dla mnie wyzwaniem'
                    case 1:
                        return 'Przychodzi mi dość trudno'
                    case 2:
                        return 'Przychodzi mi dość łatwo'
                    case 3:
                        return 'Nie jest dla mnie wyzwaniem'
                    default:
                        return 'nieznana'
                }
            },
            lotOfImpressionsFormattedMatch(item) {
                switch (item.lotOfImpressions) {
                    case 0:
                        return 'Zdecydowanie tak'
                    case 1:
                        return 'Raczej tak'
                    case 2:
                        return 'Raczej nie'
                    case 3:
                        return 'Zdecydowanie nie'
                    default:
                        return 'nieznana'
                }
            },
            openToNewThingsFormattedMatch(item) {
                switch (item.openToNewThings) {
                    case 0:
                        return 'Tak'
                    case 1:
                        return 'Raczej tak'
                    case 2:
                        return 'Nie'
                    default:
                        return 'nieznana'
                }
            },
            openToNewPeopleFormattedMatch(item) {
                switch (item.openToNewPeople) {
                    case 0:
                        return 'Przychodzi mi łatwo'
                    case 1:
                        return 'Nie przychodzi mi łatwo'
                    default:
                        return 'nieznana'
                }
            },
            cityPossibleFormattedMatch(item) {
                switch (item.cityPossible) {
                    case '0':
                        return 'Poznań'
                    case '1':
                        return 'Warszawa'
                    case 0:
                        return 'Poznań'
                    case 1:
                        return 'Warszawa'
                    default:
                        return 'nieznana'
                }
            },
            growthMatterFormattedMatch(item) {
                switch (item.growthMatter) {
                    case 0:
                        return 'Musi być niższa'
                    case 1:
                        return 'Musi być wyższa'
                    case 2:
                        return 'Nie ma dla mnie znaczenia'
                    default:
                        return 'nieznana'
                }
            },
            addPremiumOption() {
                axios
                    .put(
                        `${this.url}api/verify/addpremium/${this.$route.params.id}`
                    )
                    .then(() => {
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            handleKeydown(e) {
                if (!this.lightboxVisible) return
                if (e.key === 'ArrowLeft') {
                    this.navigatePhoto(-1)
                } else if (e.key === 'ArrowRight') {
                    this.navigatePhoto(1)
                }
            },
            handleKeydownExit(e) {
                if (!this.lightboxVisible) return
                if (e.key == 'Escape') {
                    this.lightboxVisible = false
                }
            }
        },
        created() {
            this.getUsers()
            this.getQuestionnaireDeclined()
            this.getUser(this.$route.params.id)
            // this.getTemporaryMatch()
            // this.getMatch()
            this.trimUserFlag()
        },
        // watch: {
        //     '$route.params.id': function (newId, oldId) {
        //         this.getTemporaryMatch()
        //     }
        // },
        computed: {
            currentPhoto() {
                return this.userPhotos[this.currentPhotoIndex]
            },
            politicalViewsFormatted() {
                switch (this.questionnaire.sex) {
                    case 0:
                        return 'są bardzo konserwatywne'
                    case 1:
                        return 'są konserwatywne'
                    case 2:
                        return 'są liberalne'
                    case 3:
                        return 'są bardzo liberalne'
                    default:
                        return 'nieznane'
                }
            },
            matchMediaweddingWantedFormatted() {
                switch (this.questionnaire.weddingWanted) {
                    case 0:
                        return 'zdecydowanie tak'
                    case 1:
                        return 'raczej tak'
                    case 2:
                        return 'raczej nie'
                    case 3:
                        return 'zdecydowanie nie'
                    default:
                        return 'nieznana'
                }
            },
            incomeImportanceFormatted() {
                switch (this.questionnaire.incomeImportance) {
                    case 0:
                        return 'Ma dla mnie bardzo duże znaczenie'
                    case 1:
                        return 'Jest dla mnie istotna'
                    case 2:
                        return 'Nie ma większego znaczenia'
                    case 3:
                        return 'Nie ma w ogóle znaczenia'
                    default:
                        return 'nieznana'
                }
            },
            incomeFormatted() {
                switch (this.questionnaire.income) {
                    case 0:
                        return '0-50 tysięcy zł brutto'
                    case 1:
                        return '50-100.000 zł brutto'
                    case 2:
                        return '100-200.000 zł brutto'
                    case 3:
                        return '200-500.000 zł brutto'
                    case 4:
                        return 'powyżej 500.000 zł brutto'
                    case 5:
                        return 'nie chcę udzielać odpowiedzi'
                    default:
                        return 'nieznana'
                }
            },
            petsAttitudeFormatted() {
                switch (this.questionnaire.petsAttitude) {
                    case 0:
                        return 'Lubię zwierzaki'
                    case 1:
                        return 'Nie przepadam'
                    case 2:
                        return 'Zdecydowanie nie przepadam'
                    default:
                        return 'nieznana'
                }
            },
            petsHasFormatted() {
                switch (this.questionnaire.petsHas) {
                    case 0:
                        return 'Tak'
                    case 1:
                        return 'Nie, ale chcę mieć'
                    case 2:
                        return 'Nie i nie chcę mieć'
                    default:
                        return 'nieznana'
                }
            },
            freeTimeFormatted() {
                switch (this.questionnaire.freeTime) {
                    case 0:
                        return 'Zdecydowanie aktywnie'
                    case 1:
                        return 'Cenię balans, łączę aktywność i odpoczynek'
                    case 2:
                        return 'Relaksując się, mało aktywnie'
                    default:
                        return 'nieznana'
                }
            },
            feelingsTalkingFormatted() {
                switch (this.questionnaire.feelingsTalking) {
                    case 0:
                        return 'Jest dla mnie wyzwaniem'
                    case 1:
                        return 'Przychodzi mi dość trudno'
                    case 2:
                        return 'Przychodzi mi dość łatwo'
                    case 3:
                        return 'Nie jest dla mnie wyzwaniem'
                    default:
                        return 'nieznana'
                }
            },
            lotOfImpressionsFormatted() {
                switch (this.questionnaire.lotOfImpressions) {
                    case 0:
                        return 'Zdecydowanie tak'
                    case 1:
                        return 'Raczej tak'
                    case 2:
                        return 'Raczej nie'
                    case 3:
                        return 'Zdecydowanie nie'
                    default:
                        return 'nieznana'
                }
            },
            openToNewThingsFormatted() {
                switch (this.questionnaire.openToNewThings) {
                    case 0:
                        return 'Tak'
                    case 1:
                        return 'Raczej tak'
                    case 2:
                        return 'Nie'
                    default:
                        return 'nieznana'
                }
            },
            openToNewPeopleFormatted() {
                switch (this.questionnaire.openToNewPeople) {
                    case 0:
                        return 'Przychodzi mi łatwo'
                    case 1:
                        return 'Nie przychodzi mi łatwo'
                    default:
                        return 'nieznana'
                }
            },
            cityPossibleFormatted() {
                switch (this.questionnaire.cityPossible) {
                    case '0':
                        return 'Poznań'
                    case '1':
                        return 'Warszawa'
                    case 0:
                        return 'Poznań'
                    case 1:
                        return 'Warszawa'
                    default:
                        return 'nieznana'
                }
            },
            growthMatterFormatted() {
                switch (this.questionnaire.growthMatter) {
                    case 0:
                        return 'Musi być niższa'
                    case 1:
                        return 'Musi być wyższa'
                    case 2:
                        return 'Nie ma dla mnie znaczenia'
                    default:
                        return 'nieznana'
                }
            }
            //matched
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .seventh {
        .single__user-parts-second-text {
            padding-top: 21px;
            margin-top: 3px;
        }
        .single__user-parts-line {
            margin-bottom: 0;
        }
        .single__user-parts-second-item-line {
            display: block !important;
            margin-bottom: 0 !important;
            width: 100%;
        }
        .single__user-parts-second-input,
        .single__user-parts-second-fix {
            display: none;
        }
        .single__user-parts-second-item {
            width: 42.2%;
            &:first-child {
                width: 100%;
                .single__user-parts-second-item-line {
                    width: 42.2%;
                }
                .single__user-parts-second-input {
                    display: block;
                }
                .single__user-parts-second-fix {
                    display: flex;
                }
            }
        }
    }
    .send-questionnaire-button {
        padding-bottom: 184px;
        .disabled {
            cursor: not-allowed;
        }
    }
    .send-questionnaire {
        display: block;
        outline: none;
        border: 1px solid $black;
        margin: 40px auto 0px auto;
        width: 200px;
        text-align: center;
        padding: 15px 0;
        background-color: #e1dbd5;
        font-size: 1.6rem;
        line-height: 3rem;
        color: $black;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $pink;
            color: $white;
            transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &__response {
            text-align: center;
            margin-top: 30px;
        }
    }
    .single {
        .bottom-text {
            text-align: center;
            margin-bottom: 40px;
        }
        &__user-parts-description {
            font-size: 1.6rem;
            line-height: 3rem;
            color: $black;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            max-width: 800px;
        }
        &__waiting_on_user {
            margin-top: 35px;
            padding-bottom: 40px;
            font-size: 1.6rem;
            font-family: 'Open Sans', sans-serif;
            color: $black;
            font-weight: 500;
        }
        &__message {
            list-style-type: none;
            background-color: $gray;
            padding: 10px;
            border-radius: 8px;
        }
        &__overlay {
            width: 100%;
            height: 100vh;
            background-color: #111111;
            opacity: 70%;
            position: fixed;
            top: 0;
            left: 0;
        }
        &__popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 500px;
            width: 100%;
            padding: 20px;
            background-color: $gray;
            border-radius: 15px;
            &-wrapper {
                display: flex;
                flex-direction: column;
                textarea {
                    resize: none;
                    padding: 20px;
                    border: 1px solid darken($gray, 10%);
                    border-radius: 10px;
                    font-size: 1.6rem;
                }
                .single__btn {
                    margin-top: 8px;
                }
            }
        }
        &__check {
            border: 3px dashed #f2545b;
            border-radius: 15px;
            margin: 20px 0;
            margin-top: 0;
            padding: 20px;
            h3 {
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 10px;
                font-size: 1.4rem;
                line-height: 1.6rem;
                color: rgba(17, 17, 17, 0.5);
                font-weight: 600;
                padding: 15px 20px 0px 0;
                border-radius: 10px;
            }
        }
        &__verification {
            color: #f2545b;
            background: lighten(#f2545b, 10%);
            padding: 15px 20px !important;
            margin-top: 10px;
        }

        &__columns {
            display: flex;
            margin-left: 90px;
        }
        &__photos {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
        &__left,
        &__right {
            flex: 1;
            figure {
                width: fit-content;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    border-radius: 10px;
                }
            }
            h3 {
                font-size: 1.8rem;
                line-height: 2.5rem;
                color: $black1;
                font-weight: 700;
            }
        }
        &__left {
            @include breakpoint-max('xs-tablet') {
                padding: 0 30px;
            }
        }
        &__left-prev {
            display: block;
            width: fit-content;
            padding: 17px 25px;
            padding-top: 20px;
            background-color: $pink;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            font-size: 1.6rem;
            line-height: 3rem;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            margin-bottom: 40px;
        }
        &__gallery-display figure,
        &__gallery-thumbnails figure {
            margin: 10px;
            cursor: pointer;
        }
        &__gallery-thumbnails img {
            height: auto;
        }
        &__user {
            margin-bottom: 50px;
            background-color: $gray;
            border-radius: 15px;
        }
        &__user-top,
        &__users-top {
            display: flex;
            margin-bottom: 35px;
        }
        &__user-top-person {
            margin-top: 30px;
        }
        .no-image {
            margin-top: 30px;
        }
        &__user-top-person-name {
            padding: 8px 36px 8px 59px;
            background-color: $pink;
            color: $white;
            font-size: 3rem;
            line-height: 4.5rem;
            font-weight: 700;
            text-transform: capitalize;
            font-family: 'Open Sans', sans-serif;
            box-shadow: 0px 3px 6px #00000029;
            margin-bottom: 41px;
            @include breakpoint-max('xs-tablet') {
                padding: 6px 20px;
            }
            @include breakpoint-max('mobile') {
                padding: 4px 10px;
                font-size: 2rem;
                margin-bottom: 10px;
            }
        }
        &__user-top-person-data {
            padding-left: 51px;
            div {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 26px;
                    @include breakpoint-max('mobile') {
                        margin-right: 15px;
                        max-width: 15px;
                    }
                }
                p {
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-family: 'Open Sans', sans-serif;
                }
                @include breakpoint-max('mobile') {
                    margin-bottom: 0px;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 20px;
            }
            @include breakpoint-max('mobile') {
                padding-left: 10px;
            }
        }
        &__user-top-person-data-height {
            p {
                text-transform: lowercase !important;
            }
        }
        &__user-parts-first {
            max-width: 1200px;
            margin-bottom: 44px;
            p {
                font-size: 1.6rem;
                line-height: 2rem;
                color: $black;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                margin-bottom: 17px;
                span {
                    font-weight: 400;
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            .social-links {
                margin-bottom: 0 !important;
                margin-left: 20px;
            }
        }
        &__user-parts-first-social {
            display: block;
            margin-bottom: 7px;
            color: $black;
            text-decoration: none;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.6rem;
            line-height: 3rem;
            font-weight: 400;
            transition: 0.3s;
            width: fit-content;
            &:hover {
                color: $pink;
            }
        }
        &__user-parts-second {
            max-width: 1200px;
            margin-bottom: 43px;
        }
        &__user-parts-first-title,
        &__user-parts-second-title {
            font-size: 3.5rem;
            line-height: 5rem;
            color: $black;
            padding-bottom: 17px;
            width: 100%;
            max-width: 506px;
            font-family: 'Promenade';
            font-weight: 400;
        }
        &__user-parts-line {
            margin-bottom: 17px;
            height: 3px;
            background-color: $black;
            width: 42.2%;
            @include breakpoint-max('xl-tablet') {
                width: 65%;
            }
        }
        &__user-parts {
            margin-top: 35px;
            padding-right: 30px;
            @include breakpoint-max('xs-tablet') {
                padding-right: 0px;
            }
        }
        &__user-parts-second-title {
            margin-bottom: 3px;
        }
        &__user-parts-second-item {
            max-width: 1200px;
            width: 100%;
            &:last-child {
                .single__user-parts-second-item-line {
                    display: none;
                }
            }
        }
        &__user-parts-second-item-box {
            padding-top: 3px;
            display: flex;
            align-items: flex-start;
            column-gap: 41px;
            @include breakpoint-max('m-large') {
                column-gap: 15px;
            }
            @include breakpoint-max('xl-tablet') {
                flex-direction: column;
            }
        }
        &__user-parts-second-item-box-left {
            flex: 68;
            display: flex;
            column-gap: 41px;
            .seventh-list {
                display: block;
            }
            @include breakpoint-max('m-large') {
                column-gap: 15px;
            }
            @include breakpoint-max('xl-tablet') {
                width: 100%;
            }
        }
        .seventh-list {
            span {
                text-transform: lowercase;
            }
        }
        &__user-parts-second-item-line {
            margin-bottom: 17px;
            width: 42.2%;
            height: 1px;
            background-color: $black;
            @include breakpoint-max('xl-tablet') {
                margin-top: 20px;
                width: 65%;
            }
        }
        &__user-parts-second-text {
            flex: 50;
            padding-bottom: 21px;
            margin-bottom: 3px;
            h3 {
                margin-bottom: 3px;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                color: $black;
            }
            p {
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                color: $black;
            }
            em {
                font-style: normal;
                color: $pink;
                font-weight: 700;
            }
            .sixth-list {
                margin-bottom: 10px;
            }
            @include breakpoint-max('large') {
                flex: 35;
            }
            @include breakpoint-max('xl-tablet') {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
        &__user-parts-second-fix {
            flex: 13;
            display: flex;
            justify-content: center;
            column-gap: 22px;
            label {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                align-items: center;
            }
            span {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $black;
                font-weight: 400;
            }
            // Input type radio
            .content {
                position: relative;
                display: grid;
                place-items: center;
                width: 27px;
                height: 27px;
            }
            .checkBox {
                display: block;
                cursor: pointer;
                width: 27px;
                height: 27px;
                border: 4px solid transparent;
                border-radius: 50%;
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 0px 1.5px $black;
            }

            .checkBox div {
                width: 60px;
                height: 60px;
                background-color: $pink;
                top: -52px;
                left: -52px;
                position: absolute;
                transform: rotateZ(45deg);
                z-index: 100;
            }
            .correct {
                .checkBox div {
                    background-color: $black;
                }
            }

            .checkBox input[type='radio']:checked + div {
                left: -10px;
                top: -10px;
            }

            .checkBox input[type='radio'] {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                visibility: hidden;
            }

            .transition {
                transition: 300ms ease;
            }
            //
            @include breakpoint-max('m-large') {
                flex: 15;
            }
            @include breakpoint-max('mobile') {
                column-gap: 10px;
            }
        }
        &__user-parts-second-fix-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 17px;
        }
        .to-fix {
            min-width: 42px;
        }
        &__user-parts-second-input {
            flex: 47;
            div {
                display: flex;
                flex-direction: column;
                row-gap: 17px;
                @include breakpoint-max('xl-tablet') {
                    margin-top: 10px;
                }
            }
            span {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $black;
                font-weight: 400;
            }
            input,
            textarea {
                border: 1px solid #2a2a29;
                background: transparent;
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                color: $black;
                outline: none;
                padding: 4px 13px;
            }
            textarea {
                resize: none;
                height: 100px;
            }
            @include breakpoint-max('m-large') {
                flex: 35;
            }
            @include breakpoint-max('xl-tablet') {
                width: 100%;
            }
        }
        &__users {
            margin-bottom: 50px;
            background-color: $gray;
            padding: 31px 35px;
            border-radius: 15px;
            max-width: 650px;
        }
        &__user-info,
        &__users-info {
            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $black1;
                font-weight: 600;
                margin-bottom: 10px;
            }
            span {
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: rgba(17, 17, 17, 0.5);
                font-weight: 600;
                text-align: right;
                margin-left: 10px;
            }
        }
        &__user-mail,
        &__user-phone {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            p {
                margin-bottom: 0;
                margin-left: 13px;
            }
        }
        &__btn {
            padding: 14px 21px;
            cursor: pointer;
            border-radius: 10px;
            color: $white;
            background-color: #0fb70d;
            &--margin {
                margin-left: 8px;
            }
            &--red {
                background-color: #f2545b;
            }
        }

        .admin-users-view {
            &__header {
                max-width: 1597px;
                height: 104px;
                background-color: $white;
                padding-left: 65px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &__header-logo {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 14px;
                }
                p {
                    font-family: 'NunitoExtraBold';
                    font-weight: 800;
                    font-size: 2rem;
                    line-height: 3.7rem;
                    color: $black;
                }
            }
            &__header-notification {
                cursor: pointer;
                width: 53px;
                height: 53px;
                background: $gray;
                border-radius: 15px;
                display: grid;
                place-items: center;
                position: relative;
                transition: 0.3s;
                &:hover {
                    svg {
                        animation: myAnim 2s ease 0s 1 normal forwards;
                    }
                }
            }
            &__header-notification-circle {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 12px;
                height: 12px;
                background-color: #f2545b;
                border-radius: 50%;
            }
            &__wrapper {
                display: flex;
                padding-bottom: 86px;
            }
            &__sidebar {
                max-width: 303px;
                width: 100%;
                height: calc(100vh - 104px);
                background: $gray;
                border-radius: 0 15px 15px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding-top: 54px;
                padding-bottom: 150px;
            }
            &__sidebar-pages {
                position: relative;
                display: flex;
                flex-direction: column;
                list-style: none;
                li {
                    margin-bottom: 13px;
                    a {
                        text-decoration: none;
                        font-family: 'NunitoMedium';
                        font-weight: 500;
                        font-size: 1.8rem;
                        line-height: 2.5rem;
                        color: $black1;
                        height: 38px;
                        display: flex;
                        align-items: center;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    svg {
                        margin-right: 13px;
                    }
                }
            }
            &__sidebar-nav {
                position: absolute;
                left: -19px;
                top: 0px;
                width: 4px;
                height: 38px;
                background-color: #f2545b;
                box-shadow: -3px 3px 6px rgba(242, 84, 91, 0.35);
                border-radius: 500px;
                transition: 0.3s;
            }
            &__sidebar-logout {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &__sidebar-line {
                margin-bottom: 34px !important;
                max-width: 239px;
                margin: 0 auto;
                width: 100%;
                height: 1px;
                background-color: $bege;
            }
            &__sidebar-logout-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;
                p {
                    font-family: 'NunitoMedium';
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2.5rem;
                    color: $black1;
                }
                &:hover {
                    .arrow {
                        left: 12px;
                    }
                }
            }
            &__sidebar-logout-svg {
                position: relative;
                width: 17px;
                height: 19px;
                margin-right: 15px;
                .left {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
                .arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 6px;
                    transition: 0.3s;
                }
            }
            &__content {
                width: 100%;
                height: calc(100vh - 104px);
                // background-image: url(http://localhost:8080/img/light-bcg-big.93d5f768.png);
                input {
                    padding-left: 93px;
                    width: 270px;
                    padding: 15px;
                }
            }
            &__content-users {
                padding-top: 49px;
                padding-left: 93px;
                padding-top: 13px;
                max-width: 1294px;
                width: 100%;
            }
            &__content-input {
                padding-left: 93px;
                input {
                    background: $gray;
                    border-radius: 10px;
                    outline: none;
                    border: none;
                    font-family: 'NunitoBold';
                    font-weight: 700;
                    font-size: 1.6rem;
                    color: #111111;
                }
            }
            &__content-input-wrapper {
                position: relative;
                width: fit-content;
                svg {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 24px;
                }
            }

            @keyframes myAnim {
                0%,
                100% {
                    transform: translateX(0%);
                    transform-origin: 50% 50%;
                }

                15% {
                    transform: translateX(-5px) rotate(12deg);
                }

                30% {
                    transform: translateX(5px) rotate(-12deg);
                }

                45% {
                    transform: translateX(0px) rotate(3.6deg);
                }

                60% {
                    transform: translateX(0px) rotate(-2.4deg);
                }

                75% {
                    transform: translateX(0px) rotate(1.2deg);
                }
            }
        }
    }

    .gallery {
        &__thumbnails {
            figure {
                display: block;
                img {
                    cursor: pointer;
                    display: block;
                    height: auto;
                    width: 280px;
                    height: 280px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    object-position: center;
                    border-radius: 0 !important;
                    border: 1px solid $black;
                    @include breakpoint-max('xs-tablet') {
                        width: 240px;
                        height: 240px;
                    }
                    @include breakpoint-max('mobile') {
                        width: 160px;
                        height: 160px;
                    }
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                }
            }
        }
    }
    .lightbox {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            max-width: 80vw; /* 80% szerokości widoku */
            max-height: 80vh; /* 80% wysokości widoku */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            max-width: 95vw;
            max-height: 95vh;
            object-fit: contain !important;
        }
    }

    .lightbox__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .lightbox__nav {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }

    .lightbox__nav--left {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }

    .lightbox__nav--right {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }

    .lightbox__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
</style>
