<template>
    <div class="check">
        {{ user }}
        {{ questionnaires }}
        {{ verifications }}

        <h3>Weryfikacje</h3>
        <ul v-if="verifications">
            <li v-for="item in verifications">
                {{ item }}
            </li>
        </ul>

        <div class="check__popup" v-if="popup">
            <p>Podaj powód odrzucenia</p>
            <textarea v-model="reason"></textarea>
            <button class="check__btn" @click="decline">Wyślij</button>
            <button class="check__btn" @click="popup = false">Anuluj</button>
        </div>

        <div v-if="!user.isVerified && (!verifications || !verifications.length || verifications[verifications.length - 1].resolved === true)">
            <button class="check__btn" @click="addVerify">Zatwierdź</button>
            <button class="check__btn" @click="popup = true">Odrzuć</button>
        </div>

        <div v-else-if="!user.isVerified && verifications && verifications.length && verifications[verifications.length - 1].resolved === false">
            <p>Oczekiwanie na poprawienie profilu.</p>
        </div>

        <div v-else>
            <p>Osoba zweryfikowana.</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'admin verify user',
    data(){
        return{
            user: [],
            questionnaires: [],
            verifications: [],
            popup: false,
            reason: ''
        }
    },
    methods: {
        checkVerifications(){
            if (this.verifications && this.verifications.length > 0) {
                const lastVerification = this.verifications[this.verifications.length - 1];
                return lastVerification && lastVerification.resolved === true;
            }
            return true;
        },
        getUser(id){

            const authToken = this.getCookie('authToken')
            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }

            axios
                .get(`${this.url}api/verify/${id}`, config)
                .then((resp) => {
                    this.user = resp.data.user;
                    this.questionnaires = resp.data.questionnaires;
                    this.verifications = resp.data.verifications;
                })
                .catch((err) => {
                    console.log(err);
                });
    
        },
        addVerify(){
            const authToken = this.getCookie('authToken')
            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }

            axios
                .post(`${this.url}api/verify/`, {
                    userid: this.$route.params.id
                }, config)
                .then(() => {
                    location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });

        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            }
        },
        decline(){
            const authToken = this.getCookie('authToken');
            const config = { headers: { 'Authorization': `Bearer ${authToken}` } };
            
            axios
                .post(`${this.url}api/verify/decline`, {
                    userid: this.$route.params.id,
                    reason: this.reason
                }, config)
                .then(() => {
                    location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },
    created(){
        this.getUser(this.$route.params.id);
    },
}
</script>

<style lang="scss">
.check{
    &__btn{
        padding: 10px 15px;
        margin-right: 10px;
        cursor: pointer;
    }
    &__popup{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFF;
        display: flex;
        flex-direction: column;
    }
}
</style>
  