<template>
    <div class="personal-basic-info-profile">
        <div
            class="personal-basic-info-profile__user-top-person person-mobile-name"
        >
            <h1 class="personal-basic-info-profile__user-top-person-name">
                {{ name }}, {{ setAge(age) }}
            </h1>
        </div>
        <div class="personal-basic-info-profile__user-top">
            <!-- Lightbox -->
            <div
                v-if="lightboxVisible"
                class="lightbox"
                @click.self="lightboxVisible = false"
            >
                <figure>
                    <img :src="currentPhoto" alt="Powiększone zdjęcie" />
                </figure>
                <div class="lightbox__controls">
                    <button
                        class="lightbox__close"
                        @click="lightboxVisible = false"
                    >
                        X
                    </button>
                    <button
                        class="lightbox__nav lightbox__nav--left"
                        @click="navigatePhoto(-1)"
                    >
                        &#8592;
                    </button>
                    <button
                        class="lightbox__nav lightbox__nav--right"
                        @click="navigatePhoto(1)"
                    >
                        &#8594;
                    </button>
                </div>
            </div>
            <!-- END Lightbox -->
            <div class="personal-basic-info-profile__user-top-wrapper">
                <div
                    class="personal-basic-info-profile__user-top-gallery gallery"
                >
                    <div class="gallery__thumbnails">
                        <figure
                            v-if="userPhotos[0] && !hideGalleryOnMobile"
                            @click="openLightbox(0)"
                        >
                            <img :src="userPhotos[0]" alt="Photo" />
                        </figure>
                        <figure
                            v-else-if="hideGalleryOnMobile"
                            @click="editImages()"
                        >
                            <img :src="userPhotos[0]" alt="Photo" />
                        </figure>
                        <figure v-else>
                            <p v-if="userPhotos[0]">Brak zdjęcia</p>
                            <img
                                src="../../assets//images/ludzik1.png"
                                alt="Photo"
                            />
                        </figure>
                    </div>
                    <div
                        v-if="isLinkActive('/panel/profile')"
                        @click="editImages()"
                        class="personal-basic-info-profile__edit-images"
                    >
                        Edytuj zdjęcia
                    </div>
                    <div class="profile__flex-images">
                        <div @click="editImages()">
                            <img
                                v-if="userPhotos[1]"
                                :src="userPhotos[1]"
                                class="profile__img profile__img--unactive"
                            />
                            <img
                                v-else
                                src="../../assets//images/ludzik1.png"
                                class="profile__img profile__img--unactive"
                                alt="Photo"
                            />
                        </div>
                        <div @click="editImages()">
                            <img
                                v-if="userPhotos[2]"
                                :src="userPhotos[2]"
                                class="profile__img profile__img--unactive"
                            />
                            <img
                                v-else
                                src="../../assets//images/ludzik1.png"
                                class="profile__img profile__img--unactive"
                                alt="Photo"
                            />
                        </div>
                        <div @click="editImages()">
                            <img
                                v-if="userPhotos[3]"
                                :src="userPhotos[3]"
                                class="profile__img profile__img--unactive"
                            />
                            <img
                                v-else
                                src="../../assets//images/ludzik1.png"
                                class="profile__img profile__img--unactive"
                                alt="Photo"
                            />
                        </div>
                    </div>
                    <div
                        v-if="showEditPop"
                        class="personal-basic-info-profile__edit-images-popup"
                    >
                        <div
                            @click="closeEditPop()"
                            class="personal-basic-info-profile__pop-close"
                        >
                            zamknij
                        </div>
                        <h3>Zmień zdjęcia</h3>
                        <div class="save-profile__photos-flex">
                            <div class="images-box">
                                <picture>
                                    <img
                                        :src="imageSrc1"
                                        @click="triggerFileInput1"
                                        class="profile__img profile__img--border"
                                    />
                                </picture>
                                <svg
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 50 50"
                                    xml:space="preserve"
                                >
                                    <circle
                                        style="fill: #ffffff"
                                        cx="25"
                                        cy="25"
                                        r="25"
                                    />
                                    <line
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 10;
                                        "
                                        x1="25"
                                        y1="13"
                                        x2="25"
                                        y2="38"
                                    />
                                    <line
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 10;
                                        "
                                        x1="37.5"
                                        y1="25"
                                        x2="12.5"
                                        y2="25"
                                    />
                                </svg>
                            </div>
                            <div class="images-box">
                                <picture>
                                    <img
                                        :src="imageSrc2"
                                        @click="triggerFileInput2"
                                        class="profile__img"
                                    />
                                </picture>
                                <svg
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 50 50"
                                    xml:space="preserve"
                                >
                                    <circle
                                        style="fill: #ffffff"
                                        cx="25"
                                        cy="25"
                                        r="25"
                                    />
                                    <line
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 10;
                                        "
                                        x1="25"
                                        y1="13"
                                        x2="25"
                                        y2="38"
                                    />
                                    <line
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 10;
                                        "
                                        x1="37.5"
                                        y1="25"
                                        x2="12.5"
                                        y2="25"
                                    />
                                </svg>
                                <p
                                    v-if="imageSrc2"
                                    @click="deletePhoto(2)"
                                    class="trash"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="100"
                                        height="100"
                                        viewBox="0 0 128 128"
                                    >
                                        <path
                                            d="M 49 1 C 47.34 1 46 2.34 46 4 C 46 5.66 47.34 7 49 7 L 79 7 C 80.66 7 82 5.66 82 4 C 82 2.34 80.66 1 79 1 L 49 1 z M 24 15 C 16.83 15 11 20.83 11 28 C 11 35.17 16.83 41 24 41 L 101 41 L 101 104 C 101 113.37 93.37 121 84 121 L 44 121 C 34.63 121 27 113.37 27 104 L 27 52 C 27 50.34 25.66 49 24 49 C 22.34 49 21 50.34 21 52 L 21 104 C 21 116.68 31.32 127 44 127 L 84 127 C 96.68 127 107 116.68 107 104 L 107 40.640625 C 112.72 39.280625 117 34.14 117 28 C 117 20.83 111.17 15 104 15 L 24 15 z M 24 21 L 104 21 C 107.86 21 111 24.14 111 28 C 111 31.86 107.86 35 104 35 L 24 35 C 20.14 35 17 31.86 17 28 C 17 24.14 20.14 21 24 21 z M 50 55 C 48.34 55 47 56.34 47 58 L 47 104 C 47 105.66 48.34 107 50 107 C 51.66 107 53 105.66 53 104 L 53 58 C 53 56.34 51.66 55 50 55 z M 78 55 C 76.34 55 75 56.34 75 58 L 75 104 C 75 105.66 76.34 107 78 107 C 79.66 107 81 105.66 81 104 L 81 58 C 81 56.34 79.66 55 78 55 z"
                                        ></path>
                                    </svg>
                                </p>
                            </div>
                            <div class="images-box">
                                <picture>
                                    <img
                                        :src="imageSrc3"
                                        @click="triggerFileInput3"
                                        class="profile__img"
                                    />
                                </picture>
                                <svg
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 50 50"
                                    xml:space="preserve"
                                >
                                    <circle
                                        style="fill: #ffffff"
                                        cx="25"
                                        cy="25"
                                        r="25"
                                    />
                                    <line
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 10;
                                        "
                                        x1="25"
                                        y1="13"
                                        x2="25"
                                        y2="38"
                                    />
                                    <line
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 10;
                                        "
                                        x1="37.5"
                                        y1="25"
                                        x2="12.5"
                                        y2="25"
                                    />
                                </svg>
                                <p
                                    v-if="imageSrc3"
                                    @click="deletePhoto(3)"
                                    class="trash"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="100"
                                        height="100"
                                        viewBox="0 0 128 128"
                                    >
                                        <path
                                            d="M 49 1 C 47.34 1 46 2.34 46 4 C 46 5.66 47.34 7 49 7 L 79 7 C 80.66 7 82 5.66 82 4 C 82 2.34 80.66 1 79 1 L 49 1 z M 24 15 C 16.83 15 11 20.83 11 28 C 11 35.17 16.83 41 24 41 L 101 41 L 101 104 C 101 113.37 93.37 121 84 121 L 44 121 C 34.63 121 27 113.37 27 104 L 27 52 C 27 50.34 25.66 49 24 49 C 22.34 49 21 50.34 21 52 L 21 104 C 21 116.68 31.32 127 44 127 L 84 127 C 96.68 127 107 116.68 107 104 L 107 40.640625 C 112.72 39.280625 117 34.14 117 28 C 117 20.83 111.17 15 104 15 L 24 15 z M 24 21 L 104 21 C 107.86 21 111 24.14 111 28 C 111 31.86 107.86 35 104 35 L 24 35 C 20.14 35 17 31.86 17 28 C 17 24.14 20.14 21 24 21 z M 50 55 C 48.34 55 47 56.34 47 58 L 47 104 C 47 105.66 48.34 107 50 107 C 51.66 107 53 105.66 53 104 L 53 58 C 53 56.34 51.66 55 50 55 z M 78 55 C 76.34 55 75 56.34 75 58 L 75 104 C 75 105.66 76.34 107 78 107 C 79.66 107 81 105.66 81 104 L 81 58 C 81 56.34 79.66 55 78 55 z"
                                        ></path>
                                    </svg>
                                </p>
                            </div>
                            <div class="images-box">
                                <picture>
                                    <img
                                        :src="imageSrc4"
                                        @click="triggerFileInput4"
                                        class="profile__img"
                                    />
                                </picture>
                                <svg
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 50 50"
                                    xml:space="preserve"
                                >
                                    <circle
                                        style="fill: #ffffff"
                                        cx="25"
                                        cy="25"
                                        r="25"
                                    />
                                    <line
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 10;
                                        "
                                        x1="25"
                                        y1="13"
                                        x2="25"
                                        y2="38"
                                    />
                                    <line
                                        style="
                                            fill: none;
                                            stroke: #000000;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 10;
                                        "
                                        x1="37.5"
                                        y1="25"
                                        x2="12.5"
                                        y2="25"
                                    />
                                </svg>
                                <p
                                    v-if="imageSrc4"
                                    @click="deletePhoto(4)"
                                    class="trash"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="100"
                                        height="100"
                                        viewBox="0 0 128 128"
                                    >
                                        <path
                                            d="M 49 1 C 47.34 1 46 2.34 46 4 C 46 5.66 47.34 7 49 7 L 79 7 C 80.66 7 82 5.66 82 4 C 82 2.34 80.66 1 79 1 L 49 1 z M 24 15 C 16.83 15 11 20.83 11 28 C 11 35.17 16.83 41 24 41 L 101 41 L 101 104 C 101 113.37 93.37 121 84 121 L 44 121 C 34.63 121 27 113.37 27 104 L 27 52 C 27 50.34 25.66 49 24 49 C 22.34 49 21 50.34 21 52 L 21 104 C 21 116.68 31.32 127 44 127 L 84 127 C 96.68 127 107 116.68 107 104 L 107 40.640625 C 112.72 39.280625 117 34.14 117 28 C 117 20.83 111.17 15 104 15 L 24 15 z M 24 21 L 104 21 C 107.86 21 111 24.14 111 28 C 111 31.86 107.86 35 104 35 L 24 35 C 20.14 35 17 31.86 17 28 C 17 24.14 20.14 21 24 21 z M 50 55 C 48.34 55 47 56.34 47 58 L 47 104 C 47 105.66 48.34 107 50 107 C 51.66 107 53 105.66 53 104 L 53 58 C 53 56.34 51.66 55 50 55 z M 78 55 C 76.34 55 75 56.34 75 58 L 75 104 C 75 105.66 76.34 107 78 107 C 79.66 107 81 105.66 81 104 L 81 58 C 81 56.34 79.66 55 78 55 z"
                                        ></path>
                                    </svg>
                                </p>
                            </div>
                        </div>
                        <div class="personal-basic-info-profile__save-images">
                            <button
                                @click="saveImages()"
                                :class="{
                                    'personal-basic-info-profile__btn--disabled':
                                        !photosValidation
                                }"
                            >
                                {{ editImagesButtonText }}
                            </button>
                        </div>
                        <p class="personal-basic-info-profile__infoPopup">
                            {{ infoPopup }}
                        </p>
                        <input
                            class="profile__file"
                            type="file"
                            ref="fileInput1"
                            @change="onFileChange1"
                        />
                        <input
                            class="profile__file"
                            type="file"
                            ref="fileInput2"
                            @change="onFileChange2"
                        />
                        <input
                            class="profile__file"
                            type="file"
                            ref="fileInput3"
                            @change="onFileChange3"
                        />
                        <input
                            class="profile__file"
                            type="file"
                            ref="fileInput4"
                            @change="onFileChange4"
                        />
                    </div>
                </div>
                <div class="personal-basic-info-profile__user-top-person">
                    <h1
                        class="personal-basic-info-profile__user-top-person-name"
                    >
                        {{ name }}, {{ setAge(age) }}
                    </h1>
                    <div
                        class="personal-basic-info-profile__user-top-person-data"
                    >
                        <div
                            class="personal-basic-info-profile__user-top-person-data-city"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="15.004"
                                height="20.372"
                                viewBox="0 0 15.004 20.372"
                            >
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect
                                            id="Rectangle_226"
                                            data-name="Rectangle 226"
                                            width="15.004"
                                            height="20.372"
                                            transform="translate(-1 -1)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                    </clipPath>
                                </defs>
                                <g
                                    id="Group_341"
                                    data-name="Group 341"
                                    transform="translate(1 1)"
                                >
                                    <g
                                        id="Group_340"
                                        data-name="Group 340"
                                        transform="translate(0 0)"
                                        clip-path="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_325"
                                            data-name="Path 325"
                                            d="M6.5,18.372l-.237-.281A42.963,42.963,0,0,1,.62,9.224,6.351,6.351,0,0,1,0,6.463a6.5,6.5,0,0,1,13,0,6.375,6.375,0,0,1-.634,2.771,43.1,43.1,0,0,1-5.631,8.858ZM6.5.619A5.87,5.87,0,0,0,.619,6.463a5.749,5.749,0,0,0,.564,2.5A41.552,41.552,0,0,0,6.5,17.4a41.685,41.685,0,0,0,5.3-8.427,5.77,5.77,0,0,0,.578-2.513A5.87,5.87,0,0,0,6.5.619"
                                            transform="translate(0 0)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_326"
                                            data-name="Path 326"
                                            d="M13.406,16.448a3.406,3.406,0,1,1,3.406-3.406,3.41,3.41,0,0,1-3.406,3.406m0-6.193a2.787,2.787,0,1,0,2.787,2.787,2.79,2.79,0,0,0-2.787-2.787"
                                            transform="translate(-6.904 -6.653)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <p>{{ city }}</p>
                        </div>
                        <div
                            class="personal-basic-info-profile__user-top-person-data-gender"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19.705"
                                height="20.062"
                                viewBox="0 0 19.705 20.062"
                            >
                                <g
                                    id="Group_349"
                                    data-name="Group 349"
                                    transform="translate(-110.047 -753.468)"
                                >
                                    <g
                                        id="Group_24"
                                        data-name="Group 24"
                                        transform="translate(110.047 756.945)"
                                    >
                                        <g
                                            id="Ellipse_5"
                                            data-name="Ellipse 5"
                                            transform="translate(0 0)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-width="1"
                                        >
                                            <ellipse
                                                cx="5.407"
                                                cy="5.407"
                                                rx="5.407"
                                                ry="5.407"
                                                stroke="none"
                                            />
                                            <ellipse
                                                cx="5.407"
                                                cy="5.407"
                                                rx="4.907"
                                                ry="4.907"
                                                fill="none"
                                            />
                                        </g>
                                        <line
                                            id="Line_23"
                                            data-name="Line 23"
                                            y1="5.677"
                                            transform="translate(5.542 10.408)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                        <line
                                            id="Line_24"
                                            data-name="Line 24"
                                            x2="4.055"
                                            transform="translate(3.379 13.382)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                    </g>
                                    <g
                                        id="Group_26"
                                        data-name="Group 26"
                                        transform="translate(115.711 753.969)"
                                    >
                                        <g
                                            id="Ellipse_5-2"
                                            data-name="Ellipse 5"
                                            transform="translate(0.001 2.368)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-width="1"
                                        >
                                            <ellipse
                                                cx="5.407"
                                                cy="5.407"
                                                rx="5.407"
                                                ry="5.407"
                                                stroke="none"
                                            />
                                            <ellipse
                                                cx="5.407"
                                                cy="5.407"
                                                rx="4.907"
                                                ry="4.907"
                                                fill="none"
                                            />
                                        </g>
                                        <line
                                            id="Line_23-2"
                                            data-name="Line 23"
                                            x1="4.055"
                                            y2="4.055"
                                            transform="translate(9.058 0.307)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                        <path
                                            id="Path_50"
                                            data-name="Path 50"
                                            d="M822.272,6398.362h3.463v3.1"
                                            transform="translate(-812.194 -6398.363)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <p>{{ setSex(sex) }}</p>
                        </div>
                        <div
                            v-if="growth"
                            class="personal-basic-info-profile__user-top-person-data-height"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="21.097"
                                height="26.128"
                                viewBox="0 0 21.097 26.128"
                            >
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect
                                            id="Rectangle_227"
                                            data-name="Rectangle 227"
                                            width="5.532"
                                            height="28.277"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                    </clipPath>
                                </defs>
                                <g
                                    id="Group_343"
                                    data-name="Group 343"
                                    transform="matrix(0.809, 0.588, -0.588, 0.809, 16.621, 0)"
                                >
                                    <g
                                        id="Group_342"
                                        data-name="Group 342"
                                        clip-path="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_327"
                                            data-name="Path 327"
                                            d="M27.063,180.972H25.651a.217.217,0,1,1,0-.435h1.412a.217.217,0,1,1,0,.435"
                                            transform="translate(-21.748 -154.375)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_328"
                                            data-name="Path 328"
                                            d="M20.355,165.352H17.806a.217.217,0,0,1,0-.435h2.549a.217.217,0,0,1,0,.435"
                                            transform="translate(-15.04 -141.019)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_329"
                                            data-name="Path 329"
                                            d="M27.063,149.731H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,0,1,0,.435"
                                            transform="translate(-21.748 -127.661)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_330"
                                            data-name="Path 330"
                                            d="M20.355,134.111H17.806a.217.217,0,0,1,0-.435h2.549a.217.217,0,0,1,0,.435"
                                            transform="translate(-15.04 -114.305)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_331"
                                            data-name="Path 331"
                                            d="M5.532,28.277H0V0H5.532Zm-5.1-.435H5.1V.435H.435Z"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_332"
                                            data-name="Path 332"
                                            d="M27.063,118.49H25.651a.217.217,0,1,1,0-.435h1.412a.217.217,0,1,1,0,.435"
                                            transform="translate(-21.748 -100.947)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_333"
                                            data-name="Path 333"
                                            d="M20.355,102.869H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435"
                                            transform="translate(-15.04 -87.59)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_334"
                                            data-name="Path 334"
                                            d="M27.063,87.249H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,0,1,0,.435"
                                            transform="translate(-21.748 -74.234)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_335"
                                            data-name="Path 335"
                                            d="M20.355,71.628H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435"
                                            transform="translate(-15.04 -60.876)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_338"
                                            data-name="Path 338"
                                            d="M20.355,71.628H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435"
                                            transform="translate(-14.764 -66.022)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_336"
                                            data-name="Path 336"
                                            d="M27.063,56.008H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,1,1,0,.435"
                                            transform="translate(-21.748 -47.52)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_337"
                                            data-name="Path 337"
                                            d="M27.063,56.008H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,1,1,0,.435"
                                            transform="translate(-21.472 -52.665)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <p>{{ setGrowth(growth) }}</p>
                        </div>
                        <!-- <div class="personal-basic-info-profile__user-top-person-data-children">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="21" viewBox="0 0 22 21">
                                <defs>
                                    <clipPath id="clip-path">
                                    <rect id="Rectangle_229" data-name="Rectangle 229" width="22" height="21" transform="translate(0 -0.067)" fill="#d13724"/>
                                    </clipPath>
                                </defs>
                                <g id="Group_347" data-name="Group 347" transform="translate(0 0.067)">
                                    <g id="Group_346" data-name="Group 346" clip-path="url(#clip-path)">
                                    <path id="Path_359" data-name="Path 359" d="M10.8,123.469a8.433,8.433,0,0,1-8.1-6.077H2.7a2.7,2.7,0,0,1,0-5.4h.462a.338.338,0,0,1,0,.675H2.7a2.026,2.026,0,1,0,0,4.051h.264a.338.338,0,0,1,.327.253,7.758,7.758,0,0,0,15.027,0,.338.338,0,0,1,.327-.253h.262a2.026,2.026,0,0,0,.006-4.051l-.448.018a.338.338,0,1,1-.027-.675l.455-.018h.013a2.7,2.7,0,0,1,0,5.4h0a8.434,8.434,0,0,1-8.1,6.077" transform="translate(0 -102.536)" fill="#d13724"/>
                                    <path id="Path_360" data-name="Path 360" d="M161.652,60.758a.338.338,0,0,1-.335-.3,7.817,7.817,0,0,0-5.119-6.353.338.338,0,0,1,.225-.637,8.5,8.5,0,0,1,5.564,6.906.338.338,0,0,1-.293.377l-.042,0" transform="translate(-142.808 -48.942)" fill="#d13724"/>
                                    <path id="Path_361" data-name="Path 361" d="M88.675,133.35a.675.675,0,1,1,.675-.675.676.676,0,0,1-.675.675m0-.675h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z" transform="translate(-80.572 -120.858)" fill="#d13724"/>
                                    <path id="Path_362" data-name="Path 362" d="M152.675,133.35a.675.675,0,1,1,.675-.675.676.676,0,0,1-.675.675m0-.675h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z" transform="translate(-139.17 -120.858)" fill="#d13724"/>
                                    <path id="Path_363" data-name="Path 363" d="M102.436,189.28a4.46,4.46,0,0,1-2.286-.662.338.338,0,0,1,.374-.562,3.441,3.441,0,0,0,3.678,0,.338.338,0,0,1,.375.562,3.8,3.8,0,0,1-2.14.662" transform="translate(-91.559 -172.131)" fill="#d13724"/>
                                    <path id="Path_364" data-name="Path 364" d="M29.081,11.817l-.042,0a.338.338,0,0,1-.293-.377A8.408,8.408,0,0,1,36.115,4.12a2.728,2.728,0,0,0,1.269-.488A2.03,2.03,0,0,0,38.44,1.965,1.289,1.289,0,0,0,37.157.675h0a.928.928,0,0,0-.671.308.921.921,0,0,0-.276.785.7.7,0,0,0,.383.5.338.338,0,0,1-.294.608,1.381,1.381,0,0,1-.752-.977,1.576,1.576,0,0,1,.44-1.368A1.592,1.592,0,0,1,37.157,0h0a1.96,1.96,0,0,1,1.958,1.953A2.672,2.672,0,0,1,37.777,4.18a3.406,3.406,0,0,1-1.583.61,7.734,7.734,0,0,0-6.779,6.731.338.338,0,0,1-.335.3" transform="translate(-26.317)" fill="#d13724"/>
                                    </g>
                                </g>
                            </svg>
                            <p>{{ (children === 'Tak' || children == 1 || children == '1') ? 'Mam dzieci' : 'Nie mam dzieci' }}</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                currentPhotoIndex: 0,
                lightboxVisible: false,
                hideGalleryOnMobile: false,
                userPhotos: [],
                selectedFile1: '',
                selectedFile2: '',
                selectedFile3: '',
                selectedFile4: '',
                editImagesButtonText: 'Zapisz',
                imageSrc1: require('@/assets/images/ludzik1.png'),
                defaultImageSrc1: require('@/assets/images/ludzik1.png'),
                imageSrc1Filled: null,
                imageSrc2: require('@/assets/images/ludzik2.png'),
                imageSrc2Filled: null,
                imageSrc3: require('@/assets/images/blank.png'),
                imageSrc4: require('@/assets/images/blank.png'),
                showEditPop: false,
                infoPopup: '',
                photosValidation: true
            }
        },
        props: {
            images: Array,
            name: String,
            age: String,
            city: String,
            sex: String,
            growth: String,
            children: String
        },
        mounted() {
            this.checkWidthForGallery()
            document.addEventListener('keyup', this.handleEscape)
            if (this.images) {
                setTimeout(() => {
                    this.userPhotos = [
                        this.images[0],
                        this.images[1],
                        this.images[2],
                        this.images[3]
                    ].filter(Boolean)
                    if (this.userPhotos.length > 0) {
                        this.currentPhoto = this.userPhotos[0]
                    }
                }, 300)
            }
            this.getProfile()
        },
        beforeDestroy() {
            document.removeEventListener('keyup', this.handleEscape)
        },
        methods: {
            checkWidthForGallery() {
                if (window.innerWidth <= 800) this.hideGalleryOnMobile = true
                window.addEventListener('resize', () => {
                    if (window.innerWidth <= 800)
                        this.hideGalleryOnMobile = true
                })
            },
            isLinkActive(path) {
                return this.$route.path.startsWith(path)
            },
            closeEditPop() {
                this.showEditPop = false
            },
            editImages() {
                this.showEditPop = true
            },
            triggerFileInput1() {
                this.$refs.fileInput1.click()
            },
            triggerFileInput2() {
                this.$refs.fileInput2.click()
            },
            triggerFileInput3() {
                this.$refs.fileInput3.click()
            },
            triggerFileInput4() {
                this.$refs.fileInput4.click()
            },
            checkImagesFormat(imageType) {
                if (imageType == 'image/png' || imageType == 'image/jpeg') {
                    return true
                } else {
                    this.infoPopup = 'Poprawny format zdjęcia to .jpg lub .png'
                    return false
                }
            },
            onFileChange1(event) {
                this.validateFileSize(event.target.files[0])

                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                if (
                    event.target.files.length > 0 &&
                    this.validateFileSize(event.target.files[0])
                ) {
                    this.selectedFile1 = event.target.files[0]
                    this.imageSrc1 = URL.createObjectURL(this.selectedFile1)
                } else {
                    // Resetuj do domyślnego obrazu, jeśli nie wybrano żadnego pliku
                    this.imageSrc1 = require('@/assets/images/ludzik1.png')
                    this.selectedFile1 = '' // Dodatkowo resetuj stan selectedFile1
                }
            },
            onFileChange2(event) {
                this.validateFileSize(event.target.files[0])

                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                // Analogicznie dla pozostałych metod onFileChange
                if (
                    event.target.files.length > 0 &&
                    this.validateFileSize(event.target.files[0])
                ) {
                    // console.log('File 2 changed:', event.target.files[0]);
                    this.selectedFile2 = event.target.files[0]
                    this.imageSrc2 = URL.createObjectURL(this.selectedFile2)
                } else {
                    this.imageSrc2 = require('@/assets/images/ludzik2.png')
                    this.selectedFile2 = ''
                }
            },
            onFileChange3(event) {
                this.validateFileSize(event.target.files[0])
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                // I tak dalej dla onFileChange3 i onFileChange4
                if (
                    event.target.files.length > 0 &&
                    this.validateFileSize(event.target.files[0])
                ) {
                    // console.log('File 3 changed:', event.target.files[0]);
                    this.selectedFile3 = event.target.files[0]
                    this.imageSrc3 = URL.createObjectURL(this.selectedFile3)
                } else {
                    this.imageSrc3 = require('@/assets/images/ludzik1.png')
                    this.selectedFile3 = ''
                }
            },
            onFileChange4(event) {
                this.validateFileSize(event.target.files[0])
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                if (
                    event.target.files.length > 0 &&
                    this.validateFileSize(event.target.files[0])
                ) {
                    // console.log('File 4 changed:', event.target.files[0]);
                    this.selectedFile4 = event.target.files[0]
                    this.imageSrc4 = URL.createObjectURL(this.selectedFile4)
                } else {
                    this.imageSrc4 = require('@/assets/images/ludzik2.png')
                    this.selectedFile4 = ''
                }
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            validateFileSize(file) {
                const maxSize = 1024 * 1024 * 1
                if (file.size > maxSize) {
                    this.infoPopup =
                        'Zdjęcie jest za duże. Maksymalny rozmiar zdjęcia to 1MB.'
                    this.photosValidation = false
                    return false
                } else {
                    this.infoPopup = ''
                    this.photosValidation = true
                    return true
                }
            },
            saveImages() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const formData = new FormData()

                const userId = localStorage.getItem('userId')

                if (userId) {
                    formData.append('userid', userId)
                }

                if (this.selectedFile1) {
                    formData.append('file1', this.selectedFile1)
                }
                if (this.selectedFile2) {
                    formData.append('file2', this.selectedFile2)
                }
                if (this.selectedFile3) {
                    formData.append('file3', this.selectedFile3)
                }
                if (this.selectedFile4) {
                    formData.append('file4', this.selectedFile4)
                }

                axios
                    .post(`${this.url}api/edit-user/photos`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${authToken}`
                        }
                    })
                    .then(response => {
                        this.editImagesButtonText = 'Zapisano!'
                        setTimeout(() => {
                            //   window.location.reload()
                        }, 2000)
                        // console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            async deletePhoto(photoIndex) {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                this.infoPopup = ''
                const userId = this.user.user._id
                try {
                    const response = await axios.delete(
                        `${this.url}api/edit-user/photosdel/${photoIndex}/${userId}`,
                        config
                    )

                    switch (photoIndex) {
                        case 2:
                            this.imageSrc2 = require('@/assets/images/ludzik2.png')
                            break
                        case 3:
                            this.imageSrc3 = require('@/assets/images/blank.png')
                            break
                        case 4:
                            this.imageSrc4 = require('@/assets/images/blank.png')
                            break
                    }

                    this.infoPopup = 'Zdjęcie usunięte pomyślnie.'
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                } catch (error) {
                    console.error(error.response.data)
                    this.infoPopup = 'Wystąpił problem.'
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                }
            },
            getProfile() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                axios
                    .get(
                        `${this.url}api/edit-user/` +
                            localStorage.getItem('userId'),
                        config
                    )
                    .then(response => {
                        this.user = response.data

                        if (response.data.user.photo1) {
                            this.imageSrc1 = response.data.user.photo1
                        }
                        if (response.data.user.photo2) {
                            this.imageSrc2 = response.data.user.photo2
                        }
                        if (response.data.user.photo3) {
                            this.imageSrc3 = response.data.user.photo3
                        }
                        if (response.data.user.photo4) {
                            this.imageSrc4 = response.data.user.photo4
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            handleEscape(event) {
                if (event.key === 'Escape') {
                    this.lightboxVisible = false
                }
            },
            setAge(el) {
                const dzisiaj = new Date()
                const dataUrodzeniaDate = new Date(el)

                let wiek =
                    dzisiaj.getFullYear() - dataUrodzeniaDate.getFullYear()

                const miesiacDzisiaj = dzisiaj.getMonth()
                const dzienDzisiaj = dzisiaj.getDate()
                const miesiacUrodzenia = dataUrodzeniaDate.getMonth()
                const dzienUrodzenia = dataUrodzeniaDate.getDate()

                if (
                    miesiacDzisiaj < miesiacUrodzenia ||
                    (miesiacDzisiaj === miesiacUrodzenia &&
                        dzienDzisiaj < dzienUrodzenia)
                ) {
                    wiek--
                }

                return wiek
            },
            setGrowth(el) {
                let metric
                if (el) {
                    el.length >= 3 && !el.includes('.')
                        ? (metric = 'cm')
                        : (metric = 'm')
                    return `${el} ${metric}`
                }
            },
            setSex(el) {
                switch (el) {
                    case '0':
                        return 'Kobieta'
                    case '1':
                        return 'Mężczyzna'
                    case '2':
                        return 'Osoba Niebinarna'
                    default:
                        return el
                }
            },
            //
            navigatePhoto(direction) {
                this.currentPhotoIndex += direction
                if (this.currentPhotoIndex < 0) {
                    this.currentPhotoIndex = this.userPhotos.length - 1
                } else if (this.currentPhotoIndex >= this.userPhotos.length) {
                    this.currentPhotoIndex = 0
                }
            },
            openLightbox(index) {
                this.currentPhotoIndex = index
                this.lightboxVisible = true
            },
            handleKeydown(e) {
                if (!this.lightboxVisible) return
                if (e.key === 'ArrowLeft') {
                    this.navigatePhoto(-1)
                } else if (e.key === 'ArrowRight') {
                    this.navigatePhoto(1)
                }
            },
            handleKeydownExit(e) {
                if (!this.lightboxVisible) return
                if (e.key == 'Escape') {
                    this.lightboxVisible = false
                }
            }
        },
        computed: {
            currentPhoto() {
                return this.userPhotos[this.currentPhotoIndex]
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .profile {
        &__img {
            &--border {
                border: 2px solid $pink;
            }
            &--unactive {
                cursor: auto;
            }
        }
        &__flex-images {
            display: flex;
            gap: 10px;
            width: 100%;
            margin-top: 20px;
            justify-content: center;
            @include breakpoint-max('xs-tablet') {
                margin-bottom: 15px;
                padding-left: 30px;
            }
            div {
                width: 86px;
                cursor: pointer !important;
                img {
                    border-width: 1px !important;
                    @include breakpoint-max('xs-tablet') {
                        width: 100%;
                    }
                }
                @include breakpoint-max('xs-tablet') {
                    width: 100%;
                }
            }
            @include breakpoint-max('xs-tablet') {
                margin-top: 10px;
            }
        }
    }

    .personal-basic-info-profile {
        .person-mobile-name {
            display: none;
            @include breakpoint-max('xs-tablet') {
                display: block;
            }
        }
        &__user-top-person {
            @include breakpoint-max('xs-tablet') {
                display: none;
            }
        }
        &__user-top-wrapper {
            display: flex;
            @include breakpoint-max('xs-tablet') {
                flex-direction: column;
            }
        }
        &__save-images {
            text-align: center;
            button {
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                padding: 15px 0;
                width: 200px;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                }
            }
        }
        &__infoPopup {
            text-align: center;
            color: $pink;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 15px;
        }
        &__pop-close {
            position: absolute;
            right: 10px;
            top: 10px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: $white;
        }
        &__btn--disabled {
            pointer-events: none;
            opacity: 0.3;
        }
        &__edit-images-popup {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70vw;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            z-index: 99;
            .save-profile__photos-flex {
                padding: 20px;
                @include breakpoint-max('xs-tablet') {
                    padding: 15px;
                    gap: 15px 15px;
                }
                @include breakpoint-max('s-mobile') {
                    padding: 8px;
                    gap: 8px 8px;
                }
            }
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
            @include breakpoint-max('xs-tablet') {
                width: 95vw;
            }
        }
        .no-image {
            margin-top: 30px;
        }
        &__user-top-person-name {
            padding: 8px 36px 8px 59px;
            background-color: $pink;
            color: $white;
            font-size: 3rem;
            line-height: 4.5rem;
            font-weight: 700;
            text-transform: capitalize;
            font-family: 'Open Sans', sans-serif;
            box-shadow: 0px 3px 6px #00000029;
            margin-bottom: 41px;
            @include breakpoint-max('xs-tablet') {
                padding: 6px 20px;
            }
            @include breakpoint-max('mobile') {
                padding: 4px 10px;
                font-size: 2rem;
            }
        }
        &__user-top-person-data {
            padding-left: 51px;
            div {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 26px;
                    @include breakpoint-max('mobile') {
                        margin-right: 15px;
                        max-width: 15px;
                    }
                }
                p {
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-family: 'Open Sans', sans-serif;
                }
                @include breakpoint-max('mobile') {
                    margin-bottom: 0px;
                }
                @include breakpoint-max('xs-tablet') {
                    width: fit-content;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-items: center;
                row-gap: 23px;
            }
        }
        &__user-top-person-data-height {
            p {
                text-transform: lowercase !important;
            }
        }
        &__user-top-person-data-children {
            p {
                text-transform: none !important;
            }
        }
        &__edit-images {
            text-align: center;
            background-color: $pink;
            padding: 10px;
            color: $white;
            font-weight: bold;
            cursor: pointer;
            @include breakpoint-max('xs-tablet') {
                display: none;
            }
        }
    }
    .gallery {
        &__thumbnails {
            figure {
                display: block;
                img {
                    cursor: pointer;
                    display: block;
                    height: auto;
                    width: 280px;
                    height: 280px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    object-position: center;
                    border-radius: 0 !important;
                    border: 1px solid $black;
                    @include breakpoint-max('xs-tablet') {
                        width: 100%;
                        height: auto;
                    }
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                }
                @include breakpoint-max('xs-tablet') {
                    margin-bottom: 26px;
                    margin-bottom: 0 !important;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
            }
        }
    }
    .lightbox {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            max-width: 80vw; /* 80% szerokości widoku */
            max-height: 80vh; /* 80% wysokości widoku */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            max-width: 95vw;
            max-height: 95vh;
            object-fit: contain !important;
        }
    }
    .lightbox__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .lightbox__nav {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
    .lightbox__nav--left {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }
    .lightbox__nav--right {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }
    .lightbox__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
</style>
