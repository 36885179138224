<template>
    <!-- <transition name="fade" mode="out-in"> -->
    <div>
        <Header />
        <div class="sidebar">
            <header class="admin-users-view__header">
                <div class="admin-users-view__header-logo">
                    <p>Biuro</p>
                </div>
            </header>
            <aside class="admin-users-view__sidebar">
                <ul class="admin-users-view__sidebar-pages">
                    <li
                        :class="{
                            'active-link': isLinkActive(
                                '/admin/matches/simulated-matches/'
                            )
                        }"
                    >
                        <RouterLink to="/admin/matches/simulated-matches/1">
                            Symulacja dopasowań
                        </RouterLink>
                    </li>
                    <li
                        :class="{
                            'active-link': isLinkActive('/admin/verify/')
                        }"
                    >
                        <RouterLink to="/admin/verify/1">
                            Do weryfikacji
                        </RouterLink>
                    </li>
                    <li
                        @click="showSubmenu('basic')"
                        :class="[
                            { 'active-link-sub': isLinkActive('/admin/basic') },
                            'li-with-child'
                        ]"
                    >
                        <p>Pakiet basic</p>

                        <ul
                            :class="[
                                { 'sub-menu-opened': subMenuForBasicOpened },
                                'sub-menu'
                            ]"
                        >
                            <div class="sub-menu-wrapper">
                                <li
                                    v-for="city in cities"
                                    ref="subMenuLi"
                                    :class="{
                                        'active-link-sub-menu': isLinkActive(
                                            `/admin/basic/${city.url}/`
                                        )
                                    }"
                                >
                                    <Router-link
                                        :to="'/admin/basic/' + city.url + '/1'"
                                    >
                                        {{ city.city }}
                                    </Router-link>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <li
                        @click="showSubMenu"
                        :class="[
                            { 'active-link-sub': isLinkActive('/admin/plus') },
                            'li-with-child'
                        ]"
                    >
                        <p>Pakiet plus</p>
                        <ul
                            :class="[
                                { 'sub-menu-opened': subMenuOpened },
                                'sub-menu'
                            ]"
                        >
                            <div class="sub-menu-wrapper">
                                <li
                                    v-for="city in cities"
                                    ref="subMenuLi"
                                    :class="{
                                        'active-link-sub-menu': isLinkActive(
                                            `/admin/plus/${city.url}/`
                                        )
                                    }"
                                >
                                    <Router-link
                                        :to="'/admin/plus/' + city.url + '/1'"
                                    >
                                        {{ city.city }}
                                    </Router-link>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <!-- @click="showSubMenu2" -->
                    <li
                        :class="[
                            { 'active-link-sub': isLinkActive('/admin/users') },
                            'li-with-child'
                        ]"
                    >
                        <p>Wszyscy użytkownicy</p>
                        <ul
                            :class="[
                                { 'sub-menu-opened': !subMenuOpened2 },
                                'sub-menu'
                            ]"
                        >
                            <div class="sub-menu-wrapper">
                                <li
                                    v-for="city in cities"
                                    ref="subMenuLi"
                                    :class="{
                                        'active-link-sub-menu': isLinkActive(
                                            `/admin/users/${city.url}/`
                                        )
                                    }"
                                >
                                    <Router-link
                                        :to="'/admin/users/' + city.url + '/1'"
                                    >
                                        {{ city.city }}
                                    </Router-link>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <li :class="{ 'active-link': isLinkActive('/admin/info') }">
                        <RouterLink to="/admin/info">Komunikaty</RouterLink>
                    </li>
                    <!-- <li
                        :class="{
                            'active-link': isLinkActive(
                                '/admin/premium-accounts'
                            )
                        }"
                    >
                        <RouterLink to="/admin/premium-accounts">
                            Premium
                        </RouterLink>
                    </li> -->
                    <li
                        :class="{
                            'active-link': isLinkActive('/admin/tools')
                        }"
                    >
                        <RouterLink to="/admin/tools">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-settings"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path
                                    d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
                                />
                                <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                            </svg>
                            <div>Narzędzia</div>
                        </RouterLink>
                    </li>
                </ul>
            </aside>
        </div>
        <div class="wrapper">
            <router-view />
        </div>
    </div>
    <!-- </transition> -->
</template>

<script>
    import axios from 'axios'
    import Header from '@/components/landing-03/HeaderComponent.vue'

    export default {
        data() {
            return {
                cities: [
                    { city: 'Warszawa', url: 'Warszawa' },
                    { city: 'Poznań', url: 'Poznań' },
                    { city: 'Wrocław', url: 'Wrocław' },
                    { city: 'Kraków', url: 'Kraków' },
                    { city: 'Łódź', url: 'Łódź' },
                    { city: 'Rzeszów', url: 'Rzeszów' },
                    { city: 'Gdańsk/Gdynia/Sopot', url: 'ggs' },
                    { city: 'Katowice', url: 'Katowice' },
                    { city: 'Szczecin', url: 'Szczecin' },
                    { city: 'Bydgoszcz', url: 'Bydgoszcz' },
                    { city: 'Lublin', url: 'Lublin' }
                ],
                subMenuOpened: false,
                subMenuForBasicOpened: false,
                subMenuOpened2: false
            }
        },
        watch: {
            '$route.path'(value) {
                const plusPath = value?.includes('plus')
                const basicPath = value?.includes('basic')
                plusPath
                    ? (this.subMenuOpened = true)
                    : (this.subMenuOpened = false)
                basicPath
                    ? (this.subMenuForBasicOpened = true)
                    : (this.subMenuForBasicOpened = false)
            }
        },
        components: {
            Header
        },
        methods: {
            showSubmenu(name) {
                switch (name) {
                    case 'basic':
                        this.subMenuForBasicOpened
                            ? (this.subMenuForBasicOpened = false)
                            : (this.subMenuForBasicOpened = true)
                        break
                    case 'plus':
                        this.subMenuOpened
                            ? (this.subMenuOpened = false)
                            : (this.subMenuOpened = true)
                        break
                }

                //close others submenus
            },
            showSubMenu() {
                this.subMenuOpened
                    ? (this.subMenuOpened = false)
                    : (this.subMenuOpened = true)
            },
            showSubMenu2() {
                this.subMenuOpened2
                    ? (this.subMenuOpened2 = false)
                    : (this.subMenuOpened2 = true)
            },
            async logout() {
                try {
                    await axios.post(`${this.url}api/logout`)

                    this.deleteAuthCookie()

                    this.$store.commit('setAuth', false)

                    this.$router.push('/login')
                } catch (error) {
                    console.error(error)
                }
            },
            isLinkActive(path) {
                return this.$route.path.startsWith(path)
            },
            deleteAuthCookie() {
                document.cookie =
                    'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
            }
        }
    }
</script>

<style lang="scss">
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    ul {
        .active-link-sub {
            p {
                display: block;
                color: $white !important;
                background-color: $pink !important;
                &::selection {
                    background-color: transparent;
                }
            }
            @include breakpoint-max('xs-talbet') {
                border-radius: 10px !important;
            }
        }
        .active-link-sub-menu {
            overflow: hidden;
            a {
                background-color: $pink !important;
                color: $white !important;
                &::selection {
                    background-color: transparent;
                }
            }
        }
    }
    .active-link {
        background-color: $pink !important;
        a {
            color: $white !important;
            &::selection {
                background-color: transparent;
            }
            // @include breakpoint-max('xs-tablet') {
            // }
        }
        // @include breakpoint-max('xs-tablet') {
        // }
    }

    nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .admin-users-view {
        &__logout {
            padding: 12px 25px;
            cursor: pointer;
            border-radius: 10px;
            background-color: #f2545b;
            color: #fff;
        }
        &__header {
            height: 104px;
            background-color: $white;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            padding-left: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include breakpoint-max('xs-tablet') {
                height: 50px;
            }
        }
        &__header-logo {
            display: flex;
            align-items: center;
            svg {
                margin-right: 14px;
            }
            p {
                font-family: 'NunitoExtraBold';
                font-weight: 800;
                font-size: 2rem;
                line-height: 3.7rem;
                color: $black;
            }
        }
        &__btn {
            background-color: #0fb70d;
            color: #fff;
            text-decoration: none;
            padding: 15px 20px;
            border-radius: 5px;
            margin-top: 30px;
            display: inline-block;
            margin-bottom: 20px;
            &--red {
                background-color: #f2545b;
                margin-right: 10px;
            }
        }
        &__header-notification {
            cursor: pointer;
            width: 53px;
            height: 53px;
            background: $gray;
            border-radius: 15px;
            display: grid;
            place-items: center;
            position: relative;
            transition: 0.3s;
            &:hover {
                svg {
                    animation: myAnim 2s ease 0s 1 normal forwards;
                }
            }
        }
        &__header-notification-circle {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 12px;
            height: 12px;
            background-color: #f2545b;
            border-radius: 50%;
        }
        &__wrapper {
            display: flex;
        }
        &__sidebar {
            overflow-y: scroll;
            max-width: 376px;
            width: 100%;
            position: fixed;
            height: 100vh;
            top: 0;
            background: $gray;
            border-radius: 0 15px 15px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-top: 177px;
            padding-bottom: 150px;
            p {
                background-color: #e1dbd5 !important;
                border-radius: 0px 10px 10px 0px;
                &::selection {
                    background-color: transparent;
                }
            }
            .li-with-child {
                background-color: #f6f4f0;
                box-shadow: none;
                &::selection {
                    background-color: transparent;
                }
            }
            .sub-menu {
                overflow: hidden;
                max-height: 0;
                transition: 0.3s ease-out;
                list-style: none;
                margin-left: 64px;
                li {
                    margin-bottom: 14px;
                    a {
                        padding-left: 40px;
                        @include breakpoint-max('v-large') {
                            padding-left: 10px;
                        }
                        @include breakpoint-max('xs-tablet') {
                            background-color: $white;
                            border-radius: 10px;
                        }
                    }
                    &:first-child {
                        margin-top: 14px;
                        @include breakpoint-max('xs-tablet') {
                            margin-top: 0px;
                        }
                    }
                    @include breakpoint-max('xs-tablet') {
                        margin-bottom: 0;
                    }
                }
                .sub-menu-wrapper {
                    @include breakpoint-max('xs-tablet') {
                        padding: 50px;
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        gap: 15px;
                        background-color: #e1dbd5;
                    }
                    @include breakpoint-max('mobile') {
                        grid-template-columns: repeat(2, 1fr);
                        padding: 25px;
                    }
                }
                @include breakpoint-max('v-large') {
                    margin-left: 20px;
                }
                @include breakpoint-max('xs-tablet') {
                    margin-left: 0px;
                    position: fixed;
                    top: 150px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 50;
                    border-radius: 10px;
                }
            }
            .sub-menu-opened {
                max-height: 850px;
            }
            @include breakpoint-max('v-large') {
                max-width: 230px;
            }
            @include breakpoint-max('xl-tablet') {
                max-width: 240px;
            }
            @include breakpoint-max('xs-tablet') {
                position: static;
                max-width: none;
                padding-top: 80px;
                padding-bottom: 0;
                height: auto;
            }
            &::-webkit-scrollbar {
                width: 0px;
            }
        }
        &__sidebar-pages {
            width: 100%;
            display: flex;
            flex-direction: column;
            list-style: none;
            row-gap: 14px;
            li {
                background-color: $bege3;
                border-radius: 0px 10px 10px 0px;
                box-shadow: 0px 3px 6px #00000029;

                &::selection {
                    background-color: transparent;
                }
                a,
                p {
                    cursor: pointer;
                    text-decoration: none;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black1;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding-left: 104px;
                    height: 57px;
                    background: transparent;
                    @include breakpoint-max('v-large') {
                        padding-left: 30px;
                    }
                    @include breakpoint-max('xs-tablet') {
                        padding: 0;
                        width: 100%;
                        height: auto;
                        padding: 10px;
                        justify-content: center;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                svg {
                    margin-right: 13px;
                }
                @include breakpoint-max('xs-tablet') {
                    background-color: transparent;
                    border-radius: 10px;
                }
            }
            @include breakpoint-max('xs-tablet') {
                flex-direction: row;
                justify-content: center;
                gap: 20px;
                flex-wrap: wrap;
                margin-bottom: 20px;
                padding: 0 30px;
            }
            .li-with-child {
                @include breakpoint-max('xs-tablet') {
                    border-radius: 10px !important;
                    overflow: hidden;
                }
            }
        }
        &__sidebar-nav {
            position: absolute;
            left: -19px;
            top: 0px;
            width: 4px;
            height: 38px;
            background-color: #f2545b;
            box-shadow: -3px 3px 6px rgba(242, 84, 91, 0.35);
            border-radius: 500px;
            transition: 0.3s;
        }
        &__sidebar-logout {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__sidebar-line {
            margin-bottom: 34px !important;
            max-width: 239px;
            margin: 0 auto;
            width: 100%;
            height: 1px;
            background-color: $bege;
        }
        &__sidebar-logout-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;
            p {
                font-family: 'NunitoMedium';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.5rem;
                color: $black1;
            }
            &:hover {
                .arrow {
                    left: 12px;
                }
            }
        }
        &__sidebar-logout-svg {
            position: relative;
            width: 17px;
            height: 19px;
            margin-right: 15px;
            .left {
                position: absolute;
                top: 0px;
                left: 0px;
            }
            .arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 6px;
                transition: 0.3s;
            }
        }
        &__content {
            width: 100%;
            height: calc(100vh - 104px);
            // background-image: url(http://localhost:8080/img/light-bcg-big.93d5f768.png);
            input {
                padding-left: 93px;
                width: 270px;
                padding: 15px;
            }
        }
        &__content-users {
            padding-top: 49px;
            padding-left: 93px;
            padding-top: 13px;
            max-width: 1294px;
            width: 100%;
        }
        &__content-input {
            padding-left: 93px;
            input {
                background: $gray;
                border-radius: 10px;
                outline: none;
                border: none;
                font-family: 'NunitoBold';
                font-weight: 700;
                font-size: 1.6rem;
                color: #111111;
            }
        }
        &__content-input-wrapper {
            position: relative;
            width: fit-content;
            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 24px;
            }
        }

        @keyframes myAnim {
            0%,
            100% {
                transform: translateX(0%);
                transform-origin: 50% 50%;
            }

            15% {
                transform: translateX(-5px) rotate(12deg);
            }

            30% {
                transform: translateX(5px) rotate(-12deg);
            }

            45% {
                transform: translateX(0px) rotate(3.6deg);
            }

            60% {
                transform: translateX(0px) rotate(-2.4deg);
            }

            75% {
                transform: translateX(0px) rotate(1.2deg);
            }
        }
    }

    .wrapper {
        min-height: 100vh;
        width: 100%;
        padding-top: 177px;
        padding-left: 469px;
        background-color: #f6f4f0 !important;
        @include breakpoint-max('v-large') {
            padding-left: 260px;
        }
        @include breakpoint-max('xs-tablet') {
            padding: 0;
            padding-top: 40px;
        }
    }
</style>
