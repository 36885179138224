<template>
    <div class="personal-basic-info">
        <div v-if="!loading" class="personal-basic-info__pairs">
            <div v-for="item in pairs" class="personal-basic-info__pair">
                <img
                    loading="lazy"
                    width="200px"
                    height="200px"
                    :src="item.user.photo1"
                />
                <p>{{ item.questionnaire.name }}</p>
            </div>
        </div>
        <div v-else>
            <!-- <Loader /> -->
        </div>

        <div class="personal-basic-info__user-top">
            <!-- Lightbox -->
            <div
                v-if="lightboxVisible"
                class="lightbox"
                @click.self="lightboxVisible = false"
            >
                <figure>
                    <img :src="currentPhoto" alt="Powiększone zdjęcie" />
                </figure>
                <div class="lightbox__controls">
                    <button
                        class="lightbox__close"
                        @click="lightboxVisible = false"
                    >
                        X
                    </button>
                    <button
                        class="lightbox__nav lightbox__nav--left"
                        @click="navigatePhoto(-1)"
                    >
                        &#8592;
                    </button>
                    <button
                        class="lightbox__nav lightbox__nav--right"
                        @click="navigatePhoto(1)"
                    >
                        &#8594;
                    </button>
                </div>
            </div>
            <!-- END Lightbox -->
            <div class="personal-basic-info__user-top-wrapper">
                <!-- header -->
                <div class="personal-basic-info__user-header">
                    <div class="personal-basic-info__user-top-gallery gallery">
                        <div class="gallery__thumbnails">
                            <figure
                                v-if="userPhotos[0]"
                                @click="openLightbox(0)"
                            >
                                <img :src="userPhotos[0]" alt="Photo" />
                            </figure>
                            <figure v-else>
                                <p v-if="userPhotos[0]">Brak zdjęcia</p>
                                <img
                                    src="../../assets//images/ludzik1.png"
                                    alt="Photo"
                                />
                            </figure>
                        </div>
                        <div
                            v-if="isLinkActive('/panel/profile')"
                            @click="editImages()"
                            class="personal-basic-info__edit-images"
                        >
                            Edytuj zdjęcia
                        </div>

                        <div
                            v-if="showEditPop"
                            class="personal-basic-info__edit-images-popup"
                        >
                            <div
                                @click="closeEditPop()"
                                class="personal-basic-info__pop-close"
                            >
                                zamknij
                            </div>
                            <h3>Zmień zdjęcia</h3>
                            <div class="save-profile__photos-flex">
                                <div>
                                    <img
                                        :src="imageSrc1"
                                        @click="triggerFileInput1"
                                        class="profile__img profile__img--border"
                                    />
                                    <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 50 50"
                                        xml:space="preserve"
                                    >
                                        <circle
                                            style="fill: #ffffff"
                                            cx="25"
                                            cy="25"
                                            r="25"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="25"
                                            y1="13"
                                            x2="25"
                                            y2="38"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="37.5"
                                            y1="25"
                                            x2="12.5"
                                            y2="25"
                                        />
                                    </svg>
                                </div>
                                <div>
                                    <img
                                        :src="imageSrc2"
                                        @click="triggerFileInput2"
                                        class="profile__img"
                                    />
                                    <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 50 50"
                                        xml:space="preserve"
                                    >
                                        <circle
                                            style="fill: #ffffff"
                                            cx="25"
                                            cy="25"
                                            r="25"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="25"
                                            y1="13"
                                            x2="25"
                                            y2="38"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="37.5"
                                            y1="25"
                                            x2="12.5"
                                            y2="25"
                                        />
                                    </svg>
                                </div>
                                <div>
                                    <img
                                        :src="imageSrc3"
                                        @click="triggerFileInput3"
                                        class="profile__img"
                                    />
                                    <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 50 50"
                                        xml:space="preserve"
                                    >
                                        <circle
                                            style="fill: #ffffff"
                                            cx="25"
                                            cy="25"
                                            r="25"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="25"
                                            y1="13"
                                            x2="25"
                                            y2="38"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="37.5"
                                            y1="25"
                                            x2="12.5"
                                            y2="25"
                                        />
                                    </svg>
                                </div>
                                <div>
                                    <img
                                        :src="imageSrc4"
                                        @click="triggerFileInput4"
                                        class="profile__img"
                                    />
                                    <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 50 50"
                                        xml:space="preserve"
                                    >
                                        <circle
                                            style="fill: #ffffff"
                                            cx="25"
                                            cy="25"
                                            r="25"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="25"
                                            y1="13"
                                            x2="25"
                                            y2="38"
                                        />
                                        <line
                                            style="
                                                fill: none;
                                                stroke: #000000;
                                                stroke-width: 2;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-miterlimit: 10;
                                            "
                                            x1="37.5"
                                            y1="25"
                                            x2="12.5"
                                            y2="25"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div class="personal-basic-info__save-images">
                                <button @click="saveImages()">Zapisz</button>
                            </div>
                            <p class="personal-basic-info__infoPopup">
                                {{ infoPopup }}
                            </p>
                            <input
                                class="profile__file"
                                type="file"
                                ref="fileInput1"
                                @change="onFileChange1"
                            />
                            <input
                                class="profile__file"
                                type="file"
                                ref="fileInput2"
                                @change="onFileChange2"
                            />
                            <input
                                class="profile__file"
                                type="file"
                                ref="fileInput3"
                                @change="onFileChange3"
                            />
                            <input
                                class="profile__file"
                                type="file"
                                ref="fileInput4"
                                @change="onFileChange4"
                            />
                        </div>
                    </div>
                    <div class="personal-basic-info__user-basic-data w-full">
                        <h3>
                            {{ name }}, {{ setAge(age) }}
                            <br />
                        </h3>
                        <a
                            v-if="this.nodeENV === 'development'"
                            @click="loginAsUser(this.$route.params.id)"
                            target="_blank"
                            class="personal-basic-info__logged-in-as-user"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-login"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path
                                    d="M15 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                                />
                                <path d="M21 12h-13l3 -3" />
                                <path d="M11 15l-3 -3" />
                            </svg>
                            <span>Zaloguj jako user</span>
                        </a>
                        <ul>
                            <li>
                                <span>Imię:</span>
                                {{ name }}
                            </li>
                            <li>
                                <span>Płeć:</span>
                                {{ sex }}
                            </li>
                            <li class="flex">
                                <span>Szuka:</span>
                                <div
                                    class="flex"
                                    v-for="(gender, index) in wantedGender"
                                    :key="index"
                                >
                                    <div
                                        v-if="
                                            !excludedGendersValue.includes(
                                                gender
                                            )
                                        "
                                    >
                                        &nbsp;{{ gender }}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <span>Wiek:</span>
                                {{ setAge(age) }}
                            </li>
                            <li v-if="ageDifference">
                                <span>Różnica wieku:</span>
                                {{ ageDifference }}
                            </li>
                            <li>
                                <span>Wzrost:</span>
                                {{ growth }}
                            </li>
                            <li v-if="growthMatter">
                                <span>Wzrost</span>
                                : {{ growthMatter }}
                            </li>
                            <li v-if="growthComment">
                                <span>Dodatkowa uwaga:</span>
                                {{ growthComment }}
                            </li>
                            <li>
                                <span>Miasto:</span>
                                {{ city }}
                            </li>
                            <li>
                                <p><b>Wybrane miasta:</b></p>
                                <p
                                    class="inline"
                                    v-for="(item, index) in cityPossible"
                                    :key="index"
                                >
                                    {{ item }}
                                    <span
                                        v-if="
                                            cityPossible.length &&
                                            index < cityPossible.length - 1
                                        "
                                    >
                                        ,
                                    </span>
                                </p>
                            </li>
                            <li>
                                <span>Opcja przeprowadzki:</span>
                                {{ movePossibility }}
                            </li>
                        </ul>
                    </div>
                    <div class="personal-basic-info__user-description w-full">
                        <div
                            :class="{ expanded: expanded }"
                            class="text-content"
                        >
                            <span class="uppercase"><b>Opis:</b></span>
                            {{ profileDescription }}
                        </div>
                        <button
                            v-if="
                                profileDescription &&
                                profileDescription?.length > 300
                            "
                            @click="() => (this.expanded = !this.expanded)"
                        ></button>
                    </div>
                    <!--  -->
                </div>
                <!-- header END -->
                <!-- personal -->
                <div class="personal-basic-info__user-personal-info">
                    <ul>
                        <li>
                            <span>Wiara:</span>
                            {{ religion }}
                        </li>
                        <li>
                            <span>Praktykuje:</span>
                            {{ religionPractice }}
                        </li>
                        <li>
                            <span>Ma dzieci:</span>
                            {{ children }}
                        </li>
                        <li>
                            <span>Chce dzieci:</span>
                            {{ childrenWanted }}
                        </li>
                        <li v-if="materialStatus">
                            <span>Rozwód:</span>
                            {{
                                materialStatus === 'po rozwodzie'
                                    ? 'Po rozwodzie'
                                    : `Nie, ${materialStatus} `
                            }}
                        </li>
                        <li v-if="divorcedMatter">
                            <span>Akceptuje rozwód:</span>
                            {{ divorcedMatter }}
                        </li>
                        <li>
                            <span>Lubi zwierzęta:</span>
                            {{
                                petsAttitude === 'Lubię zwierzaki'
                                    ? 'Tak'
                                    : 'Nie'
                            }}
                        </li>
                        <li>
                            <span>Ma zwierzęta:</span>
                            {{ petsHas }}
                        </li>
                        <li>
                            <span>Praca:</span>
                            {{ job }}
                        </li>
                        <li>
                            <span>Zarobki:</span>
                            {{ income }}
                        </li>
                    </ul>
                </div>
                <!-- personal END -->
                <div class="personal-basic-info__user-additional-info">
                    <div v-if="additionalInfo?.length">
                        <b>Dodatkowe Informacje o Tobie:</b>
                        <p v-for="(info, index) in additionalInfo" :key="index">
                            {{ info }}
                        </p>
                    </div>
                    <div v-if="appearanceNoAcceptation">
                        <b>Czego nie akceptujesz w partnerze:</b>
                        <p>{{ appearanceNoAcceptation }}</p>
                    </div>
                    <div>
                        <b>Szczególne znaczenie:</b>
                        <ul>
                            <li v-for="item in additionalPartnerInfo">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <b>Na co zwracasz szczególną uwagę:</b>
                        <p>{{ specialAppearance }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import Loader from '@/components/atoms/Loader.vue'

    export default {
        data() {
            return {
                currentPhotoIndex: 0,
                lightboxVisible: false,
                userPhotos: [],
                selectedFile1: '',
                selectedFile2: '',
                selectedFile3: '',
                selectedFile4: '',
                imageSrc1: require('@/assets/images/ludzik1.png'),
                defaultImageSrc1: require('@/assets/images/ludzik1.png'),
                imageSrc1Filled: null,
                imageSrc2: require('@/assets/images/ludzik2.png'),
                imageSrc2Filled: null,
                imageSrc3: require('@/assets/images/blank.png'),
                imageSrc4: require('@/assets/images/blank.png'),
                showEditPop: false,
                infoPopup: '',
                expanded: false,
                excludedGendersValue: ['0', '1', '2', 0, 1, 2],
                pairs: [],
                loading: true
            }
        },
        components: {
            Loader
        },
        props: {
            images: Array,
            name: String,
            age: String,
            job: String,
            ageDifference: String,
            city: String,
            sex: String,
            growth: String,
            children: String,
            wantedGender: Array,
            cityPossible: Array,
            movePossibility: String,
            religion: String,
            religionMatter: String,
            religionPractice: String,
            religionPracticeMatter: String,
            petsAttitude: String,
            petsHas: String,
            divorcedMatter: String,
            childrenWanted: String,
            additionalInfo: Array,
            appearanceNoAcceptation: String,
            profileDescription: String,
            growthComment: String,
            growthMatter: String,
            materialStatus: String,
            income: String,
            additionalPartnerInfo: String,
            specialAppearance: String
        },
        mounted() {
            document.addEventListener('keyup', this.handleEscape)
            if (this.images) {
                setTimeout(() => {
                    this.userPhotos = [
                        this.images[0],
                        this.images[1],
                        this.images[2],
                        this.images[3]
                    ].filter(Boolean)
                    if (this.userPhotos.length > 0) {
                        this.currentPhoto = this.userPhotos[0]
                    }
                }, 300)
            }
            this.getProfile()
            // this.getPairs()
        },
        beforeDestroy() {
            document.removeEventListener('keyup', this.handleEscape)
        },
        methods: {
            getPairs() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                this.loading = true

                axios
                    .get(
                        `${this.url}api/finder/${this.$route.params.id}`,
                        config
                    )
                    .then(response => {
                        this.pairs = response.data
                        this.loading = false
                    })
                    .catch(error => {
                        console.log(error)
                        this.loading = false
                    })
            },
            isLinkActive(path) {
                return this.$route.path.startsWith(path)
            },
            closeEditPop() {
                this.showEditPop = false
            },
            editImages() {
                this.showEditPop = true
            },
            triggerFileInput1() {
                this.$refs.fileInput1.click()
            },
            triggerFileInput2() {
                this.$refs.fileInput2.click()
            },
            triggerFileInput3() {
                this.$refs.fileInput3.click()
            },
            triggerFileInput4() {
                this.$refs.fileInput4.click()
            },
            checkImagesFormat(imageType) {
                this.photosFormatError = ''
                if (imageType == 'image/png' || imageType == 'image/jpeg') {
                    return true
                } else {
                    this.photosFormatError =
                        'Poprawny format zdjęcia to .jpg lub .png'
                    return false
                }
            },
            onFileChange1(event) {
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                if (event.target.files.length > 0) {
                    this.selectedFile1 = event.target.files[0]
                    this.imageSrc1 = URL.createObjectURL(this.selectedFile1)
                } else {
                    // Resetuj do domyślnego obrazu, jeśli nie wybrano żadnego pliku
                    this.imageSrc1 = require('@/assets/images/ludzik1.png')
                    this.selectedFile1 = '' // Dodatkowo resetuj stan selectedFile1
                }
            },
            onFileChange2(event) {
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                // Analogicznie dla pozostałych metod onFileChange
                if (event.target.files.length > 0) {
                    // console.log('File 2 changed:', event.target.files[0]);
                    this.selectedFile2 = event.target.files[0]
                    this.imageSrc2 = URL.createObjectURL(this.selectedFile2)
                } else {
                    this.imageSrc2 = require('@/assets/images/ludzik2.png')
                    this.selectedFile2 = ''
                }
            },
            onFileChange3(event) {
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                // I tak dalej dla onFileChange3 i onFileChange4
                if (event.target.files.length > 0) {
                    // console.log('File 3 changed:', event.target.files[0]);
                    this.selectedFile3 = event.target.files[0]
                    this.imageSrc3 = URL.createObjectURL(this.selectedFile3)
                } else {
                    this.imageSrc3 = require('@/assets/images/ludzik1.png')
                    this.selectedFile3 = ''
                }
            },
            onFileChange4(event) {
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                if (event.target.files.length > 0) {
                    // console.log('File 4 changed:', event.target.files[0]);
                    this.selectedFile4 = event.target.files[0]
                    this.imageSrc4 = URL.createObjectURL(this.selectedFile4)
                } else {
                    this.imageSrc4 = require('@/assets/images/ludzik2.png')
                    this.selectedFile4 = ''
                }
            },
            saveImages() {
                // Bearer token included ✅
                const formData = new FormData()
                const userId = localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')

                if (userId) {
                    formData.append('userid', userId)
                }

                if (this.selectedFile1) {
                    formData.append('file1', this.selectedFile1)
                }
                if (this.selectedFile2) {
                    formData.append('file2', this.selectedFile2)
                }
                if (this.selectedFile3) {
                    formData.append('file3', this.selectedFile3)
                }
                if (this.selectedFile4) {
                    formData.append('file4', this.selectedFile4)
                }

                axios
                    .post(`${this.url}api/edit-user/photos`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${authToken}`
                        }
                    })
                    .then(response => {
                        this.infoPopup = 'Zapisano!'
                        setTimeout(() => {
                            this.infoPopup = ''
                            this.showEditPop = false
                        }, 2000)
                        // console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            getProfile() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(
                        `${this.url}api/edit-user/` +
                            localStorage.getItem('userId'),
                        config
                    )
                    .then(response => {
                        this.user = response.data

                        if (response.data.user.photo1) {
                            this.imageSrc1 = response.data.user.photo1
                        }
                        if (response.data.user.photo2) {
                            this.imageSrc2 = response.data.user.photo2
                        }
                        if (response.data.user.photo3) {
                            this.imageSrc3 = response.data.user.photo3
                        }
                        if (response.data.user.photo4) {
                            this.imageSrc4 = response.data.user.photo4
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            handleEscape(event) {
                if (event.key === 'Escape') {
                    this.lightboxVisible = false
                }
            },
            setAge(el) {
                const dzisiaj = new Date()
                const dataUrodzeniaDate = new Date(el)

                let wiek =
                    dzisiaj.getFullYear() - dataUrodzeniaDate.getFullYear()

                const miesiacDzisiaj = dzisiaj.getMonth()
                const dzienDzisiaj = dzisiaj.getDate()
                const miesiacUrodzenia = dataUrodzeniaDate.getMonth()
                const dzienUrodzenia = dataUrodzeniaDate.getDate()

                if (
                    miesiacDzisiaj < miesiacUrodzenia ||
                    (miesiacDzisiaj === miesiacUrodzenia &&
                        dzienDzisiaj < dzienUrodzenia)
                ) {
                    wiek--
                }

                return wiek
            },
            setGrowth(el) {
                let metric
                if (el) {
                    el.length >= 3 && !el.includes('.')
                        ? (metric = 'cm')
                        : (metric = 'm')
                    return `${el} ${metric}`
                }
            },
            setSex(el) {
                switch (el) {
                    case '0':
                        return 'Kobieta'
                    case '1':
                        return 'Mężczyzna'
                    case '2':
                        return 'Osoba Niebinarna'
                    default:
                        return el
                }
            },
            //
            navigatePhoto(direction) {
                this.currentPhotoIndex += direction
                if (this.currentPhotoIndex < 0) {
                    this.currentPhotoIndex = this.userPhotos.length - 1
                } else if (this.currentPhotoIndex >= this.userPhotos.length) {
                    this.currentPhotoIndex = 0
                }
            },
            openLightbox(index) {
                this.currentPhotoIndex = index
                this.lightboxVisible = true
            },
            handleKeydown(e) {
                if (!this.lightboxVisible) return
                if (e.key === 'ArrowLeft') {
                    this.navigatePhoto(-1)
                } else if (e.key === 'ArrowRight') {
                    this.navigatePhoto(1)
                }
            },
            handleKeydownExit(e) {
                if (!this.lightboxVisible) return
                if (e.key == 'Escape') {
                    this.lightboxVisible = false
                }
            },
            loginAsUser(userID) {
                this.loaderAnimation = true
                const newTab = window.open('', '_blank')

                try {
                    const response = axios.post(
                        `${this.url}api/login/as-user`,
                        {
                            userID: userID
                        },
                        {
                            withCredentials: true
                        }
                    )

                    response.then(res => {
                        this.loading = false
                        const authToken = res.data.authToken
                        window.localStorage.setItem('userId', res.data.user._id)
                        window.localStorage.setItem(
                            'isAdmin',
                            res.data.user.role === 'admin' ? 1 : 0
                        )

                        this.setAuthCookie(authToken)
                        this.checkUserQuestionnaire(res.data.user._id)
                    })
                    newTab.location.href = `/panel/notification`
                } catch (e) {
                    console.log(e)
                    this.feedback = e
                }
            },
            checkUserQuestionnaire(userID) {
                console.log('checkUserQuestionnaire..')

                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                try {
                    const response = axios
                        .post(
                            `${this.url}api/login/check-questionnaire/${userID}`,
                            config
                        )
                        .then(res => {
                            // console.log(res)
                            this.$router.push('/panel/notification')
                        })
                } catch (e) {
                    console.log(e)
                }
            },

            setAuthCookie(token) {
                const expirationDate = new Date()
                expirationDate.setDate(expirationDate.getDate() + 7)
                document.cookie = `authToken=${token}; expires=${expirationDate.toUTCString()}; path=/`
            },

            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            }
        },
        computed: {
            currentPhoto() {
                return this.userPhotos[this.currentPhotoIndex]
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .profile {
        &__img {
            &--border {
                border: 6px solid $pink;
            }
            &--unactive {
                cursor: auto;
            }
        }
        &__flex-images {
            display: flex;
            gap: 10px;
            width: 100%;
            margin-top: 20px;
            justify-content: center;
            @include breakpoint-max('xs-tablet') {
                margin-bottom: 15px;
            }
            div {
                width: 86px;
                cursor: pointer !important;
                img {
                    border-width: 1px !important;
                }
            }
        }
    }

    .personal-basic-info {
        font-size: 16px;
        &__pairs {
            width: 100%;
            margin-bottom: 130px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
        }
        &__pair {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
            background-color: #fff;
            padding: 20px 15px;

            img {
                object-fit: cover;
            }
        }
        &__user-top-wrapper {
            display: flex;
            flex-direction: column;
            gap: 36px;
        }
        &__save-images {
            text-align: center;
            button {
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                padding: 15px 0;
                width: 200px;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                }
            }
        }
        &__user-header {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;

            @include breakpoint-min('tablet') {
                flex-direction: row;
                justify-content: flex-start;
            }

            ul {
                list-style-type: none;

                li {
                    margin-bottom: 6px;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }

        &__user-basic-data {
            @include breakpoint-min('tablet') {
                padding: 0px 30px;
            }

            min-width: clamp(400px, 440px, 500px);
            h3 {
                font-size: 2.6rem;
                padding: 0px 0px 20px 0px;
                @include breakpoint-min('desktop') {
                    padding: 15px 0px 0px 0px;
                }
            }
        }
        &__logged-in-as-user {
            background-color: $pink;
            padding: 10px 20px;
            color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            margin-bottom: 20px;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }
        }

        &__user-additional-info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            justify-content: space-between;

            @include breakpoint-min('tablet') {
                flex-direction: row;
                justify-content: space-between;
            }

            div {
                flex: 1;
            }
        }

        &__user-description {
            button {
                font-weight: bold;
                cursor: pointer;
                transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

                &:hover {
                    opacity: 80%;
                    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
                }
            }

            .text-content {
                height: 222px;
                overflow: hidden;
            }

            .expanded {
                height: auto;
            }
        }

        &__action-buttons {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 30px;
            padding: 15px 20px 30px 0px;
        }

        &__user-personal-info {
            ul {
                display: flex;
                flex-wrap: wrap;
                list-style-type: none;
                gap: 20px;
                span {
                    font-weight: bold;
                }
            }
        }

        &__infoPopup {
            text-align: center;
            color: $pink;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
        }
        &__pop-close {
            position: absolute;
            right: 10px;
            top: 10px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: $white;
        }
        &__edit-images-popup {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70vw;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            z-index: 99;
            .save-profile__photos-flex {
                padding: 20px;
            }
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
        }
        .no-image {
            margin-top: 30px;
        }
        &__user-top-person-name {
            padding: 8px 36px 8px 59px;
            background-color: $pink;
            color: $white;
            font-size: 3rem;
            line-height: 4.5rem;
            font-weight: 700;
            text-transform: capitalize;
            font-family: 'Open Sans', sans-serif;
            box-shadow: 0px 3px 6px #00000029;
            margin-bottom: 41px;
            @include breakpoint-max('xs-tablet') {
                padding: 6px 20px;
            }
            @include breakpoint-max('mobile') {
                padding: 4px 10px;
                font-size: 2rem;
            }
        }
        &__user-top-person-data {
            padding-left: 51px;
            div {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 26px;
                    @include breakpoint-max('mobile') {
                        margin-right: 15px;
                        max-width: 15px;
                    }
                }
                p {
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-family: 'Open Sans', sans-serif;
                }
                @include breakpoint-max('mobile') {
                    margin-bottom: 0px;
                }
                @include breakpoint-max('xs-tablet') {
                    width: fit-content;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-items: center;
                row-gap: 23px;
            }
        }
        &__user-top-person-data-height {
            p {
                text-transform: lowercase !important;
            }
        }
        &__user-top-person-data-children {
            p {
                text-transform: none !important;
            }
        }
        &__edit-images {
            text-align: center;
            background-color: $pink;
            padding: 10px;
            color: $white;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .gallery {
        @include breakpoint-min('tablet') {
            border: 2px solid transparent;
        }
        &__thumbnails {
            transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
                opacity: 90%;
            }
            figure {
                display: block;
                img {
                    cursor: pointer;
                    display: block;
                    height: auto;
                    width: 280px;
                    height: 280px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    object-position: center;
                    border-radius: 0 !important;
                    border: 1px solid $black;
                    @include breakpoint-max('xs-tablet') {
                        width: 100%;
                        height: auto;
                    }
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                }
                @include breakpoint-max('xs-tablet') {
                    margin-bottom: 26px;
                }
            }
        }
    }
    .lightbox {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            max-width: 80vw; /* 80% szerokości widoku */
            max-height: 80vh; /* 80% wysokości widoku */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            max-width: 95vw;
            max-height: 95vh;
            object-fit: contain !important;
        }
    }
    .lightbox__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .lightbox__nav {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
    .lightbox__nav--left {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }
    .lightbox__nav--right {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }
    .lightbox__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
</style>
