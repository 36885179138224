<template>
    <div>
        <HeaderComponent/>
        <section class="thank-you-page">
            <h1 class="thank-you-page__heading">Potwierdź swój e-mail.</h1>
            <p v-if="notsent">Nie otrzymałeś e-maila? Sprawdź folder SPAM lub <a @click.prevent="sendLink">wyślij ponownie</a>.</p>
            <p v-else>Kod został wysłany na Twój adres e-mail.</p>
        </section>
    </div>
</template>
<script>
import HeaderComponent from "@/components/landing-03/HeaderComponent.vue";
import axios from 'axios';

export default {
    data() {
        return {
            message: '',
            verified: false,
            notsent: true,
        };
    },
    components: {
        HeaderComponent
    },
    methods: {
        getUser(){

            if (!localStorage.getItem('userId')) {
                location.replace("/login");
            }
            // own ID
            axios
                .get(`${this.url}api/verify/user/${localStorage.getItem('userId')}`)
                .then((resp) => {
                    this.verified = resp.data.user.isVerified;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sendLink(){
            // console.log("trying")

            this.notsent = false;

            const formData = new FormData();
            const userId = localStorage.getItem('userId');

            if (userId) {
                formData.append("id", userId);
            }

            axios.post(`${this.url}api/register/resend-verification`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Określenie typu zawartości dla plików
                }
            })
            .then(response => {
                // Tutaj możesz wyświetlić użytkownikowi komunikat o sukcesie
                // console.log(response);
            })
            .catch(error => {
                // Tutaj możesz wyświetlić użytkownikowi komunikat o błędzie
                console.log(error);
            });
        }

    },
    created(){
        this.getUser();
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";


    .thank-you-page{
        margin-top: 120px;
        min-height: calc(100vh  - 120px);
        background-color: $bege3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p{
            font-size: 18px;
            margin-top: 15px;
            a{
                cursor: pointer;
                text-decoration: underline;
            }
        }
        @include breakpoint-max('mobile') {
            padding-bottom: 100px;
        }
        &__heading {
            margin-bottom: 39px;
            letter-spacing: 0px;
            color: $black;
            max-width: 450px;
            text-align: center;
            font-size: 4.5rem;
            line-height: 5.5rem;
            font-weight: 400;
            font-family: 'Promenade', sans-serif;
            @include breakpoint-max('mobile') {
                font-size: 2.5rem;
                line-height: 2.9rem;
                margin-bottom: 30px;
                max-width: 250px;
            }
            @include breakpoint-max('xs-mobile') {
            }
        }
        &__animation {
            margin-bottom: 65px;
            .lottie-animation-container  {
                @include breakpoint-max('mobile') {
                    height: auto;
                    max-width: 250px;
                }
            }
            svg {
                @include breakpoint-max("mobile") {
                    max-width: 280px !important;
                }
            }
            @include breakpoint-max('mobile') {
                margin-bottom: 30px;
            }
        }
        &__text {
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            color: $black;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.4rem;
            padding: 0 15px;
            margin-bottom: 50px;
            text-transform: uppercase;
        }
        &__logout {
            background-color: $pink;
            color: $white;
            text-transform: uppercase;
            padding: 17px 24px;
            font-weight: 700;
            cursor: pointer;    
            //
            outline: none;
            font-size: 2.4rem;
            line-height: 3rem;
            font-family: "Open Sans", sans-serif;
            font-weight: 700;
            padding: 10px 40px;
            cursor: pointer;
            background-color: $pink;
            color: $white;
        }
    }
</style>
  