<template>
    <div class="admin-users all-users">
        <div class="admin-users__filter">
            <div class="admin-users__filter-item">
                <input
                    type="radio"
                    id="women"
                    name="sex"
                    value="0"
                    v-model="check"
                />
                <label :class="{ selected: check == 0 }" for="women">
                    Kobiety
                </label>
            </div>
            <div class="admin-users__filter-item">
                <input
                    type="radio"
                    id="men"
                    name="sex"
                    value="1"
                    v-model="check"
                />
                <label :class="{ selected: check == 1 }" for="men">
                    Mężczyźni
                </label>
            </div>
            <div class="admin-users__filter-item">
                <input
                    type="radio"
                    id="unbinary"
                    name="sex"
                    value="2"
                    v-model="check"
                />
                <label :class="{ selected: check == 2 }" for="unbinary">
                    Niebinarni
                </label>
            </div>
            <div class="admin-users__filter-item">
                <input
                    type="radio"
                    id="all"
                    name="sex"
                    value="all"
                    v-model="check"
                />
                <label :class="{ selected: check == 'all' }" for="all">
                    Wszyscy
                </label>
            </div>
        </div>
        <div class="admin-users__questionnaire-filter">
            <div class="admin-users__questionnaire-filter-item">
                <input
                    type="radio"
                    id="assigned"
                    name="access"
                    value="1"
                    v-model="access"
                />
                <label :class="{ selected: access == 1 }" for="assigned">
                    Przydzielono
                </label>
            </div>
            <div class="admin-users__questionnaire-filter-item">
                <input
                    type="radio"
                    id="awaits"
                    name="access"
                    value="0"
                    v-model="access"
                />
                <label :class="{ selected: access == 0 }" for="awaits">
                    Oczekuje
                </label>
            </div>
        </div>
        <div class="admin-users__loader">
            <div :class="{ loader: loader }"></div>
            <p v-if="!users[0] && loader == false">Brak osób do wyświetlenia</p>
        </div>

        <AdminUsersView :users="users" />

        <!-- PAGINATION START -->

        <router-link
            v-if="usersCount == 'loading'"
            class="admin-users__btn-next"
            :to="`/admin/users/${$route.params.city}/${
                Number($route.params.page) + 1
            }`"
        >
            Następna strona
        </router-link>

        <div
            v-if="usersCount == 'loading'"
            class="admin-users__loader pagination-loader"
        >
            <p>Ładowanie paginacji ...</p>
            <div class="loader"></div>
        </div>
        <div
            v-if="
                usersCount > numberOfPeopleDisplayed &&
                $route.params.page <=
                    roundUsersPage(usersCount / numberOfPeopleDisplayed)
            "
            class="admin-users__buttons"
        >
            <div class="admin-users__buttons-box">
                <router-link
                    v-if="$route.params.page != 1"
                    class="admin-users__btn admin-users-view__btn--red"
                    :to="`/admin/users/${$route.params.city}/${
                        Number($route.params.page) - 1
                    }`"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        color="#000000"
                    >
                        <path
                            d="M15 6L9 12L15 18"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </router-link>
            </div>
            <router-link
                v-if="$route.params.page != 1"
                :to="`/admin/users/${$route.params.city}/1`"
            >
                1
            </router-link>
            <p v-if="$route.params.page != 1">...</p>
            <router-link
                v-if="$route.params.page - 2 > 1"
                :to="`/admin/users/${$route.params.city}/${
                    Number($route.params.page) - 2
                }`"
            >
                {{ $route.params.page - 2 }}
            </router-link>
            <router-link
                v-if="$route.params.page - 1 > 1"
                :to="`/admin/users/${$route.params.city}/${
                    Number($route.params.page) - 1
                }`"
            >
                {{ $route.params.page - 1 }}
            </router-link>
            <p class="current-page">{{ $route.params.page }}</p>
            <router-link
                v-if="
                    parseInt($route.params.page) + 1 <
                    roundUsersPage(usersCount / numberOfPeopleDisplayed)
                "
                :to="`/admin/users/${$route.params.city}/${
                    Number($route.params.page) + 1
                }`"
            >
                {{ parseInt($route.params.page) + 1 }}
            </router-link>
            <router-link
                v-if="
                    parseInt($route.params.page) + 2 <
                    roundUsersPage(usersCount / numberOfPeopleDisplayed)
                "
                :to="`/admin/users/${$route.params.city}/${
                    Number($route.params.page) + 2
                }`"
            >
                {{ parseInt($route.params.page) + 2 }}
            </router-link>
            <p
                v-if="
                    $route.params.page !=
                    roundUsersPage(usersCount / numberOfPeopleDisplayed)
                "
            >
                ...
            </p>
            <router-link
                v-if="
                    $route.params.page !=
                    roundUsersPage(usersCount / numberOfPeopleDisplayed)
                "
                :to="`/admin/users/${$route.params.city}/${roundUsersPage(
                    usersCount / numberOfPeopleDisplayed
                )}`"
            >
                {{ roundUsersPage(usersCount / numberOfPeopleDisplayed) }}
            </router-link>
            <div class="admin-users__buttons-box">
                <router-link
                    v-if="
                        $route.params.page <
                        roundUsersPage(usersCount / numberOfPeopleDisplayed)
                    "
                    class="admin-users__btn"
                    :to="`/admin/users/${$route.params.city}/${
                        Number($route.params.page) + 1
                    }`"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        color="#000000"
                    >
                        <path
                            d="M9 6L15 12L9 18"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </router-link>
            </div>
        </div>

        <!-- PAGINATION END -->

        <!-- NEW PAGINATION COMPONENT -->

        <!-- <Pagination
            :totalItems="totalItems"
            :itemsPerPage="itemsPerPage"
            v-model:currentPage="currentPage"
        /> -->

        <!-- NEW PAGINATION COMPONENT -->

        <div class="admin-users__page-input" v-if="this.usersCount > 16">
            <input placeholder="Numer strony" type="text" v-model="pageInput" />
            <button @click="setPage()">Przejdź do strony</button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import AdminUsersView from '@/components/AdminUsersView.vue'

    export default {
        name: 'admin-users',
        data() {
            return {
                users: [],
                usersCount: 'loading',
                numberOfPeopleDisplayed: 16,
                check: 'all',
                access: '1',
                loader: false,
                pageInput: ''
            }
        },
        components: {
            AdminUsersView
        },
        methods: {
            setPage() {
                if (this.pageInput != '' && parseInt(this.pageInput)) {
                    this.$router.push(
                        `/admin/users/${this.$route.params.city}/${this.pageInput}`
                    )
                    this.pageInput = ''
                }
            },
            roundUsersPage(num) {
                return Math.ceil(num)
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getUsers() {
                // Bearer token included ✅
                this.users = []
                this.usersCount = 0
                const page = this.$route.params.page
                const city = this.$route.params.city

                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                this.loader = true
                axios
                    .get(
                        `${this.url}api/questionnaire/page/${page}/${this.check}/${city}/${this.access}`,
                        config
                    )
                    .then(resp => {
                        this.users = resp?.data?.users || []
                        this.usersCount = resp?.data?.total || 0
                        this.loader = false
                        0
                    })
                    .catch(err => {
                        this.loader = false
                        console.log(err)
                    })
            },
            // async getUsersCount() {
            //     this.usersCount = 'loading'
            //     const city = this.$route.params.city

            //     const authToken = this.getCookie('authToken')
            //     const config = {
            //         headers: { Authorization: `Bearer ${authToken}` }
            //     }

            //     await axios
            //         .get(
            //             `${this.url}api/users/count/${city}/${this.access}/${this.check}`,
            //             config
            //         )
            //         .then(resp => {
            //             this.usersCount = resp.data.count
            //         })
            //         .catch(err => {
            //             console.log(err)
            //         })
            // },
            setFirstPage() {
                this.$router.push(`/admin/users/${this.$route.params.city}/1`)
            }
        },
        watch: {
            '$route.params.page': function () {
                this.getUsers()
            },
            '$route.params.city': function () {
                this.getUsers()
                // this.getUsersCount()
                this.setFirstPage()
            },
            check: function () {
                this.getUsers()
                // this.getUsersCount()
                this.setFirstPage()
            },
            access: function () {
                this.getUsers()
                // this.getUsersCount()
                this.setFirstPage()
            }
        },
        created() {
            this.getUsers()
            // this.getUsersCount()
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    .admin-users {
        padding-right: 120px;
        padding-bottom: 150px;
        @include breakpoint-max('v-large') {
            padding-right: 30px;
        }
        @include breakpoint-max('xs-tablet') {
            padding-left: 30px;
            padding-bottom: 40px;
        }
        &__filter,
        &__questionnaire-filter {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            gap: 35px;
            flex-wrap: wrap;
            @include breakpoint-max('xs-tablet') {
                gap: 20px;
            }
        }
        &__filter-item,
        &__questionnaire-filter-item {
            input {
                display: none;
            }
            label {
                cursor: pointer;
                color: $black;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-transform: uppercase;
                padding: 4px 6px;
                transform: 0.3s;
            }
            .selected {
                background-color: $pink;
                color: $white;
            }
        }
        &__btn-next {
            display: block;
            width: fit-content;
            margin: 0 auto;
            color: $black;
            font-size: 1.6rem;
            line-height: 2.2rem;
            font-weight: 700;
            padding: 10px;
            text-decoration: none;
            background-color: $bege3;
        }
        &__buttons {
            margin-bottom: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            p,
            a {
                color: $black;
                font-size: 2rem;
                line-height: 2.2rem;
                font-weight: 700;
                padding: 10px;
            }
            a {
                text-decoration: none;
                transition: 0.3s;
                &:hover {
                    color: $pink;
                }
            }
            .current-page {
                color: $pink;
            }
            @include breakpoint-max('mobile') {
                column-gap: 15px;
            }
        }
        &__btn {
            display: block;
            padding: 10px !important;
            background-color: #e1dbd5;
            color: $black;
            font-size: 1.4rem;
            line-height: 2.2rem;
            text-transform: uppercase;
            font-weight: 700;
            text-decoration: none;
            transition: 0.3s;
            svg {
                display: block;
                path {
                    transition: 0.3s;
                }
            }
            &:hover {
                background-color: $pink;
                color: $white;
                svg {
                    path {
                        stroke: $white;
                    }
                }
            }
            @include breakpoint-max('mobile') {
                font-size: 1rem;
                line-height: 1.5rem;
                padding: 6px 8px !important;
            }
        }
        &__buttons-box {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            a {
                text-align: center;
            }
        }
        .pagination-loader {
            display: flex;
            align-items: center;
            column-gap: 30px;
        }
        &__loader {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                color: $black;
                font-size: 1.4rem;
                line-height: 2.2rem;
                text-transform: uppercase;
                font-weight: 700;
                padding: 50px 0;
            }
        }
        &__page-input {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 20px;
            input {
                background-color: transparent;
                outline: none;
                border: 1px solid $black;
                padding: 8px 0;
                color: $black;
                font-size: 2rem;
                line-height: 2.2rem;
                font-weight: 700;
                width: 160px;
                text-align: center;
            }
            button {
                padding: 10px;
                background-color: $bege3;
                color: $black;
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    .loader {
        margin: 80px 0;
        width: 50px;
        padding: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #f2545b;
        --_m: conic-gradient(#0000 10%, #000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: l3 1s infinite linear;
    }
    @keyframes l3 {
        to {
            transform: rotate(1turn);
        }
    }
</style>
