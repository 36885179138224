<template>
    <div class="single-match">
        <div class="single-match__left">
            <figure>
                <img v-if="user?.photo1" :src="user?.photo1" alt="" />
                <img v-else src="../../assets/images/ludzik1.png" alt="Photo" />
            </figure>
            <div class="single-match__left-content">
                <div class="single-match__left-content-left">
                    <p>
                        {{ userQuestionnaire.name }},
                        {{ setAge(userQuestionnaire.age) }}
                    </p>
                    <div class="single-match__left-content-left-line">
                        <div class="fill"></div>
                    </div>
                </div>
                <div class="single-match__left-content-right">
                    <div class="single-match__left-content-right-circle">
                        <p>80%</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-match__right">
            <div class="single-match__right-button">
                <router-link
                    :to="'/admin/users/user/match/' + toBackId + '/' + userId"
                    @click="savePath()"
                >
                    ZOBACZ PROFIL
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                user: [],
                userQuestionnaire: []
            }
        },
        props: {
            userId: String,
            toBackId: String
        },
        mounted() {
            this.getUser()
        },
        methods: {
            savePath() {
                localStorage.setItem(
                    'singleMatchViewPath',
                    window.location.pathname
                )
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getUser() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
                if (this.userId) {
                    axios
                        .get(
                            `${this.url}api/questionnaire/singleuser/${this.userId}`,
                            config
                        )
                        .then(resp => {
                            this.user = resp.data.user
                            this.userQuestionnaire = resp.data.questionnaire
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            },
            setAge(el) {
                return new Date().getFullYear() - new Date(el).getFullYear() - 1
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .single-match {
        margin-bottom: 52px !important;
        display: flex;
        align-items: center;
        column-gap: 25px;
        @include breakpoint-max('v-large') {
            flex-direction: column;
            row-gap: 10px;
        }
        &__left {
            border: 1px solid $black;
            display: flex;
            align-items: center;
            column-gap: 39px;
            padding-right: 20px;
            figure {
                display: block;
                img {
                    display: block;
                    width: 79px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    border-right: 1px solid $black;
                }
            }
        }
        &__left-content {
            display: flex;
            align-items: center;
            column-gap: 20px;
        }
        &__left-content-left {
            p {
                font-size: 2.4rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                margin-bottom: 17px;
            }
        }
        &__left-content-left-line {
            width: 193px;
            height: 5px;
            border-radius: 200px;
            background-color: #e1dbd5;
            position: relative;
            overflow: hidden;
            .fill {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 0%;
                height: 100%;
                transition: 0.3s;
                background-color: $pink;
                width: 24%;
                border-radius: 200px;
            }
        }
        &__left-content-right {
        }
        &__left-content-right-circle {
            width: 49px;
            height: 49px;
            border-radius: 50%;
            background-color: $pink;
            position: relative;
            display: grid;
            place-items: center;
            p {
                position: absolute;
                font-size: 1.6rem;
                line-height: 3rem;
                color: $white;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
            }
        }
        &__right {
            @include breakpoint-max('v-large') {
                flex: auto;
            }
        }
        &__right-button {
            a {
                text-decoration: none;
                display: inline-block;
                background-color: $pink;
                padding: 15px 0;
                text-align: center;
                width: 200px;
                outline: none;
                border: none;
                font-size: 1.6rem;
                line-height: 3rem;
                color: $white;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
            }
        }
    }
</style>
