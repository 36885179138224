<template>
    <div class="my-profile">
        <div class="my-profile__wrapper">
            <div class="my-profile__left">
                <PersonalBasicInfoMyProfile
                    v-if="imagesLoaded"
                    :city="questionnaire.city"
                    :images="images"
                    :name="questionnaire.name"
                    :age="questionnaire.age"
                    :sex="questionnaire.sex"
                    :growth="questionnaire.growth"
                    :children="questionnaire.childrenHas"
                />
                <!--  -->
                <div
                    class="my-profile__more-informations"
                    v-if="user?.isVerifiedByAdmin"
                >
                    <div
                        @click="editAboutMe()"
                        class="my-profile__more-informations-about"
                    >
                        <p class="my-profile__more-informations-about-title">
                            Więcej o mnie
                            <svg
                                class="feather feather-edit"
                                fill="none"
                                height="24"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                />
                                <path
                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                />
                            </svg>
                        </p>
                        <p class="my-profile__more-informations-about-content">
                            {{ questionnaire.profileDescription }}
                        </p>
                    </div>
                    <div class="my-profile__more-informations-wrapper">
                        <div
                            v-if="questionnaire.completeSentences"
                            v-for="(
                                sentence, index
                            ) in questionnaire.completeSentences"
                            :key="index"
                            class="my-profile__more-informations-item"
                            @click="
                                    editSentence(
                                        sentence,
                                        completeSentencesText[index],
                                        index
                                    )
                                "
                        >
                            <div
                                class="my-profile__more-informations-item-circle"
                            >
                                <svg
                                    id="Group_326"
                                    data-name="Group 326"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="20.534"
                                    height="19.599"
                                    viewBox="0 0 20.534 19.599"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect
                                                id="Rectangle_219"
                                                data-name="Rectangle 219"
                                                width="20.534"
                                                height="19.599"
                                                fill="#fff"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g
                                        id="Group_325"
                                        data-name="Group 325"
                                        transform="translate(0 0)"
                                        clip-path="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_307"
                                            data-name="Path 307"
                                            d="M15.262,19.6a2.2,2.2,0,0,1-1.02-.252l-3.974-2.074L6.293,19.347a2.187,2.187,0,0,1-2.3-.165,2.153,2.153,0,0,1-.871-2.124l.759-4.393L.662,9.553A2.148,2.148,0,0,1,.109,7.327,2.17,2.17,0,0,1,1.875,5.849l4.443-.641,1.987-4a2.195,2.195,0,0,1,3.924,0l1.987,4,4.443.641a2.168,2.168,0,0,1,1.213,3.7l-3.215,3.111.759,4.393a2.152,2.152,0,0,1-.87,2.124,2.193,2.193,0,0,1-1.284.417m-4.995-3.565a.592.592,0,0,1,.273.067l4.247,2.217a1,1,0,0,0,1.07-.077.985.985,0,0,0,.4-.987L15.45,12.56a.58.58,0,0,1,.169-.515L19.054,8.72a.984.984,0,0,0,.257-1.034A1,1,0,0,0,18.491,7l-4.748-.685A.587.587,0,0,1,13.3,6L11.179,1.725a1.02,1.02,0,0,0-1.823,0L7.233,6a.587.587,0,0,1-.441.318L2.044,7a1,1,0,0,0-.82.687A.984.984,0,0,0,1.48,8.72l3.436,3.325a.579.579,0,0,1,.168.515l-.811,4.694a.987.987,0,0,0,.4.987,1.006,1.006,0,0,0,1.07.077L9.995,16.1a.593.593,0,0,1,.273-.067"
                                            transform="translate(0 0)"
                                            fill="#fff"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <p>
                                <strong>
                                    {{ completeSentencesText[index] }}:
                                </strong>
                                {{ sentence }}
                            </p>
                                <svg
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                    />
                                    <path
                                        d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                    />
                                </svg>
                        </div>
                    </div>
                    <div
                        @click="showDeletePop()"
                        class="my-profile__right-button delete-desktop"
                    >
                        <button>Usuń konto</button>
                    </div>
                </div>
                <div
                    class="my-profile__more-informations-wrapper my-profile__more-informations-wrapper--margin"
                >
                    <div
                        class="my-profile__more-informations-item my-profile__more-informations-item--column"
                    >
                        <p><strong>Wybrane miasta:</strong></p>
                        <div class="city-date">
                            <Multiselect
                                class="my-profile__multiselect"
                                v-model="cityDate"
                                :options="options"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="true"
                            />
                        </div>
                        <p v-if="errorMsg">{{ errorMsg }}</p>
                        <button
                            class="my-profile__button"
                            @click="changeCityPossible"
                        >
                            Zapisz miasta
                        </button>
                    </div>
                </div>
            </div>
            <div class="my-profile__right">
                <!-- <div class="my-profile__right-image">
                    <picture v-if="notification">
                        <img src="../assets/images/bbbasia-chat.png" alt="">
                    </picture>
                    <p>Hej {{questionnaire.name}},<br> Nadal szukamy pary dla Ciebie, jak się takowa pojawi, dostaniesz informację.</p>
                </div> -->
                <div
                    @click="showDeletePop()"
                    class="my-profile__right-button desktop-delete"
                >
                    <button>Usuń konto</button>
                </div>
            </div>
        </div>
        <div v-if="showPopup" class="my-profile__delete-profile-pop">
            <div
                @click="hideDeletePop()"
                class="my-profile__delete-profile-pop-close"
            >
                zamknij
            </div>
            <h3>Jeśli chcesz usunąć konto, wpisz swoje imię i nazwisko</h3>
            <div class="my-profile__delete-profile-pop-box">
                <div class="my-profile__delete-profile-pop-wrap">
                    <p>
                        Czy na pewno chcesz
                        <strong>usunąć trwale swoje konto?</strong>
                        <br />
                        Zmiany są nieodwracalne.
                    </p>

                    <input
                        v-model="checkName"
                        id="my-profile__input-name-pop"
                        placeholder="Imię"
                    />
                    <input
                        v-model="checkSurname"
                        id="my-profile__input-surname-pop"
                        placeholder="Nazwisko"
                    />
                    <button @click="deleteAcc">Usuń konto</button>
                    <p
                        v-if="deleteErrorMess"
                        class="my-profile__delete-profile-error"
                    >
                        {{ deleteErrorMess }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="showPopupAbout" class="my-profile__about-pop">
            <div @click="hidePopupAbout()" class="my-profile__about-pop-close">
                zamknij
            </div>
            <h3>Zmień swój opis</h3>
            <div class="my-profile__delete-profile-pop-box">
                <div class="my-profile__delete-profile-pop-wrap">
                    <textarea
                        v-model="questionnaire.profileDescription"
                        name="name"
                        rows="8"
                        cols="80"
                        maxlength="1000"
                        style="margin-bottom: 16px"
                    ></textarea>
                    <div>
                        {{ questionnaire.profileDescription.length }}/1000
                        znaków
                    </div>
                    <button @click="saveAboutMe()">ZAPISZ</button>
                    <p
                        v-if="showErrorMess"
                        class="my-profile__delete-profile-error"
                    >
                        {{ showErrorMess }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="showPopupSentence" class="my-profile__about-pop">
            <div
                @click="hidePopupSentence()"
                class="my-profile__about-pop-close"
            >
                zamknij
            </div>
            <h3>Zmień swoją odpowiedź</h3>
            <div class="my-profile__delete-profile-pop-box">
                <div class="my-profile__delete-profile-pop-wrap">
                    <div style="font-weight: 700; margin-bottom: 16px">
                        {{ currentShownQuestion }}
                    </div>
                    <textarea
                        v-model="currentShownSentence"
                        name="name"
                        rows="4"
                        cols="40"
                        maxlength="100"
                        style="margin-bottom: 16px"
                    ></textarea>
                    <div>{{ currentShownSentence.length }}/100 znaków</div>
                    <button @click="saveSentence()">ZAPISZ</button>
                    <p
                        v-if="showErrorMess"
                        class="my-profile__delete-profile-error"
                    >
                        {{ showErrorMess }}
                    </p>
                </div>
            </div>
        </div>
        <div
            @click="showDeletePop()"
            class="my-profile__right-button btn-remove"
        >
            <button>Usuń konto</button>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import PersonalBasicInfoMyProfile from '../components/atoms/PersonalBasicInfoMyProfile.vue'
    import Multiselect from '@vueform/multiselect'

    export default {
        data() {
            return {
                images: [],
                imagesLoaded: false,
                user: [],
                questionnaire: [],
                loveInfo: [],
                notification: true,
                checkName: '',
                checkSurname: '',
                changeAboutMe: '',
                deleteErrorMess: '',
                showPopup: false,
                errorMsg: '',
                showPopupAbout: false,
                showPopupSentence: false,
                currentShownSentence: '',
                currentShownQuestion: '',
                currentShownSentenceIndex: '',
                showErrorMess: '',
                cityDate: null,
                options: [
                    'Warszawa',
                    'Poznań',
                    'Wrocław',
                    'Kraków',
                    'Łódź',
                    'Rzeszów',
                    'Gdańsk/Gdynia/Sopot',
                    'Katowice',
                    'Szczecin',
                    'Bydgoszcz',
                    'Lublin'
                ],
                completeSentencesText: [
                    'Uwielbiam',
                    'Nie przepadam',
                    'Tracę poczucie czasu, gdy',
                    'Nie żal mi pieniędzy na',
                    'Jestem najbardziej dumna/dumny z',
                    'Nie wyobrażam sobie życia bez',
                    'Najbardziej lubię spędzać wakacje',
                    'Nie jestem w stanie zjeść',
                    'W moich przyjaciołach najbardziej cenię',
                    'Najlepsza rada usłyszana w życiu to',
                    'Potrawa, którą przyrządzam po mistrzowsku to',
                    'Nie potrafię sobie odmówić',
                    'Cecha, która sprawia, że od razu kogoś lubię to',
                    'W wolnym czasie',
                    'Nigdy nie żal mi czasu na',
                    'Coś, co szybko wyprowadza mnie z równowagi to',
                    'Najgorszy prezent otrzymany w życiu to',
                    'Lubię zapach',
                    'Marzy mi się',
                    'Podziwiam ludzi za'
                ]
            }
        },
        components: {
            PersonalBasicInfoMyProfile,
            Multiselect
        },
        mounted() {
            this.getUser()
            this.getQuestionnaire()
        },
        methods: {
            editAboutMe() {
                this.showPopupAbout = true
            },
            editSentence(sentence, question, index) {
                this.showPopupSentence = true
                this.currentShownSentence = sentence
                this.currentShownQuestion = question
                this.currentShownSentenceIndex = index
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            saveSentence() {
                if (this.currentShownSentence.length <= 100) {
                    let id = window.localStorage.getItem('userId')
                    const authToken = this.getCookie('authToken')
                    const config = {
                        headers: { Authorization: `Bearer ${authToken}` }
                    }
                    axios
                        .post(
                            `${this.url}api/questionnaire/sentences`,
                            {
                                sentenceIndex: this.currentShownSentenceIndex,
                                sentence: this.currentShownSentence,
                                userId: id
                            },
                            config
                        )
                        .then(res => {
                            this.showErrorMess =
                                'Pomyślnie zmieniono odpowiedź!'
                            this.questionnaire.completeSentences[
                                this.currentShownSentenceIndex
                            ] = this.currentShownSentence
                        })
                        .catch(err => console.log(err))
                } else {
                    this.showErrorMess =
                        'Odpowiedź nie może zawierać więcej niż 100 znaków!'
                }
            },
            saveAboutMe() {
                // Bearer token included ✅
                if (this.questionnaire.profileDescription.length <= 1000) {
                    let id = window.localStorage.getItem('userId')

                    const authToken = this.getCookie('authToken')
                    const config = {
                        headers: { Authorization: `Bearer ${authToken}` }
                    }

                    this.showErrorMess = 'Pomyślnie zmieniono opis!'
                    axios
                        .post(
                            `${this.url}api/questionnaire/description`,
                            {
                                text: this.questionnaire.profileDescription,
                                userId: id
                            },
                            config
                        )
                        .then(res => {})
                        .catch(err => console.log(err))
                } else {
                    this.showErrorMess =
                        'Opis nie może zawierać więcej niż 1000 znaków!'
                }
            },
            hidePopupAbout() {
                this.showPopupAbout = false
                this.changeAboutMe = ''
            },
            hidePopupSentence() {
                this.showPopupSentence = false
                this.currentShownSentence = ''
                this.currentShownQuestion = ''
                this.currentShownSentenceIndex = ''
                this.showErrorMess = ''
            },
            hideDeletePop() {
                this.showPopup = false
            },
            showDeletePop() {
                this.showPopup = true
            },
            deleteAuthCookie() {
                document.cookie =
                    'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
            },
            async logout() {
                try {
                    await axios.post(`${this.url}api/logout`)

                    this.deleteAuthCookie()

                    this.$store.commit('setAuth', false)
                    localStorage.removeItem('userId')

                    this.$router.push('/account-deleted')
                } catch (error) {
                    console.error(error)
                }
            },
            deleteAcc() {
                if (
                    this.checkName != this.questionnaire.name &&
                    this.checkSurname != this.questionnaire.surname
                ) {
                    this.deleteErrorMess =
                        'Wprowadzone imię i nazwisko nie są zgodne!'
                    return
                }
                this.deleteErrorMess = ''

                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .delete(
                        `${
                            this.url
                        }api/users/delete-account/${localStorage.getItem(
                            'userId'
                        )}`,
                        config
                    )
                    .then(res => {
                        this.logout()
                    })
                    .catch(err => console.log(err))

                // this.showPopup = false;
            },
            getUser() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                if (!localStorage.getItem('userId')) {
                    location.replace('/login')
                }
                axios
                    .get(
                        `${this.url}api/verify/user/${localStorage.getItem(
                            'userId'
                        )}`,
                        config
                    )
                    .then(resp => {
                        this.user = resp.data.user
                        // if(!this.user.isVerifiedByAdmin) this.$router.push('/panel/notification')
                        this.images.push(this.user.photo1)
                        this.images.push(this.user.photo2)
                        this.images.push(this.user.photo3)
                        this.images.push(this.user.photo4)
                        this.imagesLoaded = true
                        for (let i = 0; i < 4; i++) {
                            this.loveInfo.push({
                                text: '<strong>Uwielbiam</strong> Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut'
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            changeCityPossible() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                // console.log(this.cityDate.length)
                if (this.cityDate.length == 0) {
                    this.errorMsg = 'Musisz wybrać przynajmniej jedno miasto.'
                    return
                }
                axios
                    .post(
                        `${this.url}api/questionnaire/changecitypossible`,
                        {
                            userId: localStorage.getItem('userId'),
                            cities: this.cityDate
                        },
                        config
                    )
                    .then(res => {
                        this.errorMsg = ''
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getQuestionnaire() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/users/singleuser/${id}`, config)
                    .then(resp => {
                        this.questionnaire = resp.data
                        if (
                            Array.isArray(this.questionnaire.cityPossible) &&
                            this.questionnaire.cityPossible.length > 0
                        ) {
                            // Sprawdź, czy pierwszy element zawiera przecinek, co sugeruje, że jest to ciąg z wieloma miastami
                            if (
                                this.questionnaire.cityPossible[0].includes(',')
                            ) {
                                // Jeśli tak, przekształć pierwszy element na tablicę miast
                                this.cityDate =
                                    this.questionnaire.cityPossible[0].split(
                                        ','
                                    )
                            } else {
                                // Jeśli pierwszy element nie zawiera przecinka, użyj tablicy bez zmian
                                this.cityDate = this.questionnaire.cityPossible
                            }
                        } else {
                            // W przypadku, gdy `cityPossible` nie jest tablicą lub jest pusta, przypisz pustą tablicę jako bezpiecznik
                            this.cityDate = []
                        }
                    })
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .btn-remove {
        display: none !important;
        @include breakpoint-max('xs-tablet') {
            display: flex !important;
        }
    }

    .delete-desktop {
        display: none !important;
    }

    .city-date {
        column-gap: 27px;
        .save-profile__title {
            padding: 27px 0;
            @include breakpoint-max('xs-tablet') {
                text-wrap: wrap;
            }
            @include breakpoint-max('mobile') {
                padding-bottom: 10px;
                padding-top: 0;
            }
        }
        .multiselect {
            cursor: pointer;
            background-color: transparent;
            border: none;
            outline: none;
            .multiselect-wrapper {
                @include breakpoint-max('mobile') {
                    width: 93%;
                }
            }
            .multiselect-tag {
                padding: 5px 10px;
            }
            .multiselect-tag-wrapper {
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
            }
            .multiselect-tag-remove-icon {
                width: 10px;
                height: 10px;
            }
            @include breakpoint-max('mobile') {
                margin-bottom: 15px;
            }
        }
        .multiselect-options {
            li {
                span {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
        .multiselect-clear-icon {
            background-color: $black;
            width: 15px;
            height: 15px;
            display: none;
        }
        .multiselect-caret {
            background-color: $black;
            width: 20px;
            height: 20px;
        }
        input {
            padding: 10px 0 !important;
            height: 26px !important;
            width: 100% !important;
            font-size: 14px !important;
            line-height: 30px !important;
        }
        .multiselect-tags {
            height: 26px !important;
            margin: 8px 0;
            height: auto !important;
            align-items: flex-start !important;
        }
        .multiselect-tag {
            background-color: $pink;
        }
        .multiselect-tags-search {
            padding: 0 !important;
            background-color: #f6f4f0;
        }
    }

    .my-profile {
        @include breakpoint-max('xs-tablet') {
            padding-bottom: 20px !important;
        }
        &__user-top-wrapper {
            display: flex;
            @include breakpoint-max('xs-tablet') {
                flex-direction: column;
            }
        }
        &__button {
            border: none;
            outline: none;
            appearance: none;
            color: #000;
            border: 1px solid $black;
            background-color: #e1dbd5;
            padding: 10px 0;
            width: 150px;
            font-size: 1.4rem;
            margin-top: 10px;
            line-height: 2.4rem;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            cursor: pointer;
            transition: 0.3s ease;
            &:hover {
                background-color: #d13724;
                color: #fff;
            }
            @include breakpoint-max('xs-tablet') {
                margin-left: 20px;
            }
        }
        &__multiselect {
            width: 100%;
        }
        &__save-images {
            text-align: center;
            button {
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                padding: 15px 0;
                width: 200px;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                }
            }
        }
        &__infoPopup {
            text-align: center;
            color: $pink;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
        }
        &__pop-close {
            position: absolute;
            right: 10px;
            top: 10px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: $white;
        }
        &__edit-images-popup {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70vw;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            z-index: 99;
            .save-profile__photos-flex {
                padding: 20px;
            }
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
        }
        padding-right: 185px;
        padding-bottom: 120px;
        @include breakpoint-max('mobile') {
            padding-bottom: 80px;
        }
        @include breakpoint-max('v-large') {
            padding-right: 30px;
        }
        .desktop-delete {
            @include breakpoint-max('large') {
                display: none;
            }
        }
        .mobile-delete {
            display: none;
            @include breakpoint-max('large') {
                display: flex;
                margin-top: 150px;
            }
            @include breakpoint-max('mobile') {
                margin-top: 129px;
            }
        }
        &__wrapper {
            display: flex;
            column-gap: 41px;
            justify-content: space-between;
            @include breakpoint-max('large') {
                flex-direction: column-reverse;
                row-gap: 80px;
            }
            @include breakpoint-max('xs-tablet') {
                margin-top: 0;
                row-gap: 0px;
            }
            @include breakpoint-max('mobile') {
                row-gap: 0px;
            }
        }
        &__left {
            flex: 82;
        }
        &__more-informations {
            margin-top: 47px;
            width: 100%;
            max-width: 823px;
            @include breakpoint-max('large') {
                max-width: 8230px;
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
            }
        }
        &__more-informations-about {
            position: relative;
            width: 100%;
            margin-bottom: 49px;
            overflow: hidden;
            cursor: pointer;
            @include breakpoint-max('mobile') {
                margin-bottom: 39px;
            }
            &:hover {
                svg {
                    transform: translateX(0%);
                }
            }
        }
        &__more-informations-about-title {
            position: relative;
            padding: 7px 0;
            text-align: center;
            background-color: $pink;
            color: $white;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            svg {
                position: absolute;
                right: 10px;
                transform: translateX(200%);
                transition: 0.3s ease;
            }
        }
        &__more-informations-about-content {
            padding: 35px;
            border: 1px solid $black;
            font-size: 1.6rem;
            line-height: 2.2rem;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            @include breakpoint-max('mobile') {
                padding: 25px 16px;
            }
        }
        &__more-informations-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 65px 27px;
            &--margin {
                margin-top: 20px;
            }
            @include breakpoint-max('mobile') {
                grid-template-columns: repeat(1, 1fr);
                row-gap: 44px;
            }
        }
        &__more-informations-item {
            display: flex;
            align-items: center;
            column-gap: 15px;
            border-top: 1px solid $black;
            padding-top: 19px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            padding-right: 22px;
            svg {
                position: absolute;
                bottom: calc(50% - 18px);
                right: 0;
                transform: translateX(200%);
                transition: 0.3s ease;
            }
            &:hover {
                svg {
                    transform: translateX(0%);
                }
            }
            &--column {
                flex-direction: column;
                p {
                    @include breakpoint-max('xs-tablet') {
                        margin-left: 20px;
                    }
                }
            }
            svg {
                min-width: 21px;
                path {
                    fill: $white;
                }
            }
            p {
                font-size: 1.6rem;
                line-height: 3rem;
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                strong {
                    font-weight: 700;
                }
            }
            @include breakpoint-max('mobile') {
                padding-top: 15px;
            }
        }
        &__more-informations-item-circle {
            display: grid;
            place-items: center;
            width: 42px;
            height: 42px;
            min-width: 42px;
            min-height: 42px;
            background-color: $pink;
            border-radius: 50%;
        }
        &__right {
            flex: 39;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            @include breakpoint-max('large') {
                row-gap: 80px;
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
            }
        }
        &__right-image {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            row-gap: 15px;
            picture {
                display: block;
                width: fit-content;
                img {
                    display: block;
                    width: 96px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    @include breakpoint-max('mobile') {
                        width: 57px;
                    }
                }
            }
            p {
                padding: 32px 35px;
                border: 1px solid $black;
                border-radius: 30px 0px 30px 30px;
                font-size: 1.4rem;
                line-height: 1.6rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                background-color: #e1dbd5;
                @include breakpoint-max('large') {
                    border-radius: 30px 30px 0px 30px;
                }
                @include breakpoint-max('mobile') {
                    padding: 21px;
                    max-width: 273px;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                }
            }
            @include breakpoint-max('large') {
                flex-direction: row-reverse;
                column-gap: 13px;
            }
        }
        &__right-button {
            width: 100%;
            display: flex;
            justify-content: center;
            button {
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                width: 150px;
                padding: 10px 0;
                font-size: 1.4rem;
                line-height: 2.4rem;
                text-transform: capitalize !important;
                color: #000;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                appearance: none;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 20px;
                justify-content: flex-start;
                margin-top: 129px;
            }
        }
        &__delete-profile-pop {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70vw;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            z-index: 999;
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
        }
        &__delete-profile-pop-box {
            padding: 40px 0 30px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__about-pop {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70vw;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
        }
        &__delete-profile-pop-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p {
                font-size: 2.4rem;
                text-align: center;
                margin-bottom: 40px;
            }
            input {
                display: block;
                padding: 10px 15px;
                color: #000000;
                font-size: 16px;
                line-height: 30px;
                border-radius: 0;
                font-family: 'Open Sans', sans-serif;
                outline: none;
                border: 1px solid #000000;
                background-color: transparent;
                width: 100%;
                max-width: 300px;
                margin: 0 auto;
            }
            input:first-of-type {
                margin-bottom: 10px;
            }
            button {
                margin-top: 20px;
                appearance: none;
                color: #000;
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                padding: 15px 0;
                width: 200px;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                }
            }
        }
        &__delete-profile-pop-close,
        &__about-pop-close {
            position: absolute;
            right: 10px;
            top: 10px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: $white;
        }
        &__delete-profile-error {
            margin-top: 20px;
            color: #d13724;
            font-size: 1.6rem !important;
        }
        &__about-pop {
            textarea {
                padding: 10px;
                outline: none;
                resize: none;
                max-width: 500px !important;
                width: 100%;
                height: 100% !important;
                font-family: 'Open Sans', sans-serif;
                border: 1px solid $black;
                border-radius: 0;
                &::placeholder {
                    font-family: 'Open Sans', sans-serif;
                }
            }
        }
    }
</style>
