<template>
    <section class="process-steps">
        <div class="scroll-div" id="process"></div>
        <div class="process-steps__header-container-mobile">
            <h1>Proces</h1>
        </div>

        <!---Mobile -->
        <div class="mobile-wrapper">
            <div class="mobile-steps-container">
                <div class="mobile-steps-container__step-container">
                    <div class="mobile-step-number-container">
                        <span>1</span>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>
                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-text-container">
                        <div class="step-header">
                            ZAPIS NA LISTĘ OCZEKUJĄCYCH
                        </div>
                        <div class="step-text">
                            Zapis na listę oczekujących jest bezpłatny!
                            <b>
                                Pamiętaj o zweryfikowaniu swojego adresu
                                mailowego.
                            </b>
                        </div>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-step-number-container">
                        <span>2</span>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-text-container">
                        <div class="step-header">
                            Utworzenie
                            <br />
                            konta
                        </div>
                        <div class="step-text">
                            Jeśli widzisz wiadomość powitalną to znaczy, że
                            jesteś na pokładzie! Zerknij na
                            <a href="#map-section" class="process-colored-span">
                                mapkę
                            </a>
                            i sprawdź czy Biuro działa już w Twoim mieście.
                        </div>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-step-number-container">
                        <div class="tag-text">
                            Oczekiwanie
                            <br />
                            na kwestionariusz
                        </div>
                        <span>3</span>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-text-container">
                        <div class="step-header">
                            Otrzymanie
                            <br />
                            kwestionariusza
                        </div>
                        <div class="step-text">
                            <b>
                                Wyślemy do Ciebie maila i/lub SMSa z informacją,
                                że kwestionariusz jest gotowy do wypełnienia.
                            </b>
                        </div>
                        <div class="step-text">
                            Pamiętaj, że po przesłaniu kwestionariusza nie
                            będziesz miał możliwości jego edytowania! Wysyłając
                            kwestionariusz i dokonując płatności aktywujesz
                            Pakiet Basic.
                        </div>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-step-number-container">
                        <span>4</span>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-text-container">
                        <div class="step-header">
                            Weryfikacja
                            <br />
                            kwestionariusza
                        </div>
                        <div class="step-text">
                            <b>
                                Zapoznajemy się z Twoimi odpowiedziami i
                                dowiadujemy się jakie są Twoje oczekiwania i
                                preferencje. Na tym etapie możemy mieć do Ciebie
                                dodatkowe pytania. Skontaktujemy się z Tobą.
                            </b>
                        </div>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-step-number-container">
                        <div class="tag-text">
                            Twoja weryfikacja
                            <br />
                            przebiegła pomyślnie!
                            <br />
                            Rozpoczynamy
                            <br />
                            poszukiwania!
                        </div>
                        <span>5</span>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-text-container">
                        <div class="step-header">
                            utworzenie
                            <br />
                            profilu singla
                        </div>
                        <div class="step-text">
                            <b>
                                Profil Singla powstaje w oparciu o naszą
                                autorską metodę. Konsultanci korzystają z niego
                                przy poszukiwaniu dopasowań.
                            </b>
                        </div>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-step-number-container">
                        <img
                            src="@/assets/images/double-heart-image.svg"
                            alt="heart"
                            class="step-number-image"
                        />
                        <span>6</span>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-text-container">
                        <div class="step-header">
                            jesteś
                            <br />
                            w pakiecie basic
                        </div>
                        <div class="step-text">
                            Pakiet Basic gwarantuje członkostwo w Biurze przez
                            12 miesięcy. W Pakiecie Basic Twój profil może być
                            prezentowany osobom korzystającym z Pakietów Plus i
                            Premium. Ich użytkownicy mają pierwszeństwo w
                            akceptacji dopasowań
                            <b>
                                Dla osób z Pakietem Basic nie prowadzimy
                                aktywnych poszukiwań dopasowań.
                            </b>
                        </div>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding"
                >
                    <div class="mobile-step-number-container">
                        <span>7</span>
                    </div>
                    <img
                        class="mobile-step-arrow"
                        src="@/assets/images/mobile-step-arrow.svg"
                        alt="arrow"
                    />
                </div>

                <div
                    class="mobile-steps-container__step-container mobile-with-padding mobile-last-item"
                >
                    <div class="mobile-text-container">
                        <div class="step-text">
                            <b>
                                CHCESZ ZWIĘKSZYĆ SWOJE MOŻLIWOŚCI? PRZEJDŹ DO
                                PAKIETU PLUS!
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <img
                src="@/assets/images/mobile-step-arrow-down.svg"
                alt="arrow image"
                style="max-height: 148px"
            />
            <SimpleButton
                :text="`Pakiet plus`"
                :route="`${isLoggedIn ? '/payment-plus' : '/register'}`"
                class="hero__button"
            />

            <div class="step-benefits-container">
                <div class="step-benefit-container">
                    <div class="red-dot">+</div>
                    <div><b>Korzyści z Pakietu Plus:</b></div>
                    <div>
                        Udział w zaawansowanym, spersonalizowanym procesie
                        poszukiwań.
                    </div>
                    <div>
                        Dobór przez konsultanta odpowiadających profilowi singla
                        dopasowań.
                    </div>
                </div>
                <div class="step-benefit-container">
                    <div class="red-dot">+</div>
                    <div>
                        <b>
                            Klientom korzystającym z Pakietu Plus w pierwszej
                            kolejności proponujemy dopasowania z bazy innych
                            klientów korzystających z Pakietu Plus.
                        </b>
                    </div>
                </div>
                <div class="step-benefit-container">
                    <div class="red-dot">+</div>
                    <div>
                        <b>
                            Klienci z Pakietu Plus pierwsi widzą i akceptują
                            dopasowania z klientami korzystającymi z Pakietu
                            Basic.
                        </b>
                    </div>
                </div>
            </div>
        </div>
        <!---Mobile -->

        <!--Desktop-->
        <div class="desktop-wrapper">
            <div class="step-wrapper">
                <div class="step-wrapper__number">
                    <div class="step-wrapper__top-abs-container"></div>
                    <div class="step-wrapper__red-dot"></div>
                    1
                </div>
                <div class="step-single-shape left">
                    <img
                        src="@/assets/images/step-arrow.svg"
                        alt="arrow"
                        class="img-border"
                    />
                    <img
                        src="@/assets/images/step-arrow-bottom.svg"
                        alt="arrow-down"
                        class="img-bottom"
                    />
                    <div class="step-single-shape__body">
                        <h3 class="step-single-shape__header">
                            ZAPIS NA LISTĘ OCZEKUJĄCYCH
                        </h3>
                        <div class="step-single-shape__text">
                            Zapis na listę oczekujących jest bezpłatny!
                            <b>
                                Pamiętaj o zweryfikowaniu swojego adresu
                                mailowego.
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-wrapper right">
                <div class="step-single-shape right">
                    <img
                        src="@/assets/images/step-arrow.svg"
                        alt="arrow"
                        class="img-border"
                    />
                    <img
                        src="@/assets/images/step-arrow-bottom.svg"
                        alt="arrow-down"
                        class="img-bottom"
                    />
                    <div class="step-single-shape__body">
                        <h3 class="step-single-shape__header">
                            Utworzenie konta
                        </h3>
                        <div class="step-single-shape__text">
                            Jeśli widzisz wiadomość powitalną to znaczy, że
                            jesteś na pokładzie! Zerknij na
                            <a href="#map-section" class="process-colored-span">
                                mapkę
                            </a>
                            i sprawdź czy Biuro działa już w Twoim mieście.
                        </div>
                    </div>
                </div>
                <div class="step-wrapper__number right-text">2</div>
            </div>
            <div class="step-wrapper">
                <div class="step-wrapper__number">
                    <div class="step-wrapper__number__tag">
                        Oczekiwanie
                        <br />
                        na nabór w wybranym przez
                        <br />
                        Ciebie mieście
                    </div>
                    3
                </div>
                <div class="step-single-shape left">
                    <img
                        src="@/assets/images/step-arrow.svg"
                        alt="arrow"
                        class="img-border"
                    />
                    <img
                        src="@/assets/images/step-arrow-bottom.svg"
                        alt="arrow-down"
                        class="img-bottom"
                    />
                    <div class="step-single-shape__body">
                        <h3 class="step-single-shape__header">
                            OTRZYMANIE KWESTIONARIUSZA
                        </h3>
                        <div class="step-single-shape__text">
                            <b>
                                Wyślemy do Ciebie maila i/lub SMSa z informacją,
                                że kwestionariusz jest gotowy do wypełnienia.
                            </b>
                        </div>
                        <div class="step-single-shape__text">
                            Pamiętaj, że po przesłaniu kwestionariusza nie
                            będziesz miał możliwości jego edytowania! Wysyłając
                            kwestionariusz i dokonując płatności aktywujesz
                            Pakiet Basic.
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-wrapper">
                <div class="step-single-shape right">
                    <img
                        src="@/assets/images/step-arrow.svg"
                        alt="arrow"
                        class="img-border"
                    />
                    <img
                        src="@/assets/images/step-arrow-bottom.svg"
                        alt="arrow-down"
                        class="img-bottom"
                    />
                    <div class="step-single-shape__body">
                        <h3 class="step-single-shape__header">
                            WERYFIKACJA KWESTIONARIUSZA
                        </h3>
                        <div class="step-single-shape__text">
                            <b>
                                Zapoznajemy się z Twoimi odpowiedziami i
                                dowiadujemy się Jakie są Twoje oczekiwania i
                                preferencje.Na tym etapie możemy mieć do Ciebie
                                dodatkowe pytania. Skontaktujemy się z Tobą.
                            </b>
                        </div>
                    </div>
                </div>
                <div class="step-wrapper__number">4</div>
            </div>
            <div class="step-wrapper">
                <div class="step-wrapper__number">
                    <div
                        class="step-wrapper__number__tag"
                        style="text-transform: none"
                    >
                        Twoje weryfikacja
                        <br />
                        przebiegła pomyślnie!
                        <br />
                        Rozpoczynamy
                        <br />
                        poszukiwania!
                    </div>
                    5
                </div>
                <div class="step-single-shape left">
                    <img
                        src="@/assets/images/step-arrow.svg"
                        alt="arrow"
                        class="img-border"
                    />
                    <img
                        src="@/assets/images/step-arrow-bottom.svg"
                        alt="arrow-down"
                        class="img-bottom"
                    />
                    <div class="step-single-shape__body">
                        <h3 class="step-single-shape__header">
                            UTWORZENIE PROFILU SINGLA
                        </h3>
                        <div class="step-single-shape__text">
                            <b>
                                Profil Singla powstaje w oparciu o naszą
                                autorską metodę. Konsultanci korzystają z niego
                                przy po/szukiwaniu dopasowań.
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step-wrapper">
                <div class="step-single-shape right">
                    <img
                        src="@/assets/images/step-arrow.svg"
                        alt="arrow"
                        class="img-border"
                    />
                    <img
                        src="@/assets/images/step-arrow-bottom.svg"
                        alt="arrow-down"
                        class="img-bottom"
                    />
                    <div class="step-single-shape__body">
                        <h3 class="step-single-shape__header">
                            JESTEŚ W PAKIECIE BASIC!
                        </h3>
                        <div class="step-single-shape__text">
                            Pakiet Basic gwarantuje członkostwo w Biurze przez
                            12 miesięcy. W Pakiecie Basic Twój profil może być
                            prezentowany osobom korzystającym z Pakietów Plus i
                            Premium. Ich użytkownicy mają pierwszeństwo w
                            akceptacji dopasowań
                            <b>
                                Dla osób z Pakietem Basic nie prowadzimy
                                aktywnych poszukiwań dopasowań.
                            </b>
                        </div>
                    </div>
                </div>
                <div class="step-wrapper__number">
                    6
                    <img
                        src="@/assets/images/double-heart-image.svg"
                        alt="heart"
                        class="step-number-image"
                    />
                </div>
            </div>
            <div class="step-wrapper" style="border-bottom: 2px solid black">
                <div class="step-wrapper__number">
                    <div class="step-wrapper__bottom-abs-container"></div>
                    <div class="step-wrapper__bottom-abs-arrow-line"></div>
                    <div class="step-wrapper__bottom-abs-triangle"></div>
                    7
                </div>
                <div class="step-single-shape left">
                    <img
                        src="@/assets/images/step-arrow.svg"
                        alt="arrow"
                        class="img-border"
                        style="left: -2px"
                    />
                    <div class="step-single-shape__body">
                        <h3 class="step-single-shape__header">
                            CHCESZ ZWIĘKSZYĆ SWOJE MOŻLIWOŚCI? PRZEJDŹ DO
                            PAKIETU PLUS!
                        </h3>
                    </div>
                </div>
            </div>

            <div class="step-button-container">
                <a :href="isLoggedIn ? '/payment-plus' : '/register'">
                    Pakiet plus
                </a>
            </div>
        </div>
        <div class="step-tile-container">
            <div class="step-benefit-container">
                <div class="red-dot">+</div>
                <div><b>Korzyści z Pakietu Plus:</b></div>
                <div>
                    Udział w zaawansowanym, spersonalizowanym procesie
                    poszukiwań.
                </div>
                <div>
                    Dobór przez konsultanta odpowiadających profilowi singla
                    dopasowań.
                </div>
            </div>
            <div class="step-benefit-container">
                <div class="red-dot">+</div>
                <div>
                    <b>
                        Klientom korzystającym z Pakietu Plus w pierwszej
                        kolejności proponujemy dopasowania z bazy innych
                        klientów korzystających z Pakietu Plus.
                    </b>
                </div>
            </div>
            <div class="step-benefit-container">
                <div class="red-dot">+</div>
                <div>
                    <b>
                        Klienci z Pakietu Plus pierwsi widzą i akceptują
                        dopasowania. W pierwszej kolejności szukamy dla nich
                        dopasowań wśród innych klientów z Pakietu Plus.
                    </b>
                </div>
            </div>
        </div>
        <!---->
    </section>
</template>

<script>
    import SimpleButton from '@/components/landing-03/atoms/SimpleButton.vue'
    export default {
        data() {
            return {}
        },
        props: {},
        computed: {
            isLoggedIn() {
                return localStorage.getItem('userId') ? true : false
            }
        },
        components: {
            SimpleButton
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/global.scss';

    .process-steps {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 100px 16px;
        @include breakpoint-max('xs-tablet') {
            padding: 32px 16px;
        }
        &__header-container-mobile {
            margin-bottom: 50px;
            /* @include breakpoint-min('large') {
            display: none;
        } */
            h1 {
                font-weight: 700;
                margin: 0;
                font-family: Oswald;
                font-size: clamp(74px, 2vw, 120px);
            }
        }
    }
    .desktop-wrapper {
        @include breakpoint-max('tablet') {
            display: none;
        }
        display: flex;
        flex-direction: column;
        max-width: 1000px;
        width: 100%;
    }

    .step-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: -1px;
        &__number {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            gap: 26px;
            color: $pink;
            font-size: 84px;
            font-family: Promenade;
            flex: 3;
            &__tag {
                font-family: 'Open Sans', sans-serif;
                background-color: $pink;
                color: white;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 14px;
                padding: 16px;
                width: 186px;
                text-align: center;
                line-height: 20px;
            }
        }
        &__top-abs-container {
            position: absolute;
            border-top: 2px solid black;
            border-left: 2px solid black;
            width: 100%;
            top: 0;
            left: 0;
            height: 120px;
        }
        &__red-dot {
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: $pink;
            left: -11px;
            top: 120px;
        }
        &__bottom-abs-container {
            position: absolute;
            width: 100%;
            bottom: -76px;
            height: 76px;
            border-top: 2px solid black;
            border-left: 2px solid black;
        }
        &__bottom-abs-arrow-line {
            position: absolute;
            bottom: -76px;
            left: 0;
            width: 120px;
            height: 2px;
            background-color: black;
        }
        &__bottom-abs-triangle {
            position: absolute;
            bottom: -90px;
            left: 118px;
            width: 0;
            height: 0;
            border-left: 17px solid transparent;
            border-right: 17px solid transparent;
            border-bottom: 30px solid $pink;
            transform: rotate(90deg);
        }
    }

    .mobile-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include breakpoint-min('tablet') {
            display: none;
        }
    }

    .mobile-steps-container {
        border-top: 2px solid black;
        border-left: 2px solid black;
        border-right: 2px solid black;
        &__step-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            min-width: 360px;
            min-height: 168px;
            .mobile-step-number-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
                span {
                    font-family: Promenade;
                    color: $pink;
                    font-size: 84px;
                }
            }
            img {
                position: absolute;
                width: 100%;
                bottom: -45.15px; ///-47px
                left: 0;
            }
            .mobile-text-container {
                display: flex;
                flex-direction: column;
                gap: 30px;
                .step-header {
                    font-weight: 700;
                    font-size: 26px;
                    text-align: center;
                    max-width: 260px;
                    text-transform: uppercase;
                }
                .step-text {
                    font-size: 16px;
                    text-align: center;
                    max-width: 260px;
                }
            }
        }
    }

    .mobile-with-padding {
        margin-top: 45.15px;
        padding: 26px 0;
    }

    .mobile-last-item {
        border-bottom: 2px solid black;
    }

    .step-benefits-container {
        display: flex;
        flex-direction: column;
        gap: 68px;
        margin-top: 52px;
    }

    .step-benefit-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        border: 1px solid black;
        background-color: #e1dbd5;
        padding: 46px;
        width: 320px;
        height: 320px;
        gap: 28px;
        font-size: 16px;
        @include breakpoint-min('xl-tablet') {
            max-width: 370px;
            width: 100%;
        }
        .red-dot {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            font-size: 50px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #d13724;
            left: calc(50% - 25px);
            top: -27px;
            color: white;
        }
    }

    .tag-text {
        background-color: $pink;
        color: white;
        text-transform: uppercase;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        padding: 14px 18px;
    }

    .step-number-image {
        max-width: 88px;
        position: static !important;
    }

    .step-single-shape {
        display: flex;
        justify-content: center;
        border-top: 2px solid black;
        /* border-bottom: 2px solid black; */
        position: relative;
        max-width: 700px;
        width: 100%;
        height: 233px;
        flex: 7;
        &__body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            max-width: 526px;
            gap: 30px;
        }
        &__header {
            font-weight: 700;
            font-size: 26px;
            text-transform: uppercase;
            margin: 0;
        }
        &__text {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .right-text {
        padding-left: 44px;
    }

    .step-single-shape.left {
        border-right: 2px solid black;
        .img-border {
            position: absolute;
            /* left: -41px; */
            left: 0;
            transform: rotate(180deg);
        }
        .img-bottom {
            position: absolute;
            bottom: -42px;
            right: -2px;
        }
    }

    .step-single-shape.right {
        border-left: 2px solid black;
        .img-border {
            position: absolute;
            right: -41px;
        }
        .img-bottom {
            position: absolute;
            bottom: -42px;
            left: -2px;
            transform: scaleX(-1);
        }
    }

    .step-arrow-bottom {
        position: absolute;
        bottom: -41px !important;
        right: 0 !important;
    }

    .step-button-container {
        margin: 40px 0 66px;
        a {
            background-color: $pink;
            color: white;
            font-weight: 700;
            font-size: 26px;
            text-transform: uppercase;
            padding: 20px;
            width: 100%;
            display: flex;
            text-align: center;
            justify-content: center;
            max-width: calc(100% - 160px);
            margin-left: auto;
            cursor: pointer;
            text-decoration: none;
        }
    }

    .step-tile-container {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        @include breakpoint-max('tablet') {
            display: none;
        }
    }

    .process-colored-span {
        color: $pink;
        cursor: pointer;
        text-decoration: none;
    }
</style>
