<template>
    <div>
        <HeaderComponent/>
        <section class="register">
            <div class="register__popup" v-if="popup">
                <p>{{ feedback.message }}</p>
                <router-link to="/login">Zaloguj się</router-link>
            </div>
            <div class="container">
                <!-- <div class="register__content">
                    <h1 class="register__title">REJESTRACJA</h1>
                    <div class="register__left" v-if="timeLeft">
                        <p>Przerwa techniczna.</p>
                        <p class="register__timer">Pozostały czas: {{ timeLeft }}</p>
                    </div>
                    <div v-else>
                        <p>Przerwa techniczna zakończona.</p>
                    </div>
                </div> -->
                <form @submit.prevent="register">
                    <h1 class="register__title">REJESTRACJA</h1>
                    <h2 class="register__title register__title--smaller">REJESTRACJA NA LIŚCIE OCZEKUJĄCYCH JEST BEZPŁATNA.</h2>
                    <div class="register__form">
                        <label for="email">
                            <input id="email" class="register__form-email" type="email" v-model="email">
                            <p>E-MAIL:</p>
                        </label>
                        <label for="password">
                            <input id="password" class="register__form-password" type="password" v-model="password">
                            <svg class="eyePassword" @click="inputTypeChange($event)" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                            <p>HASŁO:</p>
                        </label>
                        <label for="repeatPassword">
                            <input 
                                id="repeatPassword"
                                class="register__form-repeat-password"
                                type="password"
                                v-model="password2"
                            >
                            <svg class="eyePassword" @click="inputTypeChange($event)" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                            <p>POWTÓRZ HASŁO:</p>
                        </label>
                        <div class="save-profile__section save-profile__section--remove-border">
                    <label for="name" class="save-profile__title">Imię:</label>
                    <input type="text" id="name" v-model="name">
                </div>                

                <div class="save-profile__section">
                    <label for="surname" class="save-profile__title">Nazwisko:</label>
                    <input type="text" id="surname" v-model="surname">
                </div>

                <div class="save-profile__section">
                    <label for="phone" class="save-profile__title">Telefon:</label>
                    <input type="text" id="phone" v-model="phone" minlength="9" maxlength="9">
                </div>

                <div class="save-profile__section">
                    <label for="date" class="save-profile__title">Data urodzenia:</label>
                    <input id="date" type="date" v-model="date">
                </div>
                
                <div class="save-profile__section">
                    <div class="save-profile__flex">
                        <div class="save-profile__three">
                            <p class="save-profile__title save-profile__title--right">Jestem</p>
                            <div class="save-profile__flex">
                                <div class="save-profile__half">
                                    <input class="profile__radio" type="radio" id="one" value="0" v-model="gender"/>
                                    <label for="one"><svg id="Group_23" data-name="Group 23" xmlns="http://www.w3.org/2000/svg" width="40" height="61.5" viewBox="0 0 40 61.5">
                                        <g id="Ellipse_5" data-name="Ellipse 5" fill="none" stroke="#d13724" stroke-width="4">
                                            <circle cx="20" cy="20" r="20" stroke="none"/>
                                            <circle cx="20" cy="20" r="18" fill="none"/>
                                        </g>
                                        <line id="Line_23" data-name="Line 23" y1="21" transform="translate(20.5 38.5)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="4"/>
                                        <line id="Line_24" data-name="Line 24" x2="15" transform="translate(12.5 49.5)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="4"/>
                                    </svg>
                                    Kobietą</label>
                                </div>
                                <div class="save-profile__half">
                                    <input class="profile__radio" type="radio" id="two" value="1" v-model="gender"/>
                                    <label for="two"><svg xmlns="http://www.w3.org/2000/svg" width="52.081" height="50.761" viewBox="0 0 52.081 50.761">
                                        <g id="Group_25" data-name="Group 25" transform="translate(0 2)">
                                            <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(0 8.761)" fill="none" stroke="#d13724" stroke-width="4">
                                            <circle cx="20" cy="20" r="20" stroke="none"/>
                                            <circle cx="20" cy="20" r="18" fill="none"/>
                                            </g>
                                            <line id="Line_23" data-name="Line 23" x1="15" y2="14.999" transform="translate(33.5 1.137)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="4"/>
                                            <path id="Path_50" data-name="Path 50" d="M822.272,6398.363h12.809v11.483" transform="translate(-785 -6398.363)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="4"/>
                                        </g>
                                    </svg>
                                    Mężczyzną</label>
                                </div>
                            </div>
                        </div>
                        <div class="save-profile__three">
                            <p class="save-profile__title save-profile__title--right">Szukam</p>
                            <div class="save-profile__flex">
                                <div class="save-profile__half">
                                    <input class="profile__radio" type="radio" id="three" value="0" v-model="search"/>
                                    <label for="three"><svg id="Group_23" data-name="Group 23" xmlns="http://www.w3.org/2000/svg" width="40" height="61.5" viewBox="0 0 40 61.5">
                                        <g id="Ellipse_5" data-name="Ellipse 5" fill="none" stroke="#d13724" stroke-width="4">
                                            <circle cx="20" cy="20" r="20" stroke="none"/>
                                            <circle cx="20" cy="20" r="18" fill="none"/>
                                        </g>
                                        <line id="Line_23" data-name="Line 23" y1="21" transform="translate(20.5 38.5)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="4"/>
                                        <line id="Line_24" data-name="Line 24" x2="15" transform="translate(12.5 49.5)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="4"/>
                                    </svg>
                                    Kobiety</label>
                                </div>
                                <div class="save-profile__half">
                                    <input class="profile__radio" type="radio" id="four" value="1" v-model="search"/>
                                    <label for="four"><svg xmlns="http://www.w3.org/2000/svg" width="52.081" height="50.761" viewBox="0 0 52.081 50.761">
                                        <g id="Group_25" data-name="Group 25" transform="translate(0 2)">
                                            <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(0 8.761)" fill="none" stroke="#d13724" stroke-width="4">
                                            <circle cx="20" cy="20" r="20" stroke="none"/>
                                            <circle cx="20" cy="20" r="18" fill="none"/>
                                            </g>
                                            <line id="Line_23" data-name="Line 23" x1="15" y2="14.999" transform="translate(33.5 1.137)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="4"/>
                                            <path id="Path_50" data-name="Path 50" d="M822.272,6398.363h12.809v11.483" transform="translate(-785 -6398.363)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="4"/>
                                        </g>
                                    </svg>
                                    Mężczyzny</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="save-profile__section">
                    <label for="city" class="save-profile__title">Miejsce zamieszkania:</label>
                    <input type="text" id="city" v-model="city">
                </div>

                <div class="save-profile__section city-date">
                    <label for="citydate" class="save-profile__title">W jakim mieście chcesz się umawiać na randki?</label>
                    
                    <Multiselect
                        v-model="cityDate"
                        :options="options"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="false"
                        :create-option="true"
                    />
                </div>

                <div class="save-profile__section-all">
                    <div class="save-profile__photo-section">
                        <div class="save-profile__photos">
                            <p class="save-profile__heading">Dołącz zdjęcie</p>
                            <p class="save-profile__subtitle">Zdjęcie portretowe i całej sylwetki (bez czapek, okularów przeciwsłonecznych i innych osób).</p>
                        </div>
                        <div class="save-profile__all-photos">
                            <div class="save-profile__photos-flex">
                                <div>
                                    <img :src="imageSrc1" @click="triggerFileInput1" class="profile__img" />
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                            viewBox="0 0 50 50" xml:space="preserve">
                                        <circle style="fill:#FFFFFF;" cx="25" cy="25" r="25"/>
                                        <line style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="25" y1="13" x2="25" y2="38"/>
                                        <line style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="37.5" y1="25" x2="12.5" y2="25"/>
                                    </svg>
                                </div>
                                <div class="images-box">
                                    <img :src="imageSrc2" @click="triggerFileInput2" class="profile__img" />
                                    <p class="image-delete" @click="singleImageDelete(2)">Usuń</p>
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                            viewBox="0 0 50 50" xml:space="preserve">
                                        <circle style="fill:#FFFFFF;" cx="25" cy="25" r="25"/>
                                        <line style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="25" y1="13" x2="25" y2="38"/>
                                        <line style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="37.5" y1="25" x2="12.5" y2="25"/>
                                    </svg>
                                </div>
                                <div class="images-box">
                                    <img :src="imageSrc3" @click="triggerFileInput3" class="profile__img" />
                                    <p class="image-delete" @click="singleImageDelete(3)">Usuń</p>
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                            viewBox="0 0 50 50" xml:space="preserve">
                                        <circle style="fill:#FFFFFF;" cx="25" cy="25" r="25"/>
                                        <line style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="25" y1="13" x2="25" y2="38"/>
                                        <line style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="37.5" y1="25" x2="12.5" y2="25"/>
                                    </svg>
                                </div>
                                <div class="images-box">
                                    <img :src="imageSrc4" @click="triggerFileInput4" class="profile__img" />
                                    <p class="image-delete" @click="singleImageDelete(4)">Usuń</p>
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                            viewBox="0 0 50 50" xml:space="preserve">
                                        <circle style="fill:#FFFFFF;" cx="25" cy="25" r="25"/>
                                        <line style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="25" y1="13" x2="25" y2="38"/>
                                        <line style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="37.5" y1="25" x2="12.5" y2="25"/>
                                    </svg>
                                </div>
                            </div>
                            <input class="profile__file" type="file" ref="fileInput1" @change="onFileChange1" />
                            <input class="profile__file" type="file" ref="fileInput2" @change="onFileChange2" />
                            <input class="profile__file" type="file" ref="fileInput3" @change="onFileChange3" />
                            <input class="profile__file" type="file" ref="fileInput4" @change="onFileChange4" />
                        </div>
                        <!-- <div class="save-profile__checkbox checkBoxes">
                    
                            <label class="profile_label-text" for="chConfirm"><span>Administratorem danych osobowych jest B87 sp. z o.o. Pełną informację o
                            przetwarzaniu danych osobowych znajdziesz w <router-link target="_blank" to="/polityka_prywatnosci.pdf">Polityce prywatności.</router-link></span>.</label>
                        </div>
                        <div class="save-profile__checkbox checkBoxes">
                            <label class="checkBox" for="chConfirm">
                                <input id="chConfirm" type="checkbox" v-model="checkboxConfirm" value="text" name="subscribe">
                                <div class="transition"></div>
                            </label>
                            <label class="profile_label-text" for="chConfirm">Wyrażam zgodę na wysyłanie powiadomień o usługach Biura Randkowego na podany numer telefonu.</label>
                        </div>
                        <div class="save-profile__checkbox checkBoxes">
                            <label class="checkBox" for="chConfirm2">
                                <input id="chConfirm2" type="checkbox" v-model="checkboxConfirm2" value="text" name="subscribe2">
                                <div class="transition"></div>
                            </label>
                            <label class="profile_label-text" for="chConfirm2">Wyrażam zgodę na przetwarzanie przez B87 sp. z o.o. podanych danych osobowych w celu zapisu na listę oczekujących na usługi Biura Randkowego.</label>
                        </div> -->
                    </div>
                </div>
                <div class="save-profile__errors">
                    <p class="save-profile__errors-title">{{ emptyFields.title }}</p>
                    <p class="save-profile__errors-subtitle">{{ emptyFields.subtitle }}</p>
                    <ul ref="errorList">
                        <li>{{ emptyFields.email }}</li>
                        <li>{{ emptyFields.password }}</li>
                        <li>{{ emptyFields.passwordTwo }}</li>
                        <li>{{ emptyFields.name }}</li>
                        <li>{{ emptyFields.surname }}</li>
                        <li>{{ emptyFields.phone }}</li>
                        <li>{{ emptyFields.date }}</li>
                        <li>{{ emptyFields.gender }}</li>
                        <li>{{ emptyFields.search }}</li>
                        <li>{{ emptyFields.city }}</li>
                        <li>{{ emptyFields.cityDate }}</li>
                        <li>{{ emptyFields.image1 }}</li>
                        <li>{{ emptyFields.image2 }}</li>
                        <li>{{ emptyFields.checkbox }}</li>  
                        <li>{{ emptyFields.checkbox2 }}</li>  
                        <li>{{ photosFormatError }}</li>
                    </ul>
                </div>
                        <div class="register__checkboxes">
        
                            <div class="register__checkboxes-accept checkBoxes">
                                <label class="register__label-text" for="ch1"><span>Administratorem danych osobowych jest B87 sp. z o.o. Pełną informację o
                                przetwarzaniu danych osobowych znajdziesz w <router-link target="_blank" to="/polityka_prywatnosci.pdf">Polityce prywatności</router-link></span>.</label>
                            </div>
                            <div class="register__checkboxes-offer checkBoxes">
                                <label class="checkBox" for="accept">
                                    <input id="accept" type="checkbox" v-model="accept" value="text" name="accept">
                                    <div class="transition"></div>
                                </label>
                                <label class="register__label-text" for="accept">Akceptuję <router-link target="_blank" to="/regulamin_platformy.pdf">Regulamin</router-link> i <router-link target="_blank" to="/polityka_prywatnosci.pdf">Politykę Prywatności</router-link></label>
                            </div>
                            <div class="register__checkboxes-offer checkBoxes">
                                <label class="checkBox" for="ch2">
                                    <input id="ch2" type="checkbox" v-model="checkbox2" value="text" name="subscribe">
                                    <div class="transition"></div>
                                </label>
                                <label class="register__label-text" for="ch2">Wyrażam zgodę na otrzymywanie informacji marketingowych i
                                    ofert handlowych Biura Randkowego</label>
                            </div>
                            <div class="register__checkboxes-offer checkBoxes">
                                <label class="checkBox" for="chConfirm">
                                    <input id="chConfirm" type="checkbox" v-model="checkboxConfirm" value="text" name="subscribe">
                                    <div class="transition"></div>
                                </label>
                                <label class="register__label-text" for="chConfirm">Wyrażam zgodę na wysyłanie powiadomień o usługach Biura Randkowego na podany numer telefonu.</label>
                            </div>
                            <div class="register__checkboxes-offer checkBoxes">
                                <label class="checkBox" for="chConfirm2">
                                    <input id="chConfirm2" type="checkbox" v-model="checkboxConfirm2" value="text" name="subscribe2">
                                    <div class="transition"></div>
                                </label>
                                <label class="register__label-text" for="chConfirm2">Wyrażam zgodę na przetwarzanie przez B87 sp. z o.o. podanych danych osobowych w celu zapisu na listę oczekujących na usługi Biura Randkowego.</label>
                            </div>
                        </div>
                        <div class="register__wrapper-bottom">
                            <p class="register__wrapper-bottom-alert acceptTerms">{{ errorRegister }}</p>
                                <button 
                                    v-if="!loaderAnimation"
                                    type="submit" 
                                    :disabled="!(accept && checkboxConfirm && checkboxConfirm2)"
                                    :class="{'disabled-btn': accept === false || checkboxConfirm === false || checkboxConfirm2 === false}"
                                    >ZAREJESTRUJ SIĘ</button>
                                <button disabled v-else class="loader">
                                    <svg viewBox="25 25 50 50">
                                        <circle r="20" cy="50" cx="50"></circle>
                                    </svg>
                                </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <Footer/>
    </div>

<!-- <InputField pole="E-mail" type="text" :val="email" @update:val="val => email = val" :key="emailKey"/>
<InputField class="register__password" pole="Hasło" type="password" :val="password" @update:val="val => password = val" :key="passwordKey"/> -->
</template>
<script>
import HeaderComponent from "@/components/landing-03/HeaderComponent.vue";
import Footer from "@/components/landing-03/footer.vue";
import axios from 'axios';
import Multiselect from '@vueform/multiselect';

import InputField from '@/components/atoms/Input.vue';
import Button from '@/components/atoms/Button.vue';

export default {
    data() {
        return {
            selectedFile1: "",
            selectedFile2: "",
            selectedFile3: "",
            selectedFile4: "",
            imageSrc1: require("@/assets/images/ludzik1.png"),
            defaultImageSrc1: require("@/assets/images/ludzik1.png"),
            imageSrc1Filled: null,
            imageSrc2: require("@/assets/images/ludzik2.png"),
            imageSrc2Filled: null,
            imageSrc3: require("@/assets/images/blank.png"),
            imageSrc4: require("@/assets/images/blank.png"),
            checkbox: '',
            checkCheckbox: '',
            errorRegister: '',
            checkbox2: '',
            checkbox3: '',
            email: '',
            password: '',
            password2: '',
            feedback: '',
            emailKey: 0,
            message: '',
            passwordKey: 0,
            popup: false,
            error: false,
            accept: false,
            loaderAnimation: false,
            endTime: new Date(new Date().getTime() + 72 * 60 * 60 * 1000), // Dodaj 72 godziny do bieżącego czasu
            timeLeft: '',
            interval: null,
            search: null,
            gender: null,
            name: null,
            surname: null,
            phone: null,
            date: null,
            city: null,
            cityDate: null,
            checkboxConfirm: false,
            checkboxConfirm2: false,
            emptyFields: {
                email: '',
                password: '',
                passwordTwo: '',
                title: '',
                subtitle: '',
                name: '',
                surname: '',
                phone: '',
                date: '',
                gender: '',
                search: '',
                city: '',
                cityDate: '',
                image1: '',
                image2: '',
                checkbox: '',
                checkbox2: '',
                accept: '',
                checkboxConfirm: '',
                checkboxConfirm2: ''
            },
            photosFormatError: '',
            options: [
                'Warszawa',
                'Poznań',
                'Wrocław',
                'Kraków',
                'Łódź',
                'Rzeszów',
                'Gdańsk/Gdynia/Sopot',
                'Katowice',
                'Szczecin',
                'Bydgoszcz',
                'Lublin'
            ]
        };
    },
    components: {
        InputField,
        Button,
        HeaderComponent,
        Footer,
        Multiselect
    },
    methods: {
        formValidation() {
            this.emptyFields.checkboxConfirm  = '', this.emptyFields.checkboxConfirm2  = '', this.emptyFields.accept  = '', this.emptyFields.passwordTwo = '', this.emptyFields.password = '', this.emptyFields.email = '', this.emptyFields.checkbox2 = '', this.emptyFields.checkbox = '', this.emptyFields.title = '', this.emptyFields.subtitle = '', this.emptyFields.name = '', this.emptyFields.surname = '', this.emptyFields.phone = '', this.emptyFields.date = '', this.emptyFields.gender = '', this.emptyFields.search = '', this.emptyFields.city = '', this.emptyFields.cityDate = '', this.emptyFields.email = '', this.emptyFields.image1 = '', this.emptyFields.image2 = ''

            // if(this.checkboxConfirm2 == false){
            //     this.emptyFields.checkbox2 = 'Zaznacz zgodę na przetwarzanie danych osobowych.'
            //     return
            // }else{
            //     this.emptyFields.checkbox2 = ''
            // }
            
            this.email ? ((this.email.length < 5) && (this.emptyFields.email        = 'email minimum pięć znaków,')) : this.emptyFields.email = 'email,'
            this.name ? ((this.name.length < 2) && (this.emptyFields.name           = 'imię minimum dwa znaki,')) : this.emptyFields.name = 'imię,'
            this.surname ? ((this.surname.length < 2) && (this.emptyFields.surname  = 'nazwisko minimum dwa znaki,')) : this.emptyFields.surname = 'nazwisko,'
            this.phone ? ((this.phone.length < 6) && (this.emptyFields.phone        = 'telefon minimum sześć znaków,')) : this.emptyFields.phone = 'telefon,'
            !this.date && (this.emptyFields.date                                    = 'datę urodzenia,')
            !this.gender && (this.emptyFields.gender                                = 'swoją płeć,')
            !this.search && (this.emptyFields.search                                = 'płeć osoby której szukasz,')
            this.city ? ((this.city.length < 2) && (this.emptyFields.city           = 'miejsce zamieszkania minimum dwa znaki,')) : this.emptyFields.city = 'miejsce zamieszkania,'
            !this.cityDate && (this.emptyFields.cityDate                            = 'miasto w którym chcesz umawiać się na randki,')
            !this.accept && (this.emptyFields.accept                                = 'akceptacja zgody regulaminu oraz polityki prywatności,')
            !this.checkboxConfirm && (this.emptyFields.checkboxConfirm              = 'akceptacja zgody na wysyłanie powiadomień,')
            !this.checkboxConfirm2 && (this.emptyFields.checkboxConfirm2            = 'akceptacja zgody na przetwarzanie danych osobowych,')
            
            
            if (this.imageSrc1 !== this.defaultImageSrc1) {
                this.emptyFields.image1 = ''; // lub jakakolwiek inna akcja, jeśli obrazek został zmieniony
            } else {
                this.emptyFields.image1 = 'pierwsze zdjęcie,'; // jeśli obrazek nie został zmieniony
            }

            // if (this.imageSrc2 !== this.defaultImageSrc2) {
            //     this.emptyFields.image2 = ''; // lub jakakolwiek inna akcja, jeśli obrazek został zmieniony
            // } else {
            //     this.emptyFields.image2 = 'drugie zdjęcie,'; // jeśli obrazek nie został zmieniony
            // }

            if (this.password.length < 8) {
                this.emptyFields.password = 'hasło minimum 8 znaków';
            }

            if (this.password !== this.password2) {
                this.emptyFields.passwordTwo = 'hasła różnią się od siebie';
            }

            // if (!this.email || !this.password || !this.password2) {
            //     this.showError('Wypełnij wszystkie dane.')
            //     this.loaderAnimation = false
            //     return
            //  }
            // if (this.password != this.password2) {
            //     this.showError('Hasła różnią sie od siebie.')
            //     this.loaderAnimation = false
            //     return
            // }


            if (!this.name || !this.surname || !this.phone || !this.date || !this.gender || !this.search || !this.city || !this.cityDate || !this.imageSrc1) {
                this.emptyFields.title = 'Wykryliśmy błędy w formularzu.'
                this.emptyFields.subtitle = 'Uzupełnij prawidłowo następujące pola: '
                return false
            } else if (this.name.length < 2 || this.surname.length < 2 || this.phone.length < 6 || this.city.length < 2 || this.password.length < 8 || this.password !== this.password2) {
                this.emptyFields.title = 'Wykryliśmy błędy w formularzu.'
                this.emptyFields.subtitle = 'Uzupełnij prawidłowo następujące pola: '
                return false
            } else {
                return true
            }
        },
        updateCountdown() {
            const now = new Date();
            const distance = this.endTime - now;
            if (distance < 0) {
                this.timeLeft = '';
                clearInterval(this.interval);
                return;
            }

            // Oblicz dni, godziny, minuty i sekundy
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Formatuj czas jako DD:HH:MM:SS
            this.timeLeft = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        },
        twiceInputsChecked() {
            if (!this.checkbox3) {
                this.checkbox = true
                this.checkbox2 = true
            } else {
                this.checkbox = false
                this.checkbox2 = false
            }
        },
        singleImageDelete(photoNum) {
            switch (photoNum) {
                case 2:
                    this.imageSrc2 = require("@/assets/images/ludzik2.png")
                    this.selectedFile2 = "";
                    break;
                case 3:
                    this.imageSrc3 = require("@/assets/images/blank.png")
                    this.selectedFile3 = "";
                    break;
                case 4:
                    this.imageSrc4 = require("@/assets/images/blank.png")
                    this.selectedFile4 = "";
                    break;
            }
        },
        triggerFileInput1() {
            this.$refs.fileInput1.click();
        },
        triggerFileInput2() {
            this.$refs.fileInput2.click();
        },
        triggerFileInput3() {
            this.$refs.fileInput3.click();
        },
        triggerFileInput4() {
            this.$refs.fileInput4.click();
        },
        checkImagesFormat(imageType) {
            this.photosFormatError = ''
            if (imageType == 'image/png' || imageType == 'image/jpeg') { return true }
            else { this.photosFormatError = 'Poprawny format zdjęcia to .jpg lub .png'; return false }
        },
        onFileChange1(event) {
            if (!this.checkImagesFormat(event.target.files[0].type)) { return }
            // console.log('File 1 changed:', event.target.files[0]);
            this.selectedFile1 = event.target.files[0];
            if (this.selectedFile1) {
                this.imageSrc1 = URL.createObjectURL(this.selectedFile1);
            }
        },
        onFileChange2(event) {
            // console.log('File 2 changed:', event.target.files[0]);
            this.selectedFile2 = event.target.files[0];
            if (this.selectedFile2) {
                this.imageSrc2 = URL.createObjectURL(this.selectedFile2);
            }
        },
        onFileChange3(event) {
            // console.log('File 3 changed:', event.target.files[0]);
            this.selectedFile3 = event.target.files[0];
            if (this.selectedFile3) {
                this.imageSrc3 = URL.createObjectURL(this.selectedFile3);
            }
        },
        onFileChange4(event) {
            // console.log('File 4 changed:', event.target.files[0]);
            this.selectedFile4 = event.target.files[0];
            if (this.selectedFile4) {
                this.imageSrc4 = URL.createObjectURL(this.selectedFile4);
            }
        },
        filesChange(){
            if (!fileList.length) return;

            for (var i = 0; i < fileList.length; i++) {
                let file = fileList[i];
                formData.append('files[' + i + ']', file);
            } 
        },
        register(){

            this.loaderAnimation = true
            if (!this.formValidation()) {
                this.loaderAnimation = false
                return 
            }

            const formData = new FormData();

            formData.append("email", this.email);
            formData.append("password", this.password);
            formData.append("marketingaccept", this.checkbox2);
            formData.append("age", this.date);
            formData.append("gender", this.gender);
            formData.append("search", this.search);
            formData.append("city", this.city);
            formData.append("cityDate", this.cityDate);
            formData.append("name", this.name);
            formData.append("surname", this.surname);
            formData.append("phoneaccept", this.checkboxConfirm);
            formData.append("phone", this.phone);
            formData.append("consultant", this.cityDate[0]);
            
            // const userId = localStorage.getItem('userId');

            // if (userId) {
            //     formData.append("userid", userId);
            // }

            if (this.selectedFile1) {
                formData.append("file1", this.selectedFile1);
            }
            if (this.selectedFile2) {
                formData.append("file2", this.selectedFile2);
            }
            if (this.selectedFile3) {
                formData.append("file3", this.selectedFile3);
            }
            if (this.selectedFile4) {
                formData.append("file4", this.selectedFile4);
            }

            // axios.post(`${this.url}api/edit-user/`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // })
            // .then(response => {
            //     this.loaderAnimation = false;
            //     this.$router.push("/dziekujemy");
            //     console.log(response);
            // })
            // .catch(error => {
            //     this.loaderAnimation = false
            //     console.log(error);
            // });
            
            
            axios
                .post(`${this.url}api/register/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true,
                })
                .then((resp) => {
                    this.loaderAnimation = false
                    if(resp.data.message == 'ok'){

                        
                        // this.emailKey+=1;
                        // this.passwordKey+=1;
                        // this.error = false;
                        // localStorage.setItem('registered', 'ok');
                        // this.$router.push({
                        //     path: '/login',
                        // });
                    }else{
                        this.$gtm.trackEvent({
                            event: 'formularz-1'
                        });
                        this.popup = true;
                        this.error = true;
                        this.feedback = resp.data;
                        // console.log(resp.data);
                    }  
                    // console.log(resp);
                })
                .catch((err) => {
                    this.loaderAnimation = false   
                    console.log(err)                                                             
                    this.showError(err.response.data.message);
                });
        },
        checkLoggedIn() {
            const authToken = this.getCookie('authToken');
            if (authToken) {
                this.$router.push(`/zapisz-sie`);
            }
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            }
        },
        showError(text) {
            if (this.clearText) {
                clearTimeout(this.clearText);
            }
            this.errorRegister = text;
            this.clearText = setTimeout(() => {
                this.errorRegister = '';
            }, 20000);
        },
        inputTypeChange(e) {
            (e.target.closest('svg').previousSibling.type == 'text') ? e.target.closest('svg').previousSibling.type = 'password' : e.target.closest('svg').previousSibling.type = 'text'
        }
    },
    created(){
        this.checkLoggedIn();
    },
    mounted(){
        this.updateCountdown(); // Uruchom od razu po montażu
        this.interval = setInterval(this.updateCountdown, 1000);
    },
    beforeDestroy() {
        clearInterval(this.interval); // Wyczyść interval, gdy komponent jest niszczone
    } 
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/Nunito.css";

.save-profile{
    &__section{
        &--remove-border{
            border-top: 0;
        }
    }
    &__errors{
        border-bottom: 1px solid #000;
    }
}

.profile__radio{
    width: 25px !important;
}

    .register { 
        padding: 170px 0 86px;
        background-color: $bege3;
        @include breakpoint-max('mobile') {
            padding-top: 155px;
        }
        &__content{
            margin: 200px 0;
        }
        &__popup{
            position: fixed;
            padding: 40px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #FFF;
            border: 1px solid #000;
            z-index: 9999;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            p{
                font-size: 24px;
                font-family: "Oswald", sans-serif;
                max-width: 500px;
                font-weight: 700;
                color: #000000;
                text-align: center;
            }
            a {
                outline: none;
                text-transform: uppercase;
                text-decoration: none;
                display: inline-block;
                margin: 0 auto;
                font-size: 2.4rem;
                line-height: 3rem;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                padding: 10px 40px;
                cursor: pointer;
                background-color: $pink;
                color: $white;
                margin-top: 20px;
            }
        }
        &__title {
            width: 100%;
            font-family: 'Oswald', sans-serif;
            font-size: 55px;
            line-height: 6rem;
            font-weight: 700;
            margin-bottom: 30px;
            color: $black;
            text-align: center;
            &--smaller{
                font-size: 36px;
                margin-bottom: 77px;
                @include breakpoint-max('xs-tablet') {
                    font-size: 18px !important;
                    line-height: 4rem !important;
                }
            }
            @include breakpoint-max('xs-tablet') {
                font-size: 55px;
                margin-bottom: 40px;
            }
            @include breakpoint-max('mobile') {
                font-size: 55px;
                line-height: 6rem;
            }
            @include breakpoint-max('s-mobile') {
                font-size: 6rem;
            }
        }
        &__form {
            display: flex;
            flex-direction: column;
            max-width: 1075px;
            margin: 0 auto;
            input {
                padding: 27px 25px;
                color: $black;
                font-size: 16px;
                line-height: 3px;
                font-family: 'Open Sans', sans-serif;
                outline: none;
                border-radius: 0px;
                border: 1px solid $black;
                // background-color: #F4F2EE;
                background: transparent;
                width: 100%;
                &::placeholder {
                    color: $black;
                    font-size: 1.6rem;
                    line-height: 3rem;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 700;
                }
                @include breakpoint-max('xs-tablet') {
                    padding: 17px 15px;
                }
                @include breakpoint-max('mobile') {
                    padding-top: 53px;
                    padding-left: 25px !important;
                }
            }
            label {
                position: relative;
                p {
                    font-weight: 700;
                    color: $black;
                    font-size: 16px;
                    line-height: 30px;
                    font-family: 'Open Sans', sans-serif; 
                    position: absolute;
                    top: 51%;
                    transform: translateY(-50%);
                    left: 25px;
                    @include breakpoint-max('mobile') {
                        top: 20px;
                        transform: translateY(0%);
                        left: 28px;
                    }
                }
                .eyePassword {
                    position: absolute;
                    top: 50%;
                    right: 25px;
                    transform: translateY(-50%);
                    cursor: pointer;
                    @include breakpoint-max("mobile") {
                        top: 23px;
                        transform: translate(0);
                    }
                }
                #email,
                #password {
                    padding-left: 100px;
                }
                #repeatPassword {
                    padding-left: 190px;
                }
                &:nth-child(1) {
                    input {
                        border-bottom: none;
                    }
                }
                &:nth-child(2) {
                    input {
                        border-bottom: none;
                    }
                }
            }
        }
        &__checkboxes {
            margin-top: 44px;
            margin-bottom: 40px;
            .checkBoxes {
                display: flex;
                align-items: center;
                column-gap: 33px;
                margin-bottom: 18px;
                &:last-child {
                    margin-bottom: 0;
                }
                @include breakpoint-max('mobile') {
                    column-gap: 15px;
                }
            }
        }
        &__left{
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 3rem;
            line-height: 3rem;
            text-align: center;
        }
        &__timer{
            margin-top: 50px;
        }
        &__label-text {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 3rem;
            color: $black;
            a {
                color: $black;
            }
        }
        &__wrapper-bottom {
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            row-gap: 20px;
            button {
                width: fit-content;
                outline: none;
                font-size: 2.4rem;
                line-height: 3rem;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                padding: 10px 40px;
                cursor: pointer;
                background-color: $pink;
                color: $white;
            }
        }
        &__wrapper-bottom-alert {
            font-size: 1.4rem;
            line-height: 2rem;
            font-family: 'Open Sans', sans-serif;
            margin-bottom: 20px;
            font-weight: 600;
            opacity: 0;
            transition: .3s;
        }
        //
        .checkBox {
            display: block;
            cursor: pointer;
            width: 15px;
            height: 15px;
            min-width: 15px;
            min-height: 15px;
            border: 2px solid rgba(255, 255, 255, 0);
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            box-shadow: 0px 0px 0px 1px $black;
        }
        .checkBox div {
            width: 60px;
            height: 60px;
            background-color: $black;
            top: -52px;
            left: -52px;
            position: absolute;
            transform: rotateZ(45deg);
            z-index: 100;
        }
        .checkBox input[type=checkbox]:checked + div {
            left: -10px;
            top: -10px;
        }
        .checkBox input[type=checkbox] {
            position: absolute;
            left: 50px;
            visibility: hidden;
        }
        .transition {
            transition: 300ms ease;
        }
        //
        .acceptTerms {
            opacity: 1;
            color: red;
        }
        .loader {
            width: 271px;
            display: grid;
            place-items: center;
            @include breakpoint-max('large') {
                width: 251px;
            }
            @include breakpoint-max('s-tablet') {
                width: 232px;
            }
            @include breakpoint-max('mobile') {
                width: 229px;
            }
            svg {
                width: 24px;
                transform-origin: center;
                animation: rotate4 2s linear infinite;
            }

            circle {
            fill: none;
            stroke: $white;
            stroke-width: 2;
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            animation: dash4 1.5s ease-in-out infinite;
            }

            @keyframes rotate4 {
            100% {
            transform: rotate(360deg);
            }
            }

            @keyframes dash4 {
            0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            }

            50% {
            stroke-dasharray: 90, 200;
            stroke-dashoffset: -35px;
            }

            100% {
            stroke-dashoffset: -125px;
            }
            }
        }
    }
    .disabled-btn {
            cursor: not-allowed !important;
        }

</style>